/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Portal,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  PopoverAnchor,
  Button,
  Box,
  Divider,
  VStack,
  useStyleConfig,
  useControllableState,
  InputProps,
  useDisclosure,
} from '@chakra-ui/react';

import { HexAlphaColorPicker } from 'react-colorful';

interface ColorPickerProps {
  /** Allows `#rgba` and `#rrggbbaa` color formats */
  value?: string;
  defaultValue?: string;
  /** Allows `#rgba` and `#rrggbbaa` color formats */
  size?: InputProps['size'];
  tooltip?: string;
  onChange?: (color?: string) => void;
  isReadOnly?: boolean;
  variant?: 'filled' | 'opaque';
}

const HOVER_STYLE = {
  borderColor: 'var(--chakra-colors-chakra-border-color)',
  transitionProperty: 'var(--chakra-transition-property-common)',
  transitionDuration: 'var(--chakra-transition-duration-normal)',
};

const getAlphaFromHexColor = (hexColor: string) => {
  const alphaHex = hexColor.slice(7, 9);
  const alphaDecimal = parseInt(alphaHex, 16);
  const alphaValue = Math.round((alphaDecimal / 255) * 100);

  if (isNaN(alphaValue)) return 100;
  return alphaValue;
};
const getHexFromAlpha = (opacity: number) => {
  if (opacity < 0 || opacity > 100) {
    return Math.min(100, Math.max(0, opacity));
  }

  const alphaDecimal = Math.round((opacity / 100) * 255);
  const alphaHex = alphaDecimal.toString(16).padStart(2, '0');
  return alphaHex.toUpperCase();
};

export const ColorPicker = (props: ColorPickerProps) => {
  const {
    defaultValue,
    size = 'md',
    tooltip,
    isReadOnly,
    variant = 'filled',
  } = props;
  const { isOpen: isFocus, onOpen: onFocus, onClose: onBlur } = useDisclosure();
  const style = useStyleConfig('Input', { size }) as any;

  const [color, setColor] = useControllableState<string>({
    defaultValue: defaultValue || '#000000',
    value: props?.value,
    onChange: props?.onChange,
  });

  const [opacity, setOpacity] = React.useState<string>(
    getAlphaFromHexColor(color)?.toString()
  );

  /** Escapes all non-hexadecimal characters including "#" */
  const escapeColor = React.useCallback(
    (value: string) =>
      (value.replace(/([^0-9A-F]+)/gi, '') || value)?.toUpperCase(),
    []
  );

  const setColorOpacity = React.useCallback(
    (value: number) => {
      const newColor = escapeColor(color).substring(0, 6);
      const newAlpha = getHexFromAlpha(value);
      if (value <= 99) setColor(`#${newColor}${newAlpha}`);
      else setColor(`#${newColor}`);
    },
    [color]
  );

  // callback is given issues with the input element
  const handleOpacityInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isFocus || isEmpty(e.target.value)) return setOpacity(e.target.value);
    else {
      const value = Number(e.target.value);
      if (value <= 100 && value >= 0) setColorOpacity(value);
    }
  };

  // update opacity when color changes
  React.useEffect(() => {
    setOpacity(getAlphaFromHexColor(color)?.toString());
  }, [color]);

  // if default value changes, update color
  React.useEffect(() => {
    if (!defaultValue || defaultValue === color) return;
    setColor(defaultValue);
  }, [defaultValue]);

  return (
    <Popover gutter={0} matchWidth placement="bottom-end">
      <Tooltip label={tooltip} size="xs" openDelay={500}>
        <VStack spacing={0}>
          <InputGroup size={size} borderColor="transparent" data-group>
            <InputLeftElement>
              <PopoverTrigger>
                <Button
                  display="flex"
                  variant="unstyled"
                  pointerEvents={isReadOnly ? 'none' : 'auto'}
                >
                  <Box boxSize="1rem" bg={color} border="1px solid" />
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent w="fit-content" bg="transparent">
                  <HexAlphaColorPicker color={color} onChange={setColor} />
                </PopoverContent>
              </Portal>
            </InputLeftElement>
            <Input
              value={`#${escapeColor(color)}`}
              onChange={(e) => setColor(e.target.value)}
              sx={{ paddingInlineEnd: '3.6rem' }}
              onFocus={onFocus}
              onBlur={onBlur}
              _groupHover={HOVER_STYLE}
              isReadOnly={isReadOnly}
              {...(isFocus && style.field?._focusVisible)}
              {...((variant == 'opaque' || isReadOnly) && {
                color: 'gray.500',
              })}
            />
            <InputRightElement w="3.6rem">
              <Divider
                orientation="vertical"
                className="divme"
                bg="none"
                borderLeft="1px solid transparent"
                cursor="grab"
                _groupHover={HOVER_STYLE}
                {...(isFocus && HOVER_STYLE)}
              />
              <Input
                data-peer
                variant="unstyled"
                h="95%"
                size={size}
                value={isFocus ? opacity : `${opacity}%`}
                type={isFocus ? 'number' : 'text'}
                onChange={handleOpacityInput}
                onFocus={onFocus}
                isReadOnly={isReadOnly}
                onBlur={() => {
                  onBlur();
                  isEmpty(opacity) && setColorOpacity(100);
                }}
                px={get(
                  {
                    xs: 3,
                    sm: 2,
                    md: 2,
                    lg: 1,
                  },
                  `${size}`
                )}
                {...((variant == 'opaque' || isReadOnly) && {
                  color: 'gray.500',
                })}
              />
            </InputRightElement>
          </InputGroup>
          <PopoverAnchor children={<Divider h="0" visibility="hidden" />} />
        </VStack>
      </Tooltip>
    </Popover>
  );
};
