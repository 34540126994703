/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import get from 'lodash/get';
import { SystemStyleObject } from '@chakra-ui/react';
import baseApi, { StrapiId } from '.';
import { Branch } from './branch';
import { MediaContentGroup, TextContentGroup } from './content-library';

export type TemplateBlock =
  | TemplateBlockAppointments
  | TemplateBlockEmpty
  | TemplateBlockMedia
  | TemplateBlockText;
export type ContentSource =
  | 'texts'
  | 'media'
  | 'appointments'
  | 'none'
  | null
  | undefined;

interface TemplateBlockBase {
  id: StrapiId;
  source?: ContentSource;
  content_filters?: Record<string, { id: StrapiId }[]>;
  content?: unknown;
  config?: {
    sortOrder?: number;
    animation?: object | string;
    style?: SystemStyleObject;
    options?: any;
  };
}
export interface TemplateBlockEmpty extends TemplateBlockBase {
  source?: 'none' | null | undefined;
  content?: null | undefined;
}
export interface TemplateBlockMedia extends TemplateBlockBase {
  source: 'media';
  content?: Pick<MediaContentGroup, 'id' | 'media'>[];
}
export interface TemplateBlockText extends TemplateBlockBase {
  source: 'texts';
  content?: Pick<TextContentGroup, 'id' | 'texts'>[];
}
export interface TemplateBlockAppointments extends TemplateBlockBase {
  source: 'appointments';
}

interface TemplateBase {
  id: StrapiId;
  name?: string;
  properties?: TemplateBlock;
}
export interface TemplateSection extends TemplateBase {
  blocks?: TemplateBlock[];
}
export interface DisplayTemplate extends TemplateBase {
  branch?: Partial<Branch>;
  sections?: TemplateSection[];
}

type PutDisplayTemplateReq = Required<Pick<DisplayTemplate, 'id'>> &
  Partial<DisplayTemplate>;

const templatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDefaultTemplate: builder.query<DisplayTemplate, void>({
      query: () => `/display-templates/default`,
      transformResponse: (res, meta, arg) =>
        get(res, 'data', {}) as DisplayTemplate,
    }),
    getDisplayTemplate: builder.query<DisplayTemplate, TemplateBase['id']>({
      providesTags: (result, error, args) => [
        { type: 'DisplayTemplates', id: result?.id || 'LIST' },
        { type: 'DisplayTemplates', id: 'LIST' },
      ],
      query: (id) => `/display-templates/${id}`,
      transformResponse: (res, meta, arg) =>
        get(res, 'data', {}) as DisplayTemplate,
    }),
    createDisplayTemplate: builder.mutation<any, Partial<DisplayTemplate>>({
      invalidatesTags: [{ type: 'DisplayTemplates', id: 'LIST' }],
      query: (data) => ({
        url: '/display-templates',
        method: 'POST',
        body: { data },
      }),
    }),
    updateDisplayTemplate: builder.mutation<any, PutDisplayTemplateReq>({
      // Invalidates all queries that subscribe to Appointment ids only.
      invalidatesTags: (result, error, { id }) => [
        { type: 'DisplayTemplates', id },
      ],
      query({ id, ...data }) {
        return {
          url: `/display-templates/${id}`,
          method: 'PUT',
          body: { data },
        };
      },
    }),
  }),
});

export const {
  useCreateDisplayTemplateMutation,
  useUpdateDisplayTemplateMutation,
  useGetDefaultTemplateQuery,
  useGetDisplayTemplateQuery,
} = templatesApi;

export default templatesApi;
