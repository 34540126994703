/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Add,
  ArrowBack,
  DeleteOutline,
  Delete,
  CalendarToday,
  PermMedia,
} from 'emotion-icons/material';
import {
  Button,
  HStack,
  Spacer,
  Box,
  useToast,
  Tag,
  TagLabel,
  TagLeftIcon,
  ScaleFade,
} from '@chakra-ui/react';

import UploadAssetModal from './UploadAssetDialog';
import EmptyMessage from './EmptyMessage';
import { useBranch } from '../../hooks/branch';
import { useBulkDeleteMedia } from './MediaLibrary';
import { AssetsGrid, Folder, useAssetsGrid } from '../../components/DataView';
import Page, { PageHeading } from '../../components/Page';
import {
  useBulkDeleteContentGroupsMutation,
  useGetMediaFilesQuery,
} from '../../services/content-library';

interface FolderAssetsProps<T extends Folder> {
  isEditable?: boolean;
  folder?: T;
}

const FolderAssets = <T extends Folder>({
  isEditable = true,
  folder: folderProp,
}: FolderAssetsProps<T>) => {
  const toast = useToast();
  const branch = useBranch();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { folderId: folderIdUrlParam } = useParams();

  const folder: T = folderProp || state;
  const {
    data: myAssets,
    error,
    isError,
  } = useGetMediaFilesQuery({
    branchId: branch?.id,
    groupId: folderIdUrlParam || folder?.id,
  });

  const myAssetsGrid = useAssetsGrid({
    data: myAssets?.data,
    enableSelection: true,
  });

  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);
  const [bulkDeleteAssets] = useBulkDeleteMedia(myAssetsGrid);
  const [bulkDeleteFolders] = useBulkDeleteContentGroupsMutation();

  const folderId =
    folder?.id || state?.id || folderIdUrlParam || get(myAssets?.data, '0.id');
  const folderName =
    folder?.name || state?.name || get(myAssets?.data, '0.groupName');
  const createdAt =
    folder?.createdAt || state?.createdAt || get(myAssets?.data, '0.createdAt');
  const selectedCountSum = myAssetsGrid.getSelectedRowModel().rows.length;
  const fileActions = [
    {
      label: 'Delete',
      icon: <DeleteOutline size={24} />,
      onClick: (asset) => {
        myAssetsGrid?.openConfirmDialog?.({
          title: 'Delete Asset',
          description: `Are you sure you want to delete ${asset.name}?`,
          type: 'delete',
          onSubmit: () => bulkDeleteAssets([asset]),
        });
      },
    },
  ];

  const handleBackClick = () => {
    navigate(-1);
  };

  // if is error, navigate to media library
  React.useEffect(() => {
    if (isError) {
      toast({
        title: `Error loading folder - ${folderName}`,
        description: get(error, 'data.error.message'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      handleBackClick();
    }
  }, [isError, error]);

  return (
    <Page>
      <UploadAssetModal
        data={folder}
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      />
      {isEditable && (
        <Button
          size="sm"
          variant="ghost"
          px={2}
          leftIcon={<ArrowBack size="24" />}
          children="Media Library"
          onClick={handleBackClick}
        />
      )}
      <HStack alignItems="start">
        <Box my={2}>
          <PageHeading children={folderName} />
          <HStack my={2} spacing={5}>
            {!!createdAt && (
              <Tag variant="unstyled" size="lg" p={0}>
                <TagLeftIcon as={CalendarToday} />
                <TagLabel>
                  Created At:
                  {' ' + DateTime.fromISO(createdAt)?.toRelative?.()}
                </TagLabel>
              </Tag>
            )}
            <Tag variant="unstyled" size="lg" p={0}>
              <TagLeftIcon as={PermMedia} />
              <TagLabel>Assets: {myAssets?.data?.length || 0}</TagLabel>
            </Tag>
          </HStack>
        </Box>
        <Spacer />
        {isEditable && (
          <>
            <Button
              size="md"
              leftIcon={<Add size="24" />}
              colorScheme="blue"
              children="Add"
              onClick={() => setIsUploadModalOpen(true)}
            />
            <Button
              size="md"
              hidden={selectedCountSum === 0}
              leftIcon={<Delete size="24" />}
              colorScheme="blue"
              children={`Delete (${selectedCountSum})`}
              onClick={() => {
                myAssetsGrid?.openConfirmDialog?.({
                  title: 'Delete Selected',
                  description: `Are you sure you want to delete ${selectedCountSum} assets?`,
                  type: 'delete',
                  onSubmit: () =>
                    myAssetsGrid.getIsAllRowsSelected()
                      ? bulkDeleteFolders([folderId]).then(handleBackClick)
                      : bulkDeleteAssets(),
                });
              }}
            />
          </>
        )}
      </HStack>
      <ScaleFade initialScale={0.9} in>
        <EmptyMessage hidden={!myAssetsGrid.getIsViewEmpty()} />
        <AssetsGrid
          hidden={myAssetsGrid.getIsViewEmpty()}
          isEditable={isEditable}
          view={myAssetsGrid}
          actions={fileActions}
        />
      </ScaleFade>
    </Page>
  );
};

export default FolderAssets;
