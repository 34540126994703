/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { DateTime } from 'luxon';
import { get, map } from 'lodash';
import { Add, MoreVert } from 'emotion-icons/material';
import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Switch,
  FormControl,
  FormLabel,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import Page, { PageContainer, PageHeading } from '../../components/Page';
import {
  Appointment,
  useBulkDeleteAppointmentsMutation,
  useGetAppointmentsQuery,
} from '../../services/appointments';
import { useLoadingTimeout } from '../../hooks/timeout';
import { useBranch } from '../../hooks/branch';
import { useGetGroupsQuery } from '../../services/groups';
import DataTable from '../../components/DataTable';
import AddEditForm from './AddEditForm';
import AddFirstGroup from './AddFirstGroup';

const useBulkDelete = () => {
  const toast = useToast();
  const [bulkDelete, apiResp] = useBulkDeleteAppointmentsMutation();
  const deleteData = async (data: number[]) => {
    try {
      await bulkDelete(data).unwrap();
    } catch (error) {
      toast({
        title: 'Error deleting appointments. Please try again',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };
  return [deleteData, apiResp] as const;
};

const Appointments = () => {
  const branch = useBranch();
  const [searchValue, setSearchValue] = React.useState('');
  const [showPastDue, setShowPastDue] = React.useState(false);
  const [pageSize, onPageSizeChange] = React.useState(10);
  const [page, onPageChange] = React.useState(1);
  const [editableRow, setEditableRow] = React.useState<Partial<Appointment>>();
  const [tabGroupFilter, setTabGroupFilter] = React.useState<{
    id?: string | number;
  }>();

  const [bulkDeleteSelected] = useBulkDelete();
  const { data: groups } = useGetGroupsQuery([branch?.id]);
  const {
    data: appointments,
    isFetching,
    isUninitialized,
    isLoading,
  } = useGetAppointmentsQuery(
    [
      { branchId: branch?.id },
      { page, pageSize },
      {
        $and: [
          {
            groups: tabGroupFilter,
            ...(!showPastDue && {
              scheduledAt: {
                // we need to create a custom iso format to omit the seconds
                $gte: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"),
              },
            }),
          },
        ],
        $or: [
          { displayName: { $contains: searchValue } },
          { groups: { name: { $contains: searchValue } } },
        ],
      },
    ],
    { pollingInterval: 60000 }
  );

  const isLoadingWithTimeout = useLoadingTimeout([isUninitialized, isLoading]);

  const columns = React.useMemo(
    () => [
      // { accessorKey: 'id', header: 'ID' },
      {
        accessorKey: 'displayName',
        header: 'Name',
        meta: { style: { td: { w: 'full' } } },
      },
      {
        accessorKey: 'groups',
        cell: (info) => {
          const groups = info.getValue();
          return (
            <HStack spacing={1}>
              {groups?.map((group, indx) => (
                <Tag key={group?.id || indx} children={group?.name} />
              ))}
            </HStack>
          );
        },
      },
      {
        accessorKey: 'scheduledAt',
        header: 'Scheduled Date',
        cell: (info) => {
          const date = DateTime.fromISO(info.getValue());
          return (
            <VStack align="start" spacing={1}>
              <Text>{date.toFormat('MMMM d, yyyy')}</Text>
              <Text fontSize="md">{date.toFormat('h:mm a')}</Text>
            </VStack>
          );
        },
      },
      {
        id: 'actions',
        cell: (info) => {
          const [bulkDeleteSelected] = useBulkDelete();
          const { openConfirmDialog } = info.table?.options;
          return (
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                aria-label="row-actions"
                variant="ghost"
                icon={<MoreVert size={24} />}
              />
              <MenuList>
                <MenuItem
                  onClick={() => setEditableRow(info?.row.original)}
                  children="Edit"
                />
                <MenuItem
                  onClick={() =>
                    openConfirmDialog?.({
                      type: 'delete',
                      title: `Delete Appointment for ${info.row.getValue(
                        'displayName'
                      )}`,
                      description:
                        'Are you sure you want to delete this appointment?',
                      labels: { submit: 'Delete' },
                      onSubmit: () =>
                        bulkDeleteSelected([get(info, 'row.original.id')]),
                    })
                  }
                  children="Delete"
                />
              </MenuList>
            </Menu>
          );
        },
      },
    ],
    []
  );

  return (
    <Page>
      <AddFirstGroup />
      <AddEditForm
        isOpen={!!editableRow}
        data={editableRow}
        onClose={() => setEditableRow(undefined)}
      />
      <HStack>
        <PageHeading>Appointments</PageHeading>
        <Spacer />
        <Button
          size="md"
          leftIcon={<Add size="24" />}
          colorScheme="blue"
          onClick={() => setEditableRow({})}
        >
          Add
        </Button>
      </HStack>
      <PageContainer>
        <DataTable
          isLoading={isLoadingWithTimeout}
          inProgress={isFetching}
          data={appointments?.data}
          columns={columns}
          enableRowSelection
          onStyleRow={(row) => {
            const date = DateTime.fromISO(row?.scheduledAt);
            const isPastDue = date < DateTime.local();
            return { bg: isPastDue ? 'red.50' : undefined };
          }}
          empty={{
            description: 'No appointments found',
          }}
          pagination={{
            pageSize,
            onPageSizeChange,
            page: page,
            onPageChange,
            pageCount: get(appointments, 'meta.pagination.pageCount', 1),
          }}
          header={{
            tabs: [
              { label: 'Show All' },
              ...(get(groups, 'data.length', 0) <= 1
                ? []
                : get(groups, 'data', [])?.map((g) => ({
                    label: get(g, 'name'),
                    meta: { id: g?.id },
                  }))),
            ],
            tabRightElement: (
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="past-due-apts" mb="0">
                  Show Past Due
                </FormLabel>
                <Switch
                  isChecked={showPastDue}
                  id="past-due-apts"
                  colorScheme="red"
                  onChange={() => setShowPastDue((prev) => !prev)}
                />
              </FormControl>
            ),
            onSearchChange: setSearchValue,
            onTabChange: (tab) => setTabGroupFilter(tab?.meta),
            bulkActions: [
              {
                label: 'Delete',
                confirmDialog: {
                  title: 'Delete Selected Appointments',
                  labels: { submit: 'Delete' },
                  type: 'delete',
                  description:
                    'Are you sure you want to delete these appointments?',
                },
                onClick: (data) =>
                  bulkDeleteSelected(map(data?.rows, 'original.id')),
              },
            ],
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default Appointments;
