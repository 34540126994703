/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get } from 'lodash';
import { HStack, IconButton, Icon, VStack } from '@chakra-ui/react';
import { Remove } from 'emotion-icons/material';

import { ColorPicker } from '../../../../../components/ComboBox';
import { UITemplateBlock } from '../../../../../components/DisplayTemplate';
import { useDesignContext } from '../..';
import { useDebounceCallback } from '../../../hooks';
import { MediaFillOption } from './media';

const ColorFillOption = () => {
  const { updateConfig, config } = useDesignContext();
  const color = get(config, 'style.background') as string;

  const onColorChange = useDebounceCallback(
    (background?: string) => updateConfig?.({ style: { background } }),
    [updateConfig]
  );
  const onRemoveColor = React.useCallback(
    () => updateConfig?.({}, ['style.bg', 'style.background']),
    [updateConfig]
  );

  return (
    <HStack>
      <ColorPicker
        size="sm"
        defaultValue={color}
        onChange={onColorChange}
        variant={color ? 'filled' : 'opaque'}
      />
      <IconButton
        variant="ghost"
        size="sm"
        aria-label="Remove Color"
        icon={<Icon as={Remove} />}
        onClick={onRemoveColor}
        isDisabled={!get(config, 'style.bg')}
      />
    </HStack>
  );
};

export const FillControls = () => {
  const { block, isSectionBlock } = useDesignContext<UITemplateBlock>();
  return (
    <VStack spacing={1} align="start">
      <ColorFillOption />
      {(isSectionBlock || block?.source === 'media') && <MediaFillOption />}
    </VStack>
  );
};

export * from './media';
