/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import get from 'lodash/get';
import {
  HStack,
  Box,
  InputGroup,
  InputRightElement,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useEditable,
  Tooltip,
  ButtonGroup,
  EditableProps,
  CircularProgress,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { Add, Delete } from 'emotion-icons/material';
import Page, { PageContainer, PageHeading } from '../../components/Page';
import DataTable from '../../components/DataTable';
import { useBranch } from '../../hooks/branch';
import {
  Group,
  useDeleteGroupMutation,
  useGetGroupsQuery,
  useUpdateGroupMutation,
} from '../../services/groups';
import AddGroupForm, { groupSchema, GroupFormData } from './AddGroupForm';
import { useUpdateBranchMutation } from '../../services/branch';

export const branchSchema = yup.object({
  name: yup.string().label('Branch Name').required(),
});

type BranchSettingsData = yup.InferType<typeof branchSchema>;

interface EditableCellProps extends EditableProps {
  canSubmit?: boolean;
  isLoading?: boolean;
}

const EditableCell = React.forwardRef((props: EditableCellProps, ref) => {
  const { isLoading, canSubmit = true } = props;
  const { value, isEditing, getInputProps, onEdit, onSubmit, onCancel } =
    useEditable({
      isDisabled: props?.isDisabled || props?.isLoading,
      ...props,
    });
  const { onBlur, ...inputProps } = getInputProps();
  return isEditing ? (
    <InputGroup size="md">
      <Input
        pr="7rem"
        {...inputProps}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      />
      <InputRightElement as={HStack} width="10rem" hidden={!isEditing}>
        <ButtonGroup h="1.75rem" size="sm">
          <Button h="inherit" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            h="inherit"
            colorScheme="blue"
            isDisabled={!canSubmit}
            onClick={onSubmit}
            children="Save"
          />
        </ButtonGroup>
      </InputRightElement>
    </InputGroup>
  ) : (
    <Tooltip label={isLoading ? '' : 'Click to edit'}>
      <Button
        fontWeight="normal"
        variant="ghost"
        rightIcon={
          <CircularProgress hidden={!isLoading} isIndeterminate size="10px" />
        }
        onClick={onEdit}
        children={value}
      />
    </Tooltip>
  );
});

const EditableGroup = ({ name, id: groupId }: Group) => {
  const branch = useBranch();
  const toast = useToast();
  const { data: groups } = useGetGroupsQuery([branch?.id]);
  const {
    control,
    handleSubmit,
    reset: resetFrom,
    formState: { errors, isValid },
  } = useForm<GroupFormData>({
    resolver: yupResolver(groupSchema),
    context: { groupName: name, groups: groups?.data },
    defaultValues: { groupName: name },
    mode: 'onChange',
  });

  const [updateGroup, { isLoading: isUpdatingGroup }] =
    useUpdateGroupMutation();

  const onSubmit = async (aptData: GroupFormData) => {
    if (!groupId) return;
    try {
      await updateGroup({
        id: groupId,
        name: aptData.groupName,
      }).unwrap();
    } catch (error) {
      resetFrom({ groupName: name });
      toast({
        title: 'Error updating group. Please try again or contact support',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <FormControl isRequired isInvalid={!!errors.groupName}>
      <Controller
        name="groupName"
        control={control}
        render={({ field }) => (
          <EditableCell
            canSubmit={name !== field.value?.trim() && isValid}
            isLoading={isUpdatingGroup}
            value={field.value}
            onChange={(value) => {
              field.onChange(value?.toUpperCase());
            }}
            onSubmit={async () => handleSubmit(onSubmit)()}
          />
        )}
      />
      <FormErrorMessage>{errors.groupName?.message}</FormErrorMessage>
    </FormControl>
  );
};

const BranchSettings = () => {
  const branch = useBranch();
  const { data: groups, isFetching: isFetchingGroups } = useGetGroupsQuery([
    branch?.id,
  ]);
  const toast = useToast();
  const {
    control,
    handleSubmit,
    reset: resetFrom,
    formState: { errors, isValid },
  } = useForm<BranchSettingsData>({
    resolver: yupResolver(branchSchema),
    defaultValues: { name: branch?.name },
    mode: 'onChange',
  });

  const [updateBranch, { isLoading: isUpdatingBranch }] =
    useUpdateBranchMutation();

  const onSubmit = async (aptData: BranchSettingsData) => {
    if (!branch?.id) return;
    try {
      await updateBranch({
        id: branch?.id,
        ...aptData,
      }).unwrap();
      console.log('branch updated');
    } catch (error) {
      resetFrom({ name: branch?.name });
      toast({
        title: 'Error updating group. Please try again or contact support',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => <Box>Group</Box>,
        cell: (info: any) => <EditableGroup {...info?.row?.original} />,
        meta: { style: { th: { w: 'full' }, td: { p: 1 } } },
      },
      {
        accessorKey: 'id',
        header: () => <AddGroupForm />,
        cell: (row: any) => {
          const count = get(row, 'table.options.data.length', 0);
          const [deleteGroup, { isLoading: isDeletingGroup }] =
            useDeleteGroupMutation();
          return (
            <IconButton
              hidden={count <= 1}
              isLoading={isDeletingGroup}
              float="right"
              size="xs"
              aria-label="delete"
              variant="ghost"
              colorScheme="red"
              icon={<Delete size="1rem" />}
              onClick={() => deleteGroup(row?.getValue())}
            />
          );
        },
        meta: { style: { th: { px: 2 } } },
      },
    ],
    []
  );

  return (
    <Page>
      <HStack>
        <PageHeading>Settings</PageHeading>
      </HStack>
      <PageContainer p={4}>
        <FormControl mb={4} isRequired isInvalid={!!errors.name}>
          <FormLabel>Branch Name</FormLabel>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <EditableCell
                canSubmit={branch?.name !== field.value?.trim() && isValid}
                isLoading={isUpdatingBranch}
                value={field.value}
                onChange={field.onChange}
                onSubmit={async () => handleSubmit(onSubmit)()}
              />
            )}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <DataTable
          inProgress={isFetchingGroups}
          columns={columns}
          data={groups?.data}
        />
      </PageContainer>
    </Page>
  );
};

export default BranchSettings;
