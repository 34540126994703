/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Delete, Undo } from 'emotion-icons/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  ButtonGroup,
  FormErrorMessage,
  HStack,
  FormControl,
  forwardRef,
  BoxProps,
  IconButton,
  StyleProps,
} from '@chakra-ui/react';

import InlineEdit from '../../../components/InlineEdit';

export const EditableTextSchema = yup
  .object({
    text: yup
      .string()
      .min(2, 'Text must be at least 2 characters')
      .required('Text can not be empty'),
  })
  .required();

export type InputValidation = yup.InferType<typeof EditableTextSchema>;

interface EditableTextProps extends StyleProps {
  defaultValue?: string;
  discardOnModified?: boolean;
  highlightColor?: BoxProps['color'];
  isEditable?: boolean;
  isModified?: boolean;
  leftElement?: React.ReactElement | boolean | null;
  rightElement?: React.ReactElement | boolean | null;
  leftIcon?: React.ReactElement | boolean | null;
  rightIcon?: React.ReactElement | boolean | null;
  actions?: React.ReactNode;
  onSubmit?: (data: string) => void;
  onDiscard?: () => void;
  onDelete?: () => void;
}

const EditableText = forwardRef<EditableTextProps, 'div'>((props, ref) => {
  const {
    as: asProp,
    actions,
    defaultValue,
    discardOnModified = true,
    highlightColor = 'blue.50',
    isEditable = true,
    isModified = false,
    leftElement,
    rightIcon,
    leftIcon,
    onDelete,
    onDiscard,
    onSubmit,
    placeholder,
    rightElement,
    ...boxProps
  } = props;

  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors, isDirty, isValid },
  } = useForm<InputValidation>({
    resolver: yupResolver(EditableTextSchema),
    // will get updated once values returns
    values: { text: defaultValue || '' },
    mode: 'onBlur',
  });

  const handleOnSubmit = async (data: InputValidation) =>
    data.text !== defaultValue && onSubmit?.(data.text);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
      <HStack
        ref={ref}
        {...boxProps}
        px={4}
        bg={isDirty || isModified ? highlightColor : boxProps?.bg}
      >
        {leftElement}
        <FormControl
          as={asProp}
          isRequired
          isInvalid={!!errors?.text}
          px={0}
          _hover={{ bg: 'none' }}
          display="flex"
        >
          {leftIcon}
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <InlineEdit
                {...field}
                isReadOnly={!isEditable}
                placeholder={placeholder}
                onSubmit={(e) =>
                  !e.length ? resetForm() : handleSubmit(handleOnSubmit)()
                }
                sx={{ ...boxProps }}
              />
            )}
          />
          {rightIcon}
          <FormErrorMessage mt={0} ml={1}>
            {errors.text?.message}
          </FormErrorMessage>
        </FormControl>
        {rightElement}
        <ButtonGroup size="sm" isAttached variant="outline" colorScheme="blue">
          {isValid && (isDirty || isModified) && actions}
          {(isDirty || isModified) && discardOnModified && (
            <IconButton
              _hover={{ bg: 'gray.200' }}
              aria-label="Discard"
              icon={<Undo size="18" />}
              onClick={(e) => {
                e.preventDefault();
                resetForm();
                handleSubmit(handleOnSubmit)();
                onDiscard?.();
              }}
            />
          )}
          {isEditable && (
            <IconButton
              aria-label="Delete"
              colorScheme="red"
              icon={<Delete size="18" />}
              onClick={onDelete}
            />
          )}
        </ButtonGroup>
      </HStack>
    </form>
  );
});

export default EditableText;
