/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { Lock, RestartAlt } from 'emotion-icons/material';
import {
  Navigate,
  useNavigate,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { AppLogo } from '../../components/Nav/logo';
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from '../../services/auth';
import { useAuthContext } from '../../hooks/auth';

const stepOneSchema = yup
  .object({
    identifier: yup
      .string()
      .email('Enter a valid email address')
      .required("Email can't be empty"),
  })
  .required();

const stepTwoSchema = yup
  .object({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .max(100, 'Password cannot be more than 100 characters long'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required("Password can't be empty"),
  })
  .required();

type StepOneFormData = yup.InferType<typeof stepOneSchema>;
type StepTwoFormData = yup.InferType<typeof stepTwoSchema>;

const Copyright = (props: BoxProps) => (
  <Text color="inherit" align="center" {...props}>
    {'Copyright © '}
    <Link href="https://mywelcometv.com/" isExternal>
      Innovative Internet Solutions, Inc.
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Text>
);

export const StepOneForm = () => {
  const toast = useToast();
  const { isAuthenticated } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StepOneFormData>({ resolver: yupResolver(stepOneSchema) });

  const [forgotPassword, { data: res, isLoading }] =
    useForgotPasswordMutation();

  const onSubmit = async (aptData: StepOneFormData) => {
    try {
      await forgotPassword(aptData.identifier).unwrap();
    } catch (error) {
      toast({
        title: 'Error sending request',
        description: 'Please try again or contact support',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  if (isAuthenticated) return <Navigate to="/appointments" replace />;
  return (
    <Center h="100vh">
      <Container as="main" maxW="md">
        <VStack spacing={8}>
          <Box mt={3} mb={2}>
            <AppLogo width={150} />
          </Box>
          <Heading as="h1" fontWeight="medium" size="xl">
            Forgot password
          </Heading>
          <Box
            hidden={res?.ok}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            w="full"
            mt={1}
          >
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.identifier}>
                <FormLabel fontSize="xl">Email Address</FormLabel>
                <Input
                  {...register('identifier')}
                  type="email"
                  size="lg"
                  id="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                />
                <FormErrorMessage>
                  {errors.identifier?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <Button
              type="submit"
              size="lg"
              colorScheme="blue"
              isLoading={isLoading}
              w="full"
              my={4}
              children="Reset Password"
            />
          </Box>
          <Alert
            hidden={!res?.ok}
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            status="success"
            boxShadow="lg"
          >
            <AlertIcon as={Lock} boxSize="2rem" />
            Shortly, you will receive an email containing detailed instructions
            on how to securely reset your password. If you do not receive the
            email within a few minutes, please check your spam folder or try
            again.
            <Button
              as={RouterLink}
              reloadDocument
              size="sm"
              variant="outline"
              colorScheme="blue"
              leftIcon={<RestartAlt />}
              my={4}
              children="Try Again"
            />
          </Alert>
        </VStack>
        <Copyright mt={8} mb={4} />
      </Container>
    </Center>
  );
};

export const StepTwoForm = ({ resetCode }: { resetCode?: string }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StepTwoFormData>({ resolver: yupResolver(stepTwoSchema) });

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const onSubmit = async (aptData: StepTwoFormData) => {
    if (!resetCode) return;
    try {
      await resetPassword({
        code: resetCode,
        ...aptData,
      }).unwrap();
      toast({
        title: 'Your password has been reset successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/login');
    } catch (error) {
      toast({
        title: 'Error reseting your password',
        description: 'Please try again or contact support',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  if (isAuthenticated) return <Navigate to="/appointments" replace />;
  return (
    <Center h="100vh">
      <Container as="main" maxW="md">
        <VStack spacing={8}>
          <Box mt={3} mb={2}>
            <AppLogo width={150} />
          </Box>
          <Heading as="h1" fontWeight="medium" size="xl">
            Reset password
          </Heading>
          <Box
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            w="full"
            mt={1}
          >
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.password}>
                <FormLabel fontSize="xl">Password</FormLabel>
                <Input
                  {...register('password')}
                  id="password"
                  type="password"
                  placeholder="Enter your new password"
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.passwordConfirmation}>
                <FormLabel fontSize="xl">Confirm New Password</FormLabel>
                <Input
                  {...register('passwordConfirmation')}
                  id="password-confirm"
                  type="password"
                  placeholder="Password Confirmation"
                />
                <FormErrorMessage>
                  {errors.passwordConfirmation?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <Button
              type="submit"
              size="lg"
              colorScheme="blue"
              isLoading={isLoading}
              w="full"
              my={4}
              children="Reset Password"
            />
          </Box>
        </VStack>
        <Copyright mt={8} mb={4} />
      </Container>
    </Center>
  );
};

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resetCode = React.useRef(searchParams.get('code'));

  React.useEffect(() => {
    if (!isEmpty(searchParams.get('code'))) {
      setSearchParams('', { replace: true });
    }
  }, [searchParams.get('code')]);

  return resetCode.current ? (
    <StepTwoForm resetCode={resetCode.current} />
  ) : (
    <StepOneForm />
  );
};
