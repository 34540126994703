/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { useDispatch, useSelector } from 'react-redux';
import { get, debounce } from 'lodash';
import {
  User,
  USER_ROLES,
  ROLE_TYPE_MAP,
  useGetUserQuery,
} from '../services/auth';
import { selectApp, userLogout } from '../app/slice';
import { useNavigate } from 'react-router-dom';
import { matches } from 'lodash';
import React from 'react';

interface HasPermissionFunc {
  (roleScope: USER_ROLES[]): boolean;
  /** Return true if the user has permissions using useAuthContext roleScope option */
  isAuthorized: boolean;
}

export interface AuthOptions {
  /**
   * Check the user object for JWT claims and return a boolean indicating
   * whether or not they are authorized to view the component.
   */
  claimCheck?: (claims?: User) => boolean;
  /**
   * TODO: This is temporary until we have a proper permissions system
   * Check the user object for roles and return a boolean hasPermission
   */
  roleScope?: USER_ROLES[];
  /**
   * The URL to redirect to after logout
   */
  logoutUrl?: string;
}

/* helper hook to check if the user is the current user */
export const useIsMe = (user: Partial<User>) => {
  const { user: me } = useAuthContext();
  return me?.id === user?.id || me?.email === user?.email;
};

/* helper hook to logout user and redirect to login */
export const useDispatchLogout = (logoutUrl?: string) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return () => {
    dispatch(userLogout());
    navigate(logoutUrl ?? '/login');
  };
};

export const useAuthContext = (options: AuthOptions = {}) => {
  const logout = useDispatchLogout(options.logoutUrl);
  const { claimCheck = (): boolean => true } = options;
  const { auth } = useSelector(selectApp);
  const { roleScope = ['ADMIN'] } = options;
  const { data: user } = useGetUserQuery(auth?.sessionId || 'me', {
    skip: !auth?.isAuthenticated,
  });

  /**
   * The route is authenticated if the user has valid auth and there are no
   * JWT claim mismatches.
   */
  const routeIsAuthenticated =
    auth.isAuthenticated && claimCheck(user || undefined);

  // TODO: This is temporary until we have a proper permissions system
  const checkPermission: HasPermissionFunc = React.useMemo(() => {
    const hasPermission = (scope: USER_ROLES[]) => {
      return [...scope, ...roleScope]?.some(
        matches(get(ROLE_TYPE_MAP, user?.role.type || 'public'))
      );
    };
    hasPermission.isAuthorized = hasPermission([]);
    return hasPermission;
  }, [user, roleScope]);

  return {
    ...auth,
    user: user as User,
    isAuthenticated: routeIsAuthenticated,
    checkPermission,
    logout,
  };
};
