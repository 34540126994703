/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { map, uniqBy, xorBy } from 'lodash';
import { UnfoldMore } from 'emotion-icons/material';
import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Tag,
  MenuItemOption,
  MenuOptionGroup,
  useStyleConfig,
  Box,
  useControllableState,
  Spacer,
  TagProps,
} from '@chakra-ui/react';

import { ComboBoxOption } from '.';

export interface MultiSelectProps<T extends ComboBoxOption> {
  max?: number;
  data: T[];
  value?: T[];
  labelKey: keyof T;
  children?: React.FC<{ value: T }>;
  onChange?: (value: T[]) => void;
  variant?: TagProps['variant'];
  size?: TagProps['size'];
}

export const MultiSelect = <T extends ComboBoxOption>({
  max = 10,
  size,
  labelKey,
  data,
  children,
  value,
  onChange,
  variant,
}: MultiSelectProps<T>) => {
  const { field } = useStyleConfig('Input', { size }) as any;
  const list = React.useMemo(() => uniqBy(data, labelKey), [data, labelKey]);
  const [selectedItems, setSelectedItems] = useControllableState({
    defaultValue: [],
    value: value,
    onChange,
  });
  const listReminder = Math.max(0, selectedItems.length - max);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton type="button" w="full">
        <HStack
          sx={{
            ...field,
            overflow: 'auto',
            p: 0,
            pl: 2,
          }}
        >
          {selectedItems?.slice(0, max).map((value) => (
            <Tag
              key={value?.[labelKey]}
              size={size}
              variant={variant}
              children={
                <Box
                  as={children}
                  children={value?.[labelKey]}
                  {...{ value }}
                />
              }
            />
          ))}
          {listReminder > 0 && <Tag children={`+${listReminder}`} />}
          <Spacer />
          <UnfoldMore size={24} />
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuOptionGroup value={map(selectedItems, labelKey)} type="checkbox">
          {list.map((value) => (
            <MenuItemOption
              key={value?.[labelKey]}
              value={value?.[labelKey]}
              onClick={() => {
                setSelectedItems(xorBy(selectedItems, [value], labelKey));
              }}
            >
              {value?.[labelKey]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default MultiSelect;
