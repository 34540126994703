/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
  VStack,
  useStyleConfig,
  useControllableState,
  InputProps,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { Image as ImageIcon } from 'emotion-icons/material';

interface FileSelectProps {
  tooltip?: string;
  /** Allows `#rgba` and `#rrggbbaa` color formats */
  size?: InputProps['size'];
  isReadOnly?: boolean;
  alpha?: string;
  defaultAlpha?: string;
  onChangeAlpha?: (alpha?: string) => void;
  onClick?: (e?: React.MouseEvent) => void;
  isEmpty?: boolean;
}

const HOVER_STYLE = {
  borderColor: 'var(--chakra-colors-chakra-border-color)',
  transitionProperty: 'var(--chakra-transition-property-common)',
  transitionDuration: 'var(--chakra-transition-duration-normal)',
};

export const FileSelect = (props: FileSelectProps) => {
  const {
    size = 'sm',
    isEmpty,
    alpha,
    tooltip,
    onChangeAlpha,
    isReadOnly,
    defaultAlpha,
  } = props;

  const { isOpen: isFocus, onOpen: onFocus, onClose: onBlur } = useDisclosure();
  const style = useStyleConfig('Input', { size }) as any;

  const [opacity, setOpacity] = useControllableState<string | undefined>({
    defaultValue: defaultAlpha,
    value: alpha,
    onChange: onChangeAlpha,
  });

  return (
    <Tooltip label={tooltip} size="xs" openDelay={500}>
      <VStack spacing={0}>
        <InputGroup
          size={size}
          borderColor="transparent"
          data-group
          {...((isReadOnly || isEmpty) && { color: 'gray.500' })}
        >
          <InputLeftElement>
            <IconButton
              variant="unstyled"
              aria-label="Select Media"
              icon={<Icon as={ImageIcon} />}
              onClick={props.onClick}
              onMouseDown={onFocus}
              onMouseUp={onBlur}
              isDisabled={isReadOnly}
            />
          </InputLeftElement>
          <Input
            isReadOnly
            defaultValue="Media"
            onMouseDown={onFocus}
            onMouseUp={onBlur}
            _groupHover={{ ...(!isFocus && HOVER_STYLE) }}
            onClick={props.onClick}
            pointerEvents={isReadOnly ? 'none' : 'auto'}
            sx={{ paddingInlineEnd: '3.6rem', cursor: 'pointer' }}
            {...(isFocus && style.field?._focusVisible)}
          />
          <InputRightElement w="3.6rem">
            <Input
              variant="unstyled"
              h="95%"
              size={size}
              value={isFocus ? opacity : `${opacity}%`}
              type={isFocus ? 'number' : 'text'}
              onFocus={onFocus}
              isReadOnly={isReadOnly}
              borderLeft="1px solid transparent"
              _groupHover={HOVER_STYLE}
              {...(isFocus && HOVER_STYLE)}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (!e.target.value || (value >= 0 && value <= 100))
                  setOpacity(e.target.value);
              }}
              onBlur={() => {
                onBlur();
                if (!opacity) setOpacity('100');
              }}
              px={get(
                {
                  xs: 3,
                  sm: 2,
                  md: 2,
                  lg: 1,
                },
                `${size}`
              )}
            />
          </InputRightElement>
        </InputGroup>
      </VStack>
    </Tooltip>
  );
};
