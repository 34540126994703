/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  Button,
  HStack,
  Input,
  FormControl,
  Progress,
  List,
  ListItem,
  chakra,
  useToast,
} from '@chakra-ui/react';
import EditableText, {
  InputValidation,
  EditableTextSchema,
} from './EditableText';

export interface TextListItem {
  id?: string | number;
  guid?: string;
  content: string;
  isUpdated?: boolean;
}

export type EditableTextListFC = React.FC<
  TextListItem & { groupId?: string | number }
>;
interface EditableTextListProps {
  id?: string | number;
  isEditable?: boolean;
  texts: TextListItem[];
  isLoading?: boolean;
  onAddText?: (text: string) => void;
  onChange?: (texts: TextListItem[]) => void;
  leftElement?: EditableTextListFC;
}

const ListItemWrapper = chakra(ListItem, {
  baseStyle: {
    borderTop: '1px solid',
    borderColor: 'gray.300',
    py: '1',
    px: '4',
  },
});

const EditableTextList = ({
  id,
  isEditable = true,
  isLoading,
  onAddText,
  onChange,
  texts,
  leftElement,
}: EditableTextListProps) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset: resetForm,
    formState: { errors, isDirty },
  } = useForm<InputValidation>({
    resolver: yupResolver(EditableTextSchema),
  });

  const onSubmitData = async ({ text: content }: InputValidation) => {
    try {
      onAddText?.(content);
      resetForm();
    } catch (error) {
      toast({
        title: `Error updating text group`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <List>
      {isEditable && (
        <ListItemWrapper py={2}>
          <HStack as="form" w="full" onSubmit={handleSubmit(onSubmitData)}>
            <FormControl isRequired isInvalid={!!errors?.text}>
              <Input
                placeholder="Add new text"
                variant="unstyled"
                fontWeight="normal"
                fontSize="md"
                p="2"
                {...register('text')}
              />
              <FormErrorMessage>{errors?.text?.message}</FormErrorMessage>
            </FormControl>
            <Button
              hidden={!isDirty || !!errors?.text}
              size="sm"
              colorScheme="blue"
              type="submit"
              children="Add"
            />
          </HStack>
        </ListItemWrapper>
      )}
      {isLoading && <Progress isIndeterminate size="xs" />}
      {texts?.map((txt, index) => (
        <EditableText
          key={txt.id || txt?.guid || index}
          as={ListItemWrapper}
          defaultValue={txt.content}
          isModified={txt.isUpdated}
          discardOnModified={false}
          leftElement={leftElement?.({ ...txt, groupId: id })}
          isEditable={isEditable}
          onDelete={() => onChange?.(texts.filter((t) => t !== txt))}
          onSubmit={(content) =>
            onChange?.(
              texts.map((t, i) =>
                i !== index ? t : { ...t, content, isUpdated: true }
              )
            )
          }
        />
      ))}
    </List>
  );
};

export default EditableTextList;
