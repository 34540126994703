/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import { get, keyBy, flatMap, map, omit } from 'lodash';
import baseApi, {
  BASE_API_URL,
  AssetResponse,
  ListResponse,
  StrapiId,
  getListModel,
  StrapiRelationUpdate,
  RawAsset,
  getDataModel,
  TextAsset,
} from '.';
import { Branch } from './branch';

export type LibraryType = 'media' | 'texts';

const LIBRARY_TAG_MAP = {
  media: 'ContentLibraryMedia' as const,
  texts: 'ContentLibraryText' as const,
};

interface AssetOwner {
  branch?: Branch | Pick<Branch, 'id'> | StrapiRelationUpdate;
}

type GetMediaFilesReq = {
  branchId?: StrapiId;
  groupId?: StrapiId;
  grouped?: boolean; // if true, returns all media files grouped by group name
};

export interface ContentGroup {
  id: StrapiId;
  name: string;
  owner?: AssetOwner;
  type: LibraryType;
  data?: unknown;
  createdAt?: string;
}

export interface MediaContentGroup extends ContentGroup {
  media?: AssetResponse[];
}
export interface UpdateMediaContent extends Pick<ContentGroup, 'id'> {
  assets: Pick<AssetResponse, 'id'>[];
}
interface NewMediaContent extends Pick<ContentGroup, 'id'> {
  assets: RawAsset[];
}

export interface TextContentGroup extends ContentGroup {
  texts: TextAsset[];
}
export interface UpdateTextContent extends ContentGroup {
  texts: Partial<TextAsset>[];
}

type UpdateContentGroup = Pick<ContentGroup, 'id' | 'type'> &
  (Partial<ContentGroup> | Partial<UpdateTextContent>);
type CreateContentGroupReq = {
  name?: string;
  owner: AssetOwner;
  type: LibraryType;
  texts?: unknown;
};

type MediaGroupsResponse = ListResponse<MediaContentGroup>;
type TextGroupsResponse = ListResponse<TextContentGroup>;

/** This method will extract assets from the content-api response;
 * returns a flat-map of assets with the group name and id
 */
const getAssetsListModel = (groups: unknown[]) =>
  flatMap(groups, (item) =>
    getListModel(get(item, 'attributes.media.data', []), {
      groupName: get(item, 'attributes.group'),
      groupId: get(item, 'id'),
      groupAttributes: omit(get(item, 'attributes'), 'media'),
      hostname: BASE_API_URL,
    })
  );

const contentLibraryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMediaGroups: builder.query<MediaGroupsResponse, Branch['id']>({
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.data.map((grp) => ({
                type: 'ContentLibraryMedia' as const,
                id: get(grp, 'id', 'LIST'),
              })),
              { type: 'ContentLibraryMedia', id: 'LIST' },
            ]
          : [{ type: 'ContentLibraryMedia', id: 'LIST' }];
      },
      query: (branchId) => {
        const query = {
          populate: ['media'],
          filters: {
            group: { $notNull: true }, // groups are only valid if they have a group name
            type: 'media',
            owner: {
              branch: { id: branchId },
            },
          },
        };
        return `/content-libraries?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => ({
        data: getListModel(get(res, 'data', []), {
          name: 'group',
          // we pass an the object as group so we can extract the assets from the response
          media: (item) => getAssetsListModel([item]),
        }),
        meta: get(res, 'meta', {}),
        original: get(res, 'data'),
      }),
    }),
    getMediaFiles: builder.query<ListResponse<AssetResponse>, GetMediaFilesReq>(
      {
        providesTags: (result, error, arg) => {
          return result
            ? [
                ...result.original.map((grp) => ({
                  type: 'ContentLibraryMedia' as const,
                  id: get(grp, 'id', 'LIST'),
                })),
                { type: 'ContentLibraryMedia', id: 'LIST' },
              ]
            : [{ type: 'ContentLibraryMedia', id: 'LIST' }];
        },
        query: ({ branchId, groupId, grouped }) => {
          const query = {
            filters: {
              id: groupId,
              group: { $notNull: !!groupId || !!grouped }, // groups are only valid if they have a group name
              type: 'media',
              owner: {
                branch: { id: branchId },
              },
            },
            populate: ['media'],
            pagination: { pageSize: 100 },
          };
          return `/content-libraries?${qs.stringify(query, {
            encodeValuesOnly: true,
          })}`;
        },
        transformResponse: (res, meta, arg) => ({
          data: getAssetsListModel(get(res, 'data', [])),
          meta: get(res, 'meta', {}),
          original: get(res, 'data'),
        }),
      }
    ),
    createContentGroup: builder.mutation<ContentGroup, CreateContentGroupReq>({
      // Invalidates all queries that subscribe to ContentLibrary ids only.
      invalidatesTags: (result, error, { type }) => [
        { type: LIBRARY_TAG_MAP[type ?? 'media'], id: 'LIST' },
      ],
      query: (data) => ({
        url: `/content-libraries`,
        method: 'POST',
        body: { data: { ...data, group: data.name } },
      }),
      transformResponse: (res, meta, arg) =>
        getDataModel<ContentGroup>(get(res, 'data', {}), {
          name: 'group',
        }),
    }),
    updateContentGroup: builder.mutation<any, UpdateContentGroup>({
      // Invalidates all queries that subscribe to ContentLibrary ids only.
      invalidatesTags: (result, error, { id, type }) => [
        { type: LIBRARY_TAG_MAP[type ?? 'media'], id },
        { type: 'ContentLibraryText', id: id },
      ],
      query: ({ id, name: group, ...data }) => ({
        url: `/content-libraries/${id}`,
        method: 'PUT',
        body: { data: { ...data, group } },
      }),
    }),
    bulkDeleteContentGroups: builder.mutation<any, ContentGroup['id'][]>({
      // Invalidates all queries that subscribe to ContentLibrary ids only.
      invalidatesTags: (result, error, ids) => [
        ...ids.map((id) => ({ type: 'ContentLibraryMedia' as const, id })),
        ...ids.map((id) => ({ type: 'ContentLibraryText' as const, id })),
      ],
      query: (ids) => ({
        url: `/content-libraries/bulk-delete`,
        method: 'PUT',
        body: { data: ids },
      }),
    }),
    bulkDeleteGroupMedia: builder.mutation<any, UpdateMediaContent>({
      // Invalidates all queries that subscribe to ContentLibrary ids only.
      invalidatesTags: (result, error, { id }) => [
        { type: 'ContentLibraryMedia', id },
      ],
      query: ({ id, assets }) => ({
        url: `/content-libraries/${id}/bulk-delete`,
        method: 'PUT',
        body: { data: map(assets, 'id') },
      }),
    }),
    uploadMediaContent: builder.mutation<any, NewMediaContent>({
      // Invalidates all queries that subscribe to ContentLibrary ids only.
      invalidatesTags: (result, error, { id }) => [
        { type: 'ContentLibraryMedia', id },
      ],
      query: ({ id, assets }) => {
        const uploadForm = new FormData();
        uploadForm.append(`refId`, id?.toString()); // get the id of the content library
        uploadForm.append(`ref`, 'api::content-library.content-library');
        uploadForm.append(`field`, 'media');
        assets.forEach(({ name, rawFile }) => {
          uploadForm.append(`files`, rawFile, name);
        });

        return {
          url: `/upload`,
          method: 'POST',
          body: uploadForm,
        };
      },
    }),
    getTextGroups: builder.query<TextGroupsResponse, Branch['id']>({
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.data.map((grp) => ({
                type: 'ContentLibraryText' as const,
                id: get(grp, 'id', 'LIST'),
              })),
              { type: 'ContentLibraryText', id: 'LIST' },
            ]
          : [{ type: 'ContentLibraryText', id: 'LIST' }];
      },
      query: (branchId) => {
        const query = {
          sort: ['id:desc'],
          populate: ['texts'],
          filters: {
            type: 'texts',
            owner: {
              branch: { id: branchId },
            },
          },
        };
        return `/content-libraries?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => {
        const data = getListModel<TextContentGroup[]>(get(res, 'data', []), {
          name: 'group',
        });
        return {
          data,
          meta: get(res, 'meta', {}),
          original: get(res, 'data'),
          dataByKey: [keyBy(data, 'id'), 'id'],
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useBulkDeleteContentGroupsMutation,
  useBulkDeleteGroupMediaMutation,
  useCreateContentGroupMutation,
  useGetMediaFilesQuery,
  useGetMediaGroupsQuery,
  useUpdateContentGroupMutation,
  useUploadMediaContentMutation,
  useGetTextGroupsQuery,
} = contentLibraryApi;

export default contentLibraryApi;
