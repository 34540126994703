/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import {
  InputProps,
  useEditable,
  UseEditableProps,
  useColorModeValue,
  Textarea,
  Tooltip,
  Box,
  SystemStyleObject,
} from '@chakra-ui/react';

export interface InlineEditViewProps extends InputProps {}

export interface InlineEditProps extends UseEditableProps {
  placeholder?: string;
  isEditable?: boolean;
  isReadOnly?: boolean;
  variant?: InputProps['variant'];
  sx?: SystemStyleObject;
}

export const InlineEdit = React.forwardRef<HTMLDivElement, InlineEditProps>(
  (props: InlineEditProps, ref) => {
    const {
      isEditable = true,
      isReadOnly = false,
      variant,
      sx,
      placeholder = 'Click to edit text',
      ...editconfig
    } = props;

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { value, isEditing, getInputProps, onEdit } = useEditable(editconfig);

    const adjustTextAreaHeight = () => {
      if (!textAreaRef.current) return;
      textAreaRef.current.style.height = '0px';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    };

    React.useEffect(() => {
      if (isEditing) adjustTextAreaHeight();
    }, [isEditing, value]);

    return isEditing ? (
      <Textarea
        {...getInputProps(undefined, textAreaRef)}
        minH={10}
        sx={sx}
        placeholder={placeholder}
        variant={variant}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      />
    ) : (
      <Tooltip
        shouldWrapChildren={true}
        label={isReadOnly ? undefined : 'Click to edit'}
      >
        <Box
          as="span"
          children={value || placeholder}
          onClick={(e) => {
            if (isEditable && !isReadOnly) {
              onEdit?.();
              adjustTextAreaHeight();
              e.stopPropagation();
            }
          }}
          sx={sx}
          __css={{
            cursor: 'text',
            display: 'inline-block',
            whiteSpace: 'pre-wrap',
            py: 2,
            px: 4,
            borderRadius: 'md',
            transitionProperty: 'common',
            transitionDuration: 'normal',
            lineHeight: '1.4',
            _hover: {
              ...(!isReadOnly && {
                bg: useColorModeValue('gray.100', 'gray.700'),
              }),
            },
          }}
        />
      </Tooltip>
    );
  }
);

export default InlineEdit;
