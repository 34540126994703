/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  variants: {
    'nav-item': {
      justifyContent: 'start',
      _hover: {
        bg: 'neutral.100',
      },
      _active: {
        bg: 'neutral.150',
      },
    },
    outline: ({ dropShadow, boxShadow }) => ({
      boxShadow: dropShadow ? '0px 1px 4px 0px #2121341A' : boxShadow,
    }),
  },
});

export const Checkbox = defineStyleConfig({
  baseStyle: {
    control: {
      borderColor: 'blackAlpha.700',
    },
  },
});

export * from './sidenav';
export * from './asset-card';
