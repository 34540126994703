/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

/**
 * References:
 * - nice approach, it uses window.resize, and not binary search
 *   - https://github.com/datchley/react-scale-text
 * - uses binary search, and it was made for react
 *  - https://github.com/malte-wessel/react-textfit/blob/master/src/Textfit.js
 * - original textfit, it uses binary search, js-only
 *  - https://github.com/STRML/textFit/blob/master/textFit.js
 */

import React from 'react';
import { Box, useMergeRefs, forwardRef, TextProps } from '@chakra-ui/react';
import { innerHeight, innerWidth, trackResize } from './utils';

type Omitted = 'whiteSpace';
export interface TextFitProps extends Omit<TextProps, Omitted> {
  /** nowrap will ignore the height of element */
  mode?: 'normal' | 'nowrap';
  /** `default=3` */
  minFontSize?: number | undefined;
  /** `default=100` */
  maxFontSize?: number | undefined;
  children?: string;
}

const TextFit = React.memo(
  forwardRef<TextFitProps, 'div'>((props: TextFitProps, ref) => {
    const {
      children,
      maxFontSize = 100,
      minFontSize = 3,
      mode = 'normal',
      align,
      textAlign,
      ...style
    } = props;
    const internalRef = React.useRef<HTMLElement>(null);
    const offsetParent = React.useRef<HTMLElement>();
    const styleRef = React.useRef<CSSStyleDeclaration>();
    const refs = useMergeRefs(internalRef, ref);

    const getHeight = () => props.height || props.h || props.boxSize;

    /** this will set the real offset parent */
    const setOffsetParent = () => {
      if (!internalRef.current || offsetParent.current) return;
      let parent = internalRef.current?.offsetParent as HTMLElement;
      while (parent && !parent?.clientHeight) {
        parent = parent?.offsetParent as HTMLElement;
      }
      offsetParent.current = parent;
    };

    const process = () => {
      if (!internalRef.current) return;
      const widthOnly = mode == 'nowrap';
      const innerBox = internalRef.current;
      const innerSpan = innerBox?.firstElementChild as HTMLElement;

      const originalWidth = innerWidth(innerBox, styleRef.current),
        originalHeight = innerHeight(innerBox, styleRef.current);

      let low = minFontSize,
        high = maxFontSize,
        size = minFontSize;

      /* we set the max height to equal the offsetParent */
      if (!getHeight() && offsetParent.current) {
        innerBox.style.maxHeight = offsetParent.current.clientHeight + 'px';
      }

      while (low <= high) {
        const mid = (high + low) >> 1;
        innerSpan.style.fontSize = mid + 'px';

        /* we set the default height to the innerSpan height */
        if (!getHeight()) innerBox.style.height = innerSpan.clientHeight + 'px';

        const isScrollOverflow =
          innerBox.scrollHeight > innerBox.clientHeight ||
          innerBox.scrollWidth > innerBox.clientWidth;
        const isClientOverflow =
          originalWidth <= innerSpan.clientWidth &&
          (widthOnly || originalHeight <= innerSpan.clientHeight);

        if (isScrollOverflow || isClientOverflow) {
          high = mid - 1;
        } else {
          size = mid;
          low = mid + 1;
        }
      }

      // size was the last one to fit
      if (parseInt(innerSpan.style.fontSize) != size) {
        innerSpan.style.fontSize = size + 'px';
      }
    };

    React.useLayoutEffect(() => {
      if (!internalRef.current) return;
      setOffsetParent();
      styleRef.current = window.getComputedStyle(internalRef.current);
      return trackResize([internalRef.current], () => process());
    }, [
      props?.width,
      props?.height,
      props?.w,
      props?.h,
      props?.mode,
      children,
    ]);

    return (
      <Box
        ref={refs}
        {...style}
        overflow="auto"
        pos="relative"
        display="flex"
        justifyContent={align || textAlign}
        __css={{
          /* Hide scrollbar for Chrome, Safari and Opera */
          '&::-webkit-scrollbar': { display: 'none' },
          /* Hide scrollbar for IE, Edge and Firefox */
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Box
          position="absolute"
          as="span"
          whiteSpace={mode}
          // Inline block ensure it takes on the size of its contents, even if they are enclosed
          // in other tags like <p>
          display="inline-block"
          textDecoration={style?.textDecoration}
          fontStyle={style?.fontStyle}
          fontWeight={style?.fontWeight}
        >
          {children}
        </Box>
      </Box>
    );
  })
);

export default TextFit;
