/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { extendTheme } from '@chakra-ui/react';
import { Button, AssetCard, Checkbox, SideNav } from './components';

import '@fontsource/roboto';

const theme = extendTheme({
  fonts: {
    // heading: `'Open Sans', sans-serif`,
    // heading: "Roboto, sans-serif",
    // body: `"Poppins", sans-serif;`,
  },
  styles: {
    global: {
      body: {
        bg: 'body',
      },
      '#id': {
        bg: 'red',
      },
      svg: {
        maxH: '4rem',
        maxW: '4rem',
        minH: '1rem',
        minW: '1rem',
      },
    },
  },
  components: {
    AssetCard,
    Button,
    Checkbox,
    SideNav,
  },
  colors: {
    body: '#FCFCFD',
    template: {
      bg: 'rgba(0, 86, 248, 0.63)',
      'blockBoxShadow': 'inset 0 0 0 4px  var(--chakra-colors-blue-400)',
    },
    media: {
      grid: 'repeating-conic-gradient(rgb(246, 246, 249) 0%, rgb(246, 246, 249) 25%, transparent 0%, transparent 50%) 50% center / 20px 20px',
    },
  },
});

export default theme;
