/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import {
  Info,
  Warning,
  Error as ErrorIcon,
  Delete,
} from 'emotion-icons/material';
import {
  Portal,
  UseModalProps,
  Button,
  UseDisclosureProps,
  useControllableState,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react';

type ConfirmDialogType = 'warning' | 'delete' | 'info';
export interface ConfirmDialog {
  description?: string;
  title?: string;
  type?: ConfirmDialogType;
  labels?: {
    submit?: string;
    cancel?: string;
  };
  onSubmit?: () => Promise<any> | void;
}

export interface ConfirmDialogProps extends ConfirmDialog, UseDisclosureProps {
  isLoading?: boolean;
  onClose?: () => void;
}

const defaultConfig = {
  warning: { color: 'yellow' },
  delete: { color: 'red', label: 'Delete' },
  info: { color: 'blue' },
};

const ConfirmIcon = ({
  variant,
  size = 32,
  color,
}: {
  variant?: ConfirmDialogType;
  size?: string | number;
  color?: string;
}) => {
  switch (variant) {
    case 'warning':
      return <Warning size={size} color={color || 'yellow'} />;
    case 'delete':
      return <Delete size={size} color={color || 'red'} />;
    case 'info':
      return <Info size={size} color={color || 'blue'} />;
    default:
      return null;
  }
};

export const ConfirmActionDialog = ({
  description,
  isLoading: isLoadingProp,
  isOpen: isOpenProp,
  labels,
  onClose,
  onOpen: onOpenProp,
  onSubmit,
  title,
  type,
  ...props
}: ConfirmDialogProps) => {
  const toast = useToast();
  const cancelRef = React.useRef(null);
  const [isOpen, onOpen] = useControllableState<boolean>({
    value: isOpenProp,
    onChange: onOpenProp,
  });
  const [isLoadingState, setIsLoading] = React.useState<boolean>(false);

  const handleOnClose = () => {
    setIsLoading(false);
    onClose?.();
    onOpen(false);
  };

  const isLoading = isLoadingProp ?? isLoadingState;
  return (
    <Portal>
      <AlertDialog
        {...props}
        isCentered
        isOpen={isOpen}
        onClose={handleOnClose}
        leastDestructiveRef={cancelRef}
        closeOnOverlayClick={!isLoading}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            {title ? (
              <AlertDialogHeader fontSize="lg" children={title} />
            ) : null}
            <AlertDialogBody as={VStack}>
              <ErrorIcon
                size={32}
                color={get(defaultConfig, `${type}.color`)}
              />
              <Text>{description}</Text>
            </AlertDialogBody>
            <AlertDialogFooter as={HStack} mt={4}>
              <Button
                w="full"
                variant="outline"
                children={labels?.cancel || 'Cancel'}
                ref={cancelRef}
                onClick={handleOnClose}
                isDisabled={isLoading}
              />
              <Button
                w="full"
                isLoading={isLoading}
                colorScheme={get(defaultConfig, `${type}.color`)}
                leftIcon={
                  <ConfirmIcon variant={type} color="white" size={18} />
                }
                children={
                  labels?.submit ||
                  get(defaultConfig, `${type}.label`, 'Submit')
                }
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    const res = await onSubmit?.();
                    if (get(res, 'error'))
                      throw new Error(get(res, 'error.data.error.message'));
                    handleOnClose();
                  } catch (error) {
                    setIsLoading(false);
                    toast({
                      title: `${title} - Error.`,
                      description: error?.message,
                      status: 'error',
                      duration: 4000,
                      isClosable: true,
                    });
                  }
                }}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Portal>
  );
};

export default ConfirmActionDialog;
