/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { useState } from 'react';
import { pick, startCase, result } from 'lodash';
import { ArrowUpward, ArrowDownward, SwapVert } from 'emotion-icons/material';
import {
  Box,
  Heading,
  HStack,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  SimpleGrid,
  Spacer,
  Skeleton,
} from '@chakra-ui/react';

import {
  Asset,
  ViewCore,
  DataViewOptions,
  useDataView,
  ItemSelection,
} from '..';
import FolderCard, { FolderCardProps } from './FolderCard';
import ConfirmActionDialog from '../../ConfirmActionDialog';

export interface Folder {
  id?: string | number;
  name: string;
  assets?: Asset[];
  createdAt?: string;
}

export interface FolderGridProps<T extends Folder> {
  actions?: FolderCardProps['actions'];
  view: ViewCore<T>;
  isEditable?: boolean;
  isLoading?: boolean;
  onClick?: (folder: T) => void;
  title?: string;
  hidden?: boolean;
  leftElement?: React.FC<T & ItemSelection>;
  isIndeterminateEnabled?: boolean;
}

export const useFolderGrid = <T extends Folder>({
  data,
  enableSelection,
  rowSelection,
  onRowSelectionChange,
}: DataViewOptions<T>) => {
  return useDataView({
    data,
    enableRowSelection: enableSelection,
    state: { rowSelection },
    onRowSelectionChange,
    columns: [
      { accessorKey: 'name', meta: { label: 'Name' } },
      { accessorKey: 'assetsCount', meta: { label: 'Assets' } },
      { accessorKey: 'cretedAt', meta: { label: 'Created At' } },
    ],
  });
};

export const FoldersGrid = <T extends Folder>({
  title,
  view,
  actions,
  onClick,
  isEditable,
  isLoading,
  hidden,
  leftElement,
  isIndeterminateEnabled = true,
}: FolderGridProps<T>) => {
  const [sortBy, setSortBy] = useState<string>('name');

  // set sorting values
  const sortColumn = view.getColumn(sortBy);
  const sortIcon = {
    asc: {
      icon: <ArrowUpward size={18} />,
      value: true,
    },
    desc: {
      icon: <ArrowDownward size={18} />,
      value: false,
    },
  }[sortColumn?.getIsSorted() as string] ?? {
    icon: <SwapVert size={18} />,
    value: true,
  };

  return (
    <Box w="full" hidden={hidden}>
      <ConfirmActionDialog {...result(view, 'getState.confirmDialog', {})} />
      <HStack paddingTop={2} paddingBottom={2}>
        <Heading fontSize="lg" variant="delta" fontWeight="semibold">
          {title} Folders
        </Heading>
        <Spacer />
        <HStack>
          <Menu>
            <MenuButton children={startCase(sortBy)} />
            <MenuList>
              {view.getAllColumns().map((col) => (
                <MenuItem
                  key={col.id}
                  children={col.columnDef.meta?.label}
                  onClick={() => setSortBy(col.id)}
                />
              ))}
            </MenuList>
          </Menu>
          <IconButton
            variant="ghost"
            size="sm"
            aria-label={`sort-folders-by-${sortBy}`}
            icon={sortIcon?.icon}
            onClick={sortColumn?.getToggleSortingHandler()}
          />
          <Checkbox
            // TODO: Sometimes select all hidden can be confusing
            // hidden={!view.getIsRowsSelected()}
            isChecked={view.getIsAllRowsSelected()}
            isIndeterminate={
              isIndeterminateEnabled ? view.getIsSomeRowsSelected() : false
            }
            onChange={view.getToggleAllRowsSelectedHandler()}
            size="lg"
          />
        </HStack>
      </HStack>
      <Skeleton isLoaded={!isLoading} minH="2rem">
        <SimpleGrid columns={{ md: 2, lg: 4 }} spacing={2}>
          {view.getRowModel().rows.map((row) => {
            const props = {
              ...row.original,
              ...pick(row, [
                'getCanSelect',
                'getIsSelected',
                'getIsSomeSelected',
                'getToggleSelectedHandler',
                'toggleSelected',
              ]),
            };
            return (
              <FolderCard
                key={row.id}
                actions={actions}
                isEditable={isEditable}
                isSelectable={!!view.options.enableRowSelection}
                onClick={() => onClick?.(row.original)}
                leftElement={leftElement?.(props)}
                {...props}
              />
            );
          })}
        </SimpleGrid>
      </Skeleton>
    </Box>
  );
};

export default FoldersGrid;
