/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { useContext } from 'react';
import {
  Box,
  BoxProps,
  Center,
  ChakraComponent,
  Spinner,
  chakra,
} from '@chakra-ui/react';
import { TemplateBlock } from '../../../../services/display-templates';
import { UIContentBlock } from '.';
import { UITemplateBlockType, TemplateContext } from '../..';

export interface BlockContentCustomProps
  extends BoxProps,
    UIContentBlock<TemplateBlock> {
  type?: UITemplateBlockType;
}

const OVERLAY_DEFAULT_STYLE: BoxProps = {
  pointerEvents: 'none',
  position: 'absolute',
  boxSize: 'full',
  left: 0,
  top: 0,
};

/** Local block content are blocks only rendered by the template, no remote configuration */
export const BlockContentCustom = ({
  block,
  type,
  ...props
}: BlockContentCustomProps) => {
  const { isLive, blockOverlay } = useContext(TemplateContext);

  const isSource = (sources?: TemplateBlock['source'][]) =>
    sources?.includes(block?.source);

  /** In case we have an overlay, we want to trigger the underlaying elements onClick */
  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e?.target instanceof HTMLElement) {
      e.target?.parentElement?.click();
      e.target.style.display = 'none';
      const contentBlock = document.elementFromPoint(e.clientX, e.clientY);
      const isChild =
        e?.target?.parentElement !== contentBlock &&
        e?.target?.parentElement?.contains(contentBlock);
      if (isChild && contentBlock instanceof HTMLElement) {
        contentBlock?.click();
      }
      e.target.style.removeProperty('display');
    }
  };

  if (!block) return null;
  return (
    <>
      {!isLive && blockOverlay && (
        <Center
          {...OVERLAY_DEFAULT_STYLE}
          className="block-overlay"
          onClick={handleOnClick}
          children={<Box as={blockOverlay} block={block} blockType={type} />}
          // we overwrite the pointerEvents of the children to be able to click through
          sx={{
            ':empty': { display: 'none' },
            '> *': { pointerEvents: 'auto' },
            '>:only-child': {
              ...OVERLAY_DEFAULT_STYLE,
              '> *': { pointerEvents: 'auto' },
            },
          }}
        />
      )}
    </>
  );
};

export default BlockContentCustom;
