/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import { concat, get } from 'lodash';

import baseApi, {
  StrapiId,
  getDataModel,
  getListModel,
  AssetResponse,
  ListResponse,
  PaginationRequest,
  StrapiRelationUpdate,
} from '.';
import { User } from './auth';
import { Group } from './groups';
import { DisplayTemplate } from './display-templates';

export interface Branch {
  id?: StrapiId;
  slug: string;
  users: User[] | StrapiRelationUpdate;
  name: string;
  updatedAt: string;
  createdAt: string;
  logo?: AssetResponse;
  groups?: Group[] | StrapiRelationUpdate;
  display_template?: DisplayTemplate;
}

export interface Client {
  id: StrapiId;
  displayName: string;
  fullName?: string;
  email?: string;
  phone?: string;
}

interface BranchRequest {
  slug: Branch['slug'];
  user: User;
}

type GetBranchReq = [Branch['id'], PaginationRequest?, Object?];
type BranchUserReq = { branchId: Branch['id']; userId: User['id'] };

const branchesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDefaultBranch: builder.query<Partial<Branch>, string[]>({
      query: (fields = []) => {
        const query = {
          populate: {
            branch: { fields: concat(['id'], fields) },
          },
        };
        return `/defaults?${qs.stringify(query, { encodeValuesOnly: true })}`;
      },
      transformResponse: (res, meta, arg) =>
        getDataModel(get(res, 'data.attributes.branch.data', {})),
    }),
    getBranches: builder.query<ListResponse<Branch>, User['id']>({
      providesTags: [{ type: 'Branches', id: 'LIST' }],
      query: (userId) => {
        const query = {
          populate: {
            logo: '*',
            organization: { fields: ['id', 'name'] },
          },
          filters: { users: { id: { $eq: userId } } },
        };
        return `/branches?${qs.stringify(query, { encodeValuesOnly: true })}`;
      },
      transformResponse: (res, meta, arg) => ({
        data: getListModel<Branch[]>(get(res, 'data', []), {
          logo: 'logo.data.attributes',
        }),
        meta: get(res, 'meta', {}),
        original: get(res, 'data'),
      }),
    }),
    getBranchUsers: builder.query<ListResponse<User>, GetBranchReq>({
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.data.map((grp) => ({
                type: 'Users' as const,
                id: get(grp, 'id'),
              })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }];
      },
      query: ([branchId, pageMeta, params = {}]) => {
        const query = {
          ...params,
          populate: {
            ...get(params, 'populate', {}),
            users: {
              populate: { role: { fields: ['id', 'name', 'type'] } },
              fields: [
                'id',
                'firstName',
                'lastName',
                'email',
                'createdAt',
                'blocked',
              ],
            },
          },
          pagination: get(pageMeta, 'page')
            ? pageMeta
            : { page: pageMeta, pageSize: 100 },
        };
        return `/branches/${branchId}?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => ({
        data: getListModel<User[]>(get(res, 'data.attributes.users.data', []), {
          fullName: (val: User) => `${val?.firstName} ${val?.lastName}`,
          role: 'role.data.attributes',
        }),
        meta: get(res, 'meta', {}),
        original: get(res, 'data'),
      }),
    }),
    getBranch: builder.query<Branch, Branch['id']>({
      query: (branchId) => `/branches/${branchId}`,
      transformResponse: (res, meta, arg) => getDataModel(get(res, 'data', {})),
    }),
    updateBranch: builder.mutation<any, { id: Branch['id'] } & Partial<Branch>>(
      {
        invalidatesTags: (result, error, { id }) => [
          { type: 'UserContext' },
          { type: 'Branches', id: 'LIST' },
          { type: 'DisplayTemplates', id: 'LIST' },
          { type: 'Users', id: 'LIST' },
        ],
        query: ({ id, ...data }) => ({
          url: `/branches/${id}`,
          method: 'PUT',
          body: { data },
        }),
      }
    ),
    deleteBranchUser: builder.mutation<any, BranchUserReq>({
      query: ({ branchId, userId }) => ({
        url: `/branches/${branchId}`,
        method: 'PUT',
        body: {
          data: {
            users: {
              disconnect: [{ id: userId }],
              connect: [],
            },
          },
        },
      }),
      // Invalidates all queries that subscribe to Users ids only.
      invalidatesTags: (result, error, { userId }) => [
        { type: 'Users', id: userId },
      ],
    }),
    getBranchBySlug: builder.query<Branch | null, BranchRequest>({
      query: ({ slug, user }) => {
        const query = {
          pagination: { limit: 1 },
          filters: {
            users: { id: { $eq: user.id } },
            uid: { $eq: slug },
          },
        };
        return `/branches?${qs.stringify(query, { encodeValuesOnly: true })}`;
      },
      transformResponse: (res, meta, arg) =>
        get(res, 'data.0') ? getDataModel(get(res, 'data.0', {})) : null,
    }),
  }),
  overrideExisting: true,
});

export const {
  useUpdateBranchMutation,
  useDeleteBranchUserMutation,
  useGetBranchBySlugQuery,
  useGetBranchesQuery,
  useGetBranchQuery,
  useGetBranchUsersQuery,
  useGetDefaultBranchQuery,
  usePrefetch,
} = branchesApi;

export default branchesApi;
