/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get, startCase } from 'lodash';
import { Duration } from 'luxon';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Highlight,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  StyleProps,
  Tooltip,
  useControllableState,
  VStack,
} from '@chakra-ui/react';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  Settings,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from 'emotion-icons/material';
import { useDesignContext } from '..';
import { useDebounceCallback } from '../../hooks';
import aptsApi from '../../../../services/appointments';
import { LayoutPaddingControls } from './layout';

interface DurationSliderProps {
  defaultValue?: number;
  formatLabel?: (value: number) => string;
  highlightQuery?: (value: number) => string;
  onChange?: (value: number) => void;
  value?: number;
  min?: number;
  max?: number;
  children?: React.ReactNode;
  thumbColor?: StyleProps['color'];
  tooltip?: string;
}

const getFormatFromSeconds = (seconds: number) => {
  const d = Duration.fromMillis(seconds * 60 * 1000);
  if (d.as('hours') >= 1) return d.toFormat("h 'hrs,' m 'min'");
  else return d.toFormat("m 'min'");
};

const getFormatFromMillis = (mils: number) => {
  const d = Duration.fromMillis(mils);
  if (d.as('hours') >= 1) return 'hour';
  else if (d.as('minutes') >= 1) return d.toFormat("m 'min'");
  return d.toFormat("s 'sec'");
};

const SliderSecondsTrack = () => {
  const labelStyles = { mt: '2', fontSize: 'sm' };
  return (
    <>
      <SliderTrack bg="blue.100">
        <SliderFilledTrack bg="blue" />
      </SliderTrack>
      <SliderMark value={1} {...labelStyles}>
        1min
      </SliderMark>
      <SliderMark value={360} {...labelStyles}>
        6hr
      </SliderMark>

      <SliderMark ml="-7" value={720} {...labelStyles}>
        12hr
      </SliderMark>
    </>
  );
};

const SliderMillsTrack = ({ color = 'blue' }: Pick<StyleProps, 'color'>) => (
  <>
    <SliderTrack bg={`${color}.100`}>
      <SliderFilledTrack bg={color} />
    </SliderTrack>
    <SliderMark value={10000} mt={3} ml="-1">
      10sec
    </SliderMark>
    <SliderMark value={1.8e6} mt={3} ml="-5">
      30min
    </SliderMark>
    <SliderMark value={3.6e6} mt={3} ml="-7">
      1hrs
    </SliderMark>
  </>
);

const SliderPercentTrack = ({ color = 'blue' }: Pick<StyleProps, 'color'>) => (
  <>
    <SliderTrack bg={`${color}.100`}>
      <SliderFilledTrack bg={color} />
    </SliderTrack>
    <SliderMark value={1} mt={3} ml="-1">
      1
    </SliderMark>
    <SliderMark value={50} mt={3} ml="-5">
      50
    </SliderMark>
    <SliderMark value={100} mt={3} ml="-7">
      100
    </SliderMark>
  </>
);
const DurationSlider = ({
  onChange,
  value,
  defaultValue = 60,
  formatLabel,
  highlightQuery = getFormatFromSeconds,
  min = 1,
  max = 720,
  children,
  thumbColor,
  tooltip,
}: DurationSliderProps) => {
  const [sliderValue, setSliderValue] = useControllableState({
    value,
    onChange,
    defaultValue: defaultValue,
  });
  const [showTooltip, setShowTooltip] = React.useState(false);

  const labelStyles = {
    mt: '2',
    fontSize: 'sm',
  };

  // reset slider value when default value changes
  React.useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);

  return (
    <VStack w="full" fontSize="sm" align="start">
      <Tooltip label={tooltip}>
        <Box hidden={!formatLabel} as="span">
          <Highlight
            query={highlightQuery(sliderValue)}
            styles={{
              py: '1',
              fontWeight: 'bold',
              fontStyle: 'italic',
              textDecoration: 'underline',
            }}
            children={formatLabel?.(sliderValue) || ''}
          />
        </Box>
      </Tooltip>
      <Slider
        w="full"
        size="sm"
        min={min}
        max={max}
        value={sliderValue}
        onChange={setSliderValue}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
        <Tooltip
          hasArrow
          bg="blue.800"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={formatLabel?.(sliderValue)}
        >
          <SliderThumb bg={thumbColor} />
        </Tooltip>
      </Slider>
    </VStack>
  );
};

export const AppointmentsSettingsControls = () => {
  const dispatch = useDispatch();
  const { config, updateConfig } = useDesignContext();
  /** `default=60 minutes` */
  const showBeforeMins = get(config, 'options.showBeforeMins', 60);
  const showAfterMins = get(config, 'options.showAfterMins', 60);
  /*How frequently to automatically re-fetch data (in milliseconds).*/
  const refetchInterval = get(config, 'options.refetchInterval', 10000);
  /** `default=5000 milliseconds`, frequency to automatically change appointments page  */
  const paginateInterval = get(config, 'options.paginateInterval', 10000);
  /** `default=100 appointments`, max number of appointments to display at once */
  const maxPageSize = get(config, 'options.displayMax', 100);

  const handleOnDurationChange = useDebounceCallback(
    (
      value: number,
      type:
        | 'showBeforeMins'
        | 'showAfterMins'
        | 'refetchInterval'
        | 'paginateInterval'
        | 'displayMax'
    ) => {
      const prevValue = { showBeforeMins, showAfterMins }[type];
      if (value === prevValue) return;
      updateConfig?.({ options: { [type]: value } });
    },
    [updateConfig, config?.options]
  );

  React.useEffect(() => {
    dispatch(aptsApi.util.invalidateTags(['Appointments']));
  }, [maxPageSize, showAfterMins, showBeforeMins]);

  return (
    <VStack w="full" px={2} spacing={10}>
      <DurationSlider
        tooltip="Duration in minutes to keep showing the appointment after scheduledAt passed"
        defaultValue={showAfterMins}
        onChange={(v) => handleOnDurationChange(v, 'showAfterMins')}
        formatLabel={(v) => `Display for ${getFormatFromSeconds(v)} after`}
        children={<SliderSecondsTrack />}
      />
      <DurationSlider
        tooltip="Max duration in minutes to show upcoming appointments."
        defaultValue={showBeforeMins}
        formatLabel={(v) => `Display ${getFormatFromSeconds(v)} before`}
        onChange={(v) => handleOnDurationChange(v, 'showBeforeMins')}
        children={<SliderSecondsTrack />}
      />
      <DurationSlider
        tooltip="Maximum number of appointments to display at once."
        defaultValue={maxPageSize}
        formatLabel={(v) => `Max display ${v} appointments`}
        highlightQuery={(v) => `${v}`}
        onChange={(v) => handleOnDurationChange(v, 'displayMax')}
        max={100}
        min={1}
        children={<SliderPercentTrack />}
      />
      <DurationSlider
        tooltip="How frequently we scroll hidden appointments into view."
        defaultValue={paginateInterval}
        formatLabel={(v) => `Auto scroll every ${getFormatFromMillis(v)}`}
        highlightQuery={getFormatFromMillis}
        onChange={(v) => handleOnDurationChange(v, 'paginateInterval')}
        max={3.6e6}
        min={10000}
        thumbColor="orange.500"
        children={<SliderMillsTrack color="orange" />}
      />
      <DurationSlider
        tooltip="How frequent we update the appointments."
        defaultValue={refetchInterval}
        formatLabel={(v) => `Refetch every ${getFormatFromMillis(v)}`}
        highlightQuery={getFormatFromMillis}
        max={3.6e6}
        min={10000}
        onChange={(v) => handleOnDurationChange(v, 'refetchInterval')}
        thumbColor="red.500"
        children={<SliderMillsTrack color="red" />}
      />
    </VStack>
  );
};
