/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import omit from 'lodash/omit';
import { UITemplateSection, useCtxOptions, useUITemplateSort } from '.';
import TemplateBlock from './TemplateBlock';

export interface TemplateSectionProps extends UITemplateSection {}

const TemplateSection = (props: TemplateSectionProps) => {
  const opts = useCtxOptions();
  const [blocks] = useUITemplateSort(props?.blocks);
  return (
    <TemplateBlock
      {...opts?.section?.(omit(props, 'key'))}
      type="section"
      pos="relative"
      zIndex={1}
      label={props?.name}
      block={{
        ...props?.properties,
        ...props,
      }}
    >
      {blocks?.map((block, index) => (
        <TemplateBlock
          {...opts?.content?.(block)}
          key={`${block?.id || block.blockUID || index}_${block?.source}`}
          block={block}
          justify="center"
          type="content"
        />
      ))}
    </TemplateBlock>
  );
};

export default TemplateSection;
