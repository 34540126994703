/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useState } from 'react';
import { useTimeout } from '@chakra-ui/react';
import some from 'lodash/some';

export const useLoadingTimeout = (deps: boolean[], timeout: number = 2000) => {
  const [isTimeout, setIsTimeout] = useState(false);
  useTimeout(() => setIsTimeout(true), timeout);
  React.useEffect(() => {
    if (isTimeout) setIsTimeout(false);
  }, [deps]);
  return some(deps, Boolean) && !isTimeout;
};
