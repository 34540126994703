/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import SideNav, { NavItem } from '../components/Nav/sidenav';
import {
  CalendarIcon,
  EyeIcon,
  UsersIcon,
  BlockIcon,
  DocsIcon,
  BlogIcon,
  FolderIcon,
} from '../components/Icons';

const MainSideNav = () => {
  return (
    <SideNav>
      <NavItem to="appointments" text="Appointments" icon={<CalendarIcon />} />
      <NavItem text="Content Manager" icon={<DocsIcon />}>
        <NavItem
          to="content-manager/media"
          text="Media Library"
          icon={<FolderIcon />}
        />
        <NavItem
          to="content-manager/texts"
          text="Text Library"
          icon={<BlogIcon />}
        />
      </NavItem>
      <NavItem to="displays" text="Displays" icon={<EyeIcon />} />
      <NavItem
        to="template-editor"
        text="Display Template"
        icon={<BlockIcon />}
      />
      <NavItem to="users" text="Users" icon={<UsersIcon />} />
    </SideNav>
  );
};

export default MainSideNav;
