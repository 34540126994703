/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { createContext, useContext } from 'react';
import { Search, ExpandMore } from 'emotion-icons/material';
import { uniqBy } from 'lodash';
import {
  InputLeftElement,
  InputGroup,
  Input,
  Box,
  InputProps,
  Tabs,
  TabList,
  Tab,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  ButtonProps,
  Spacer,
} from '@chakra-ui/react';
import { Table, RowData, RowModel } from '@tanstack/react-table';
import { ConfirmDialog } from '../../ConfirmActionDialog';

export interface TabFilter {
  label: string;
  meta?: any;
}

interface BulkAction<T extends RowData> extends Omit<ButtonProps, 'onClick'> {
  label: string;
  onClick?: (rows: RowModel<T> | undefined) => Promise<any> | void;
  confirmDialog?: Omit<ConfirmDialog, 'onSubmit'>;
}

export interface TableHeadersProps<T extends RowData> {
  table?: Table<T>;
  tabs?: TabFilter[];
  debounce?: number;
  bulkActions?: BulkAction<T>[];
  tabRightElement?: React.ReactElement;
  onSearchChange?: (value: string) => void;
  onTabChange?: (tab: TabFilter) => void;
}

export const TableHeadersContext = createContext<TableHeadersProps<any>>({});

export const SearchInput = (props: InputProps) => {
  const { onSearchChange, debounce } = useContext(TableHeadersContext);
  const [value, setValue] = React.useState<string>('');
  let timeoutId = React.useRef<any>();

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Search size={24} />
      </InputLeftElement>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          clearTimeout(timeoutId.current);
          timeoutId.current = setTimeout(
            () => onSearchChange?.(e.target.value),
            debounce
          );
        }}
        {...props}
        type="search"
        placeholder="Search..."
      />
    </InputGroup>
  );
};

export const TabFilters = (props: { children?: React.ReactNode }) => {
  const { tabs = [], onTabChange } = useContext(TableHeadersContext);
  return (
    <Tabs isManual onChange={(idx) => onTabChange?.(tabs?.[idx])}>
      <TabList px={6}>
        {tabs.map((tab) => (
          <Tab key={tab.label} p={4}>
            {tab?.label}
          </Tab>
        ))}
        <Spacer />
        {!!props?.children && <HStack children={props.children} />}
      </TabList>
    </Tabs>
  );
};

export const BulkActions = () => {
  const { table, bulkActions } = useContext(TableHeadersContext);
  const { openConfirmDialog } = table?.options as any;
  const selectedRowModel = table?.getSelectedRowModel();

  if (!selectedRowModel?.rows?.length) return null;
  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<ExpandMore />}
          variant="outline"
          colorScheme="blue"
        >
          Bulk Actions
        </MenuButton>
        <MenuList px={2} w="fit-content">
          {bulkActions?.map(({ label, confirmDialog, ...props }, indx) => (
            <MenuItem
              {...props}
              as={Button}
              key={label}
              children={`${label} (${selectedRowModel?.rows?.length})`}
              onClick={() =>
                openConfirmDialog?.({
                  ...confirmDialog,
                  onSubmit: () => props?.onClick?.(selectedRowModel),
                })
              }
            />
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export const TableHeader = <T extends RowData>(props: TableHeadersProps<T>) => (
  <TableHeadersContext.Provider
    value={{
      bulkActions: props?.bulkActions,
      debounce: props?.debounce || 500,
      onSearchChange: props?.onSearchChange,
      onTabChange: props?.onTabChange,
      table: props?.table,
      tabs: uniqBy(props?.tabs, 'label'),
    }}
  >
    {!!props?.tabs?.length && <TabFilters children={props?.tabRightElement} />}
    {(props?.bulkActions || props?.onSearchChange) && (
      <HStack p={6}>
        {props?.bulkActions && <BulkActions />}
        {props?.onSearchChange && <SearchInput />}
      </HStack>
    )}
  </TableHeadersContext.Provider>
);

export default SearchInput;
