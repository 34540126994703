/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useRouteError, Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  Portal,
  AlertIcon,
  Spacer,
  VStack,
  Text,
  Alert,
} from '@chakra-ui/react';

import { ArrowBack, Logout } from 'emotion-icons/material-sharp';

import { AppLogo } from '../../components/Nav/logo';
import { selectApp } from '../../app/slice';
import { useAuthContext } from '../../hooks/auth';

const Error = () => {
  const { user, logout } = useAuthContext();
  const { branch } = useSelector(selectApp);
  const routeError = useRouteError();

  return (
    <Portal>
      <Box
        top="0"
        p="40px"
        position="absolute"
        boxSize="full"
        bg="white"
        zIndex="modal"
      >
        <HStack gap={2}>
          <Box hidden={!user?.email}>
            <Text fontSize="lg">Log Into MyWelcomeTv</Text>
            <Text>{user?.email}</Text>
          </Box>
          <Spacer />
          <Button
            bg="none"
            colorScheme="blue"
            variant="link"
            as={RouterLink}
            leftIcon={<ArrowBack />}
            to={branch?.slug ? `/${branch?.slug}/appointments` : '/login'}
            sx={{ fontWeight: 'bold' }}
            replace
          >
            Back to WelcomeTv
          </Button>
          <Button
            size="sm"
            colorScheme="blue"
            rightIcon={<Logout />}
            fontWeight="bold"
            onClick={logout}
          >
            Log out
          </Button>
        </HStack>
        <Center h="50vh">
          <VStack spacing="4rem">
            <AppLogo width="152px" />
            <VStack spacing="1rem">
              <Alert status={routeError ? 'error' : 'warning'}>
                <AlertIcon />
                {get(routeError, 'data') ||
                  get(routeError, 'message', 'Something went wrong.')}
              </Alert>
              <VStack spacing={3} align="left">
                <Text>For more information, please contact us:</Text>
                <HStack>
                  <Text fontWeight="bold">Phone:</Text>
                  <Link href="tel:305.665.2500">305.665.2500</Link>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Email:</Text>
                  <Link color="blue" href="mailto:support@mywelcometv.com">
                    support@mywelcometv.com
                  </Link>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        </Center>
      </Box>
    </Portal>
  );
};

export default Error;
