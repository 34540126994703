/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import trim from 'lodash/trim';
import { assign, get, omit, pick } from 'lodash';
import {
  Box,
  Icon,
  IconButton,
  VStack,
  Tooltip,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { East, South } from 'emotion-icons/material';
import {
  AlignBottom,
  AlignCenter,
  AlignEnd,
  AlignMiddle,
  AlignStart,
  AlignTop,
} from 'emotion-icons/bootstrap';

import {
  LayoutHorizontalGapIcon,
  LayoutHorizontalPaddingIcon,
  LayoutIndividualPaddingIcon,
  LayoutPaddingBottomIcon,
  LayoutPaddingLeftIcon,
  LayoutPaddingRightIcon,
  LayoutPaddingTopIcon,
  LayoutVerticalGapIcon,
  LayoutVerticalPaddingIcon,
  LayoutWrapIcon,
} from '../../../../components/Icons';
import { useDesignContext } from '..';
import { EditableSelect } from '../../../../components/ComboBox';
import { useDebounceCallback } from '../../hooks';
import {
  UITemplateBlock,
  UITemplateSection,
} from '../../../../components/DisplayTemplate';
import { TextAlignOptions } from './texts';
import { MediaAlignOptions, SizeTypes } from '.';
import { useDispatch } from 'react-redux';
import { updateTemplateSection } from '../../slice';

type FlexDirection = 'row' | 'column' | 'wrap';

const useDirection = () => {
  const { config } = useDesignContext();
  return (config?.style?.flexWrap ||
    config?.style?.flexDirection ||
    'row') as FlexDirection;
};

const useUpdateBlocksTransform = () => {
  const dispatch = useDispatch();
  const { block } = useDesignContext<UITemplateSection>();
  return React.useCallback(
    (direction: FlexDirection) => {
      dispatch(
        updateTemplateSection({
          ...pick(block, ['id', 'blockUID']),
          blocks:
            block?.blocks?.map((item) => {
              const widthType = get(item, 'config.options.widthType', 'Fill');
              const heightType = get(item, 'config.options.heightType', 'Fill');
              const prevStyle = item?.config?.style;
              const style = {
                ...omit(prevStyle, 'flexGrow'),
                ...(direction == 'column' &&
                  heightType === SizeTypes.Fill && { flexGrow: 1 }),
                ...(direction == 'row' &&
                  widthType === SizeTypes.Fill && { flexGrow: 1 }),
              };
              return assign({}, item, { config: { style } });
            }) || [],
        })
      );
    },
    [block]
  );
};

const LayoutDirectionControls = () => {
  const { block, updateConfig } = useDesignContext<UITemplateSection>();
  const direction = useDirection();
  const updateBlocksTransform = useUpdateBlocksTransform();
  const handleUpdateDirection = React.useCallback(
    (flexDirection: 'column' | 'row') => {
      updateConfig?.({ style: { flexDirection } }, ['style.flexWrap']);
      updateBlocksTransform(flexDirection);
    },
    [updateConfig, block?.blocks]
  );
  const handleDirectionWrap = React.useCallback(
    () =>
      updateConfig?.({ style: { flexWrap: 'wrap' } }, ['style.flexDirection']),
    [updateConfig]
  );
  return (
    // TODO: Will hide this functionality for now
    <ButtonGroup hidden size="sm" isAttached variant="outline">
      <Tooltip label="Vertical layout" size="xs" openDelay={500}>
        <IconButton
          aria-label="Vertical layout"
          icon={<Icon as={South} />}
          isActive={direction === 'column'}
          onClick={() => handleUpdateDirection('column')}
        />
      </Tooltip>
      <Tooltip label="Horizontal layout" size="xs" openDelay={500}>
        <IconButton
          aria-label="Horizontal layout"
          icon={<Icon as={East} />}
          isActive={direction === 'row'}
          onClick={() => handleUpdateDirection('row')}
        />
      </Tooltip>
      <Tooltip label="Wrap" size="xs" openDelay={500}>
        <IconButton
          aria-label="Wrap"
          icon={<Icon as={LayoutWrapIcon} />}
          isActive={direction === 'wrap'}
          onClick={handleDirectionWrap}
        />
      </Tooltip>
    </ButtonGroup>
  );
};

const LayoutSpacingControls = () => {
  const { config, updateConfig } = useDesignContext();
  const direction = useDirection();
  const horizontal = get(config, 'style.columnGap', '0rem') as string;
  const vertical = get(config, 'style.rowGap', '0rem') as string;
  const gap = React.useMemo(
    () => ({
      direction,
      horizontal: trim(horizontal, 'rem'),
      vertical: trim(vertical, 'rem'),
      auto:
        direction === 'wrap'
          ? { vertical: 'alignContent', horizontal: 'justifyContent' }
          : { vertical: 'justifyContent', horizontal: 'justifyContent' },
    }),
    [config?.style, direction]
  );

  const onHorizontalChange = useDebounceCallback(
    (value?: string) => {
      if (value === 'Auto') {
        return updateConfig?.(
          { style: { [gap.auto.horizontal]: 'space-between' } },
          ['style.columnGap']
        );
      } else {
        const val = parseFloat(value || '0');
        updateConfig?.({ style: { columnGap: `${val}rem` } }, [
          `style.${gap.auto.horizontal}`,
        ]);
      }
    },
    [updateConfig, gap]
  );
  const onVerticalChange = useDebounceCallback(
    (value?: string) => {
      if (value === 'Auto') {
        return updateConfig?.(
          { style: { [gap.auto.vertical]: 'space-between' } },
          ['style.rowGap']
        );
      } else {
        const val = parseFloat(value || '0');
        updateConfig?.({ style: { rowGap: `${val}rem` } }, [
          `style.${gap.auto.vertical}`,
        ]);
      }
    },
    [updateConfig, gap]
  );

  return (
    <VStack>
      {(direction === 'row' || direction === 'wrap') && (
        <EditableSelect
          tooltip="Horizontal gap between items"
          leftElement={<LayoutHorizontalGapIcon />}
          defaultOption={!!get(config, 'style.columnGap') ? 0 : 1}
          onOptionChange={onHorizontalChange}
          options={[
            {
              type: 'number',
              min: 0,
              isEditable: true,
              defaultValue: gap.horizontal,
              label: gap.horizontal,
              step: 0.1,
              precision: 1,
            },
            {
              label: 'Auto',
              value: 'Auto',
            },
          ]}
        />
      )}
      {(direction === 'column' || direction === 'wrap') && (
        <EditableSelect
          tooltip="Vertical gap between items"
          leftElement={<LayoutVerticalGapIcon />}
          defaultOption={!!get(config, 'style.rowGap') ? 0 : 1}
          onOptionChange={onVerticalChange}
          options={[
            {
              type: 'number',
              min: 0,
              isEditable: true,
              defaultValue: gap.vertical,
              label: gap.vertical,
              step: 0.1,
              precision: 1,
            },
            {
              label: 'Auto',
              value: 'Auto',
            },
          ]}
        />
      )}
    </VStack>
  );
};

const LayoutAlignControls = () => {
  const { updateConfig, config } = useDesignContext();
  const direction = useDirection();
  const options = {
    row: {
      vertical: 'justifyContent',
      horizontal: 'alignItems',
    },
    column: {
      vertical: 'alignItems',
      horizontal: 'justifyContent',
    },
    wrap: {
      vertical: 'justifyContent',
      horizontal: 'alignContent',
    },
  }[direction];

  const handleUpdateAlign = React.useCallback(
    (align: 'start' | 'center' | 'end', dir: 'vertical' | 'horizontal') => {
      const alignPath = options?.[dir];
      const isActive = get(config, `style.${alignPath}`) === align;
      if (isActive) updateConfig?.({}, [`style.${alignPath}`]);
      else updateConfig?.({ style: { [alignPath]: align } });
    },
    [updateConfig, options, config]
  );

  return (
    <GridItem as={VStack}>
      <ButtonGroup variant="outline" size="sm" isAttached>
        <Tooltip label="Align Left" size="xs" openDelay={500}>
          <IconButton
            icon={<Box as={AlignStart} />}
            aria-label="Align Left"
            isActive={get(config, `style.${options.vertical}`) === 'start'}
            onClick={() => handleUpdateAlign('start', 'vertical')}
          />
        </Tooltip>
        <Tooltip label="Align Center" size="xs" openDelay={500}>
          <IconButton
            icon={<Box as={AlignCenter} />}
            aria-label="Align Center"
            isActive={get(config, `style.${options.vertical}`) === 'center'}
            onClick={() => handleUpdateAlign('center', 'vertical')}
          />
        </Tooltip>
        <Tooltip label="Align Right" size="xs" openDelay={500}>
          <IconButton
            icon={<Box as={AlignEnd} />}
            aria-label="Align Right"
            isActive={get(config, `style.${options.vertical}`) === 'end'}
            onClick={() => handleUpdateAlign('end', 'vertical')}
          />
        </Tooltip>
      </ButtonGroup>
      <ButtonGroup variant="outline" size="sm" isAttached>
        <Tooltip label="Align Vertical Top" size="xs" openDelay={500}>
          <IconButton
            icon={<Box as={AlignTop} />}
            aria-label="Align Vertical Top"
            isActive={get(config, `style.${options.horizontal}`) === 'start'}
            onClick={() => handleUpdateAlign('start', 'horizontal')}
          />
        </Tooltip>
        <Tooltip label="Align Vertical Center" size="xs" openDelay={500}>
          <IconButton
            icon={<Box as={AlignMiddle} />}
            aria-label="Align Vertical Center"
            isActive={get(config, `style.${options.horizontal}`) === 'center'}
            onClick={() => handleUpdateAlign('center', 'horizontal')}
          />
        </Tooltip>
        <Tooltip label="Align Vertical Center" size="xs" openDelay={500}>
          <IconButton
            icon={<Box as={AlignBottom} />}
            aria-label="Align Vertical Bottom"
            isActive={get(config, `style.${options.horizontal}`) === 'end'}
            onClick={() => handleUpdateAlign('end', 'horizontal')}
          />
        </Tooltip>
      </ButtonGroup>
    </GridItem>
  );
};

export const LayoutPaddingControls = () => {
  const { updateConfig, config } = useDesignContext();
  const individual = get(config, `options.individualPadding`, false);
  const padding = React.useMemo(() => {
    const px = get(config, 'style.px', '0') as string;
    const py = get(config, 'style.py', '0') as string;
    const pt = get(config, 'style.pt', '0') as string;
    const pb = get(config, 'style.pb', '0') as string;
    const pl = get(config, 'style.pl', '0') as string;
    const pr = get(config, 'style.pr', '0') as string;
    return {
      px: trim(px, 'rem'),
      py: trim(py, 'rem'),
      pt: trim(pt, 'rem'),
      pb: trim(pb, 'rem'),
      pl: trim(pl, 'rem'),
      pr: trim(pr, 'rem'),
    };
  }, [config?.style, individual]);

  const setIndividual = React.useCallback(
    (value: boolean) => {
      const cstyle = get(config, 'style');
      const px = get(cstyle, 'px', get(cstyle, 'pl', '0')) as string;
      const py = get(cstyle, 'py', get(cstyle, 'pt', '0')) as string;
      const padding = value ? { pt: py, pb: py, pl: px, pr: px } : { px, py };
      updateConfig?.(
        { style: { ...padding }, options: { individualPadding: value } },
        value
          ? ['style.px', 'style.py']
          : ['style.pt', 'style.pb', 'style.pl', 'style.pr']
      );
    },
    [updateConfig]
  );

  const onPaddingChange = useDebounceCallback(
    (value?: string, key?: keyof typeof padding) => {
      if (!value || value === '0') return updateConfig?.({}, [`style.${key}`]);
      updateConfig?.({ style: { [`${key}`]: `${value}rem` } });
    },
    [updateConfig, padding]
  );

  return (
    <>
      <GridItem colSpan={2}>
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem as={VStack}>
            {individual ? (
              <>
                <EditableSelect
                  tooltip="Left padding"
                  leftElement={<Icon as={LayoutPaddingLeftIcon} />}
                  type="number"
                  defaultValue={padding.pl}
                  onChange={(v) => onPaddingChange(v, 'pl')}
                  min={0}
                />
                <EditableSelect
                  tooltip="Right padding"
                  leftElement={<Icon as={LayoutPaddingRightIcon} />}
                  type="number"
                  defaultValue={padding.pr}
                  onChange={(v) => onPaddingChange(v, 'pr')}
                  min={0}
                />
              </>
            ) : (
              <EditableSelect
                tooltip="Horizontal padding"
                leftElement={<Icon as={LayoutHorizontalPaddingIcon} />}
                type="number"
                defaultValue={padding.px}
                onChange={(v) => onPaddingChange(v, 'px')}
                min={0}
              />
            )}
          </GridItem>
          <GridItem as={VStack}>
            {individual ? (
              <>
                <EditableSelect
                  tooltip="Top padding"
                  leftElement={<Icon as={LayoutPaddingTopIcon} />}
                  type="number"
                  defaultValue={padding.pt}
                  onChange={(v) => onPaddingChange(v, 'pt')}
                  min={0}
                />
                <EditableSelect
                  tooltip="Bottom padding"
                  leftElement={<Icon as={LayoutPaddingBottomIcon} />}
                  type="number"
                  defaultValue={padding.pb}
                  onChange={(v) => onPaddingChange(v, 'pb')}
                  min={0}
                />
              </>
            ) : (
              <EditableSelect
                tooltip="Vertical padding"
                leftElement={<Icon as={LayoutVerticalPaddingIcon} />}
                type="number"
                defaultValue={padding.py}
                onChange={(v) => onPaddingChange(v, 'py')}
                min={0}
              />
            )}
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem>
        <Tooltip label="Individual padding" size="xs" openDelay={500}>
          <IconButton
            size="xs"
            variant="outline"
            aria-label="Individual layout"
            icon={<Icon as={LayoutIndividualPaddingIcon} />}
            isActive={individual}
            onClick={() => setIndividual(!individual)}
          />
        </Tooltip>
      </GridItem>
    </>
  );
};

export const LayoutControls = () => {
  return (
    <Grid
      templateColumns="repeat(2, 1fr) min-content"
      templateRows="repeat(2, min-content)"
      gap={1}
    >
      <GridItem as={VStack}>
        <LayoutDirectionControls />
        <LayoutSpacingControls />
      </GridItem>
      <LayoutAlignControls />
      <LayoutPaddingControls />
    </Grid>
  );
};

export const ContentLayoutControls = () => {
  const { block } = useDesignContext<UITemplateBlock>();
  return (
    <>
      {block?.source === 'texts' && <TextAlignOptions />}
      <Grid
        templateColumns="repeat(2, 1fr) min-content"
        templateRows="repeat(2, min-content)"
        gap={1}
      >
        <LayoutPaddingControls />
      </Grid>
      {block?.source === 'media' && <MediaAlignOptions />}
    </>
  );
};
