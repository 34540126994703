/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridItem } from '@chakra-ui/react';
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
  Outlet,
  Navigate,
} from 'react-router-dom';

import { selectApp } from './slice';
import { branchLoader } from '../hooks/branch';

import MainAppBar from '../components/Nav/navbar';
import MainSideNav from './menu';
import Appointments from '../pages/Appointments';
import BranchSettings from '../pages/BranchSettings';
import BranchUsers from '../pages/BranchUsers';
import Error from '../pages/Error';
import FolderAssets from '../pages/ContentManager/FolderAssets';
import Login from '../pages/Auth/Login';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import MediaLibrary from '../pages/ContentManager/MediaLibrary';
import TemplateEditor from '../pages/TemplateEditor';
import TextLibrary from '../pages/ContentManager/TextLibrary';
import Displays, { NavigateToLinkDisplay } from '../pages/Displays';

interface ProtectedRouteProps {
  redirectPath?: string;
  children?: React.ReactElement;
}

const AppLayout = () => (
  <Grid
    templateAreas={`"header header""nav main""nav main"`}
    gridTemplateColumns={'auto 1fr'}
  >
    <GridItem area="header">
      <MainAppBar />
    </GridItem>
    <GridItem area="nav" pt={16}>
      <MainSideNav />
    </GridItem>
    <GridItem area="main" as="main" pt={16} maxH="100vh" overflowY="auto">
      <ProtectedRoute />
    </GridItem>
  </Grid>
);

const ProtectedRoute = ({
  children,
  redirectPath = '/login',
}: ProtectedRouteProps) => {
  const {
    auth: { isAuthenticated },
  } = useSelector(selectApp);
  if (!isAuthenticated) return <Navigate to={redirectPath} replace />;
  return children ? children : <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<Outlet />}>
      <Route index element={<Navigate to="/login" />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path=":branchId/*"
        errorElement={<Error />}
        element={<AppLayout />}
        loader={branchLoader}
      >
        <Route path="appointments" element={<Appointments />} />
        <Route path="users" element={<BranchUsers />} />
        <Route path="settings" element={<BranchSettings />} />
        <Route path="displays" element={<Displays />} />
        <Route path="template-editor" element={<TemplateEditor />} />
        <Route path="content-manager">
          <Route index element={<Navigate to="media" />} />
          <Route path="texts" element={<TextLibrary />} />
          <Route path="media">
            <Route index element={<MediaLibrary />} />
            <Route path=":folderId" element={<FolderAssets />} />
          </Route>
        </Route>
        <Route path="mwtv" element={<NavigateToLinkDisplay />} />
        <Route index element={<Navigate to="/appointments" />} />
      </Route>
    </Route>
  )
);
export default () => <RouterProvider router={router} />;
