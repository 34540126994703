/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import { Image as ImageIcon, MoreVert } from 'emotion-icons/material';
import {
  AspectRatio,
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  chakra,
  Checkbox,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { Asset, AssetType, ItemSelection } from '..';

interface AssetCardProps extends ItemSelection, Omit<CardProps, 'id'> {
  actions?: AssetAction[];
  asset: Asset;
  enbaleAssetSelection?: boolean;
  isEditable?: boolean;
  isLoading?: boolean;
}

export interface AssetAction {
  label: string;
  icon?: React.ReactElement;
  onClick?: (asset: Asset) => void;
}

const getFileExtension = (ext?: string) =>
  ext && ext[0] === '.' ? ext.substr(1) : ext;

const CardIcon = ({ variant }: { variant: string }) => {
  if (variant.includes?.(AssetType.IMAGE))
    return <ImageIcon size={24} color="var(--chakra-colors-blue-500)" />;
  return null;
};

const LoadingOverlay = chakra(Box, {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    px: '1rem',
    bg: 'blackAlpha.300',
  },
});

const CardVariant = (props: Asset) => {
  const common = {
    ...props,
    mime: props?.mime || '',
    extension: props?.extension || getFileExtension(get(props, 'ext')),
    src: `${get(props, 'hostname', '')}${
      get(props, 'formats.thumbnail.url') ||
      get(props, 'url') ||
      get(props, 'src')
    }`,
    alt: get(props, 'meta.alternativeText', props.name),
  };

  if (common.mime.includes(AssetType.VIDEO)) {
    return <> No Video Preview</>;
  }

  if (common.mime.includes(AssetType.IMAGE)) {
    return (
      <Image
        src={common?.src}
        alt={common?.alt}
        height={common?.height}
        width={common?.width}
        sx={{
          objectFit: 'contain!important',
        }}
      />
    );
  }

  return null;
};

export const AssetCard = ({
  actions,
  color,
  enbaleAssetSelection,
  isEditable = true,
  isLoading,
  variant,
  ...props
}: AssetCardProps) => {
  return (
    <Card variant={variant} overflow="hidden">
      {isLoading && (
        <LoadingOverlay>
          <Progress isIndeterminate size="md" w="full" height="10px" />
        </LoadingOverlay>
      )}
      <CardHeader as={HStack} p={2}>
        {enbaleAssetSelection && (
          <Checkbox
            borderColor={color}
            isDisabled={!props?.getCanSelect?.()}
            isChecked={props?.getIsSelected?.()}
            onChange={props?.getToggleSelectedHandler?.()}
          />
        )}
        <Box>
          <CardIcon variant={props.asset.mime} />
        </Box>
        <Tooltip label={props.asset.name}>
          <Text size="xs" noOfLines={1} flexGrow={1}>
            {props.asset.name}
          </Text>
        </Tooltip>
        {!!actions && isEditable && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Folder Options"
              variant="ghost"
              size="sm"
              icon={<MoreVert size="24" color={color as string} />}
            />
            <MenuList>
              {actions?.map(({ label, onClick, icon }) => (
                <MenuItem
                  key={label}
                  onClick={() => onClick?.(props.asset)}
                  children={label}
                  icon={icon}
                />
              ))}
            </MenuList>
          </Menu>
        )}
      </CardHeader>
      <CardBody p={2}>
        <AspectRatio ratio={4 / 3} bg="media.grid" borderRadius="5px">
          <CardVariant {...props.asset} />
        </AspectRatio>
      </CardBody>
    </Card>
  );
};
