/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isMatch, startCase } from 'lodash';
import {
  motion,
  Reorder,
  AnimatePresence,
  useDragControls,
} from 'framer-motion';
import {
  Text,
  Box,
  Button,
  SimpleGrid,
  ButtonGroup,
  Icon,
  VStack,
  chakra,
  HStack,
  Divider,
  IconButton,
  IconButtonProps,
  StyleProps,
} from '@chakra-ui/react';
import {
  Crop75,
  Tag,
  TextFields,
  Image,
  Delete,
  DragIndicator,
  ExpandMore,
  ExpandLess,
  EventNote,
  Settings,
} from 'emotion-icons/material';

import { AddBlockIcon, AddSectionIcon } from '../../../components/Icons';
import { useBlockUID, useIsSectionBlock } from '../hooks';
import {
  UIDisplayTemplate,
  UITemplateBlock,
  UITemplateSection,
  useUITemplateSort,
} from '../../../components/DisplayTemplate';
import {
  addTemplateBlock,
  addTemplateSection,
  deleteTemplateBlock,
  deleteTemplateSection,
  discardTemplateChanges,
  EDITOR_MODE,
  selectEditableTemplate,
  selectorIsTemplateDirty,
  selectTemplateBlock,
  setEditorLoading,
  setEditorMode,
  templateSelectedBlockId,
  updateTemplateBlocks,
  updateTemplateSections,
} from '../slice';
import {
  useCreateDisplayTemplateMutation,
  useUpdateDisplayTemplateMutation,
} from '../../../services/display-templates';
import { useBranch } from '../../../hooks/branch';

const SELECTED_BG = '#E5F4FF';
const GROUP_HOVER_HIDDEN = {
  opacity: 0,
  _groupHover: {
    opacity: 1,
  },
};

const LayoutButton = chakra(Button, {
  baseStyle: {
    h: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    fontWeight: 'normal',
  },
});

const AccordionButton = chakra(HStack, {
  baseStyle: {
    border: '1px solid transparent',
    _hover: {
      border: '1px solid var(--chakra-colors-blue-500)',
    },
    span: {
      fontWeight: 'medium',
    },
  },
});

const ActionButton = React.forwardRef((props: IconButtonProps, ref) => (
  <IconButton
    ref={ref}
    {...props}
    size="xs"
    variant="unstyled"
    opacity={props?.['aria-selected'] ? 1 : GROUP_HOVER_HIDDEN.opacity}
    _groupHover={{ ...GROUP_HOVER_HIDDEN._groupHover, ...props?._groupHover }}
  />
));

const SectionPanel = chakra(motion.section, {
  baseStyle: {
    // px: 4
    overflow: 'hidden',
  },
});

const BlockItem = chakra(Reorder.Item, {
  baseStyle: {
    w: 'full',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    pl: 5,
    py: 2,
    border: '1px solid transparent',
    borderRadius: 'none',
    bg: 'none',
    _hover: {
      bg: 'none',
      border: '1px solid var(--chakra-colors-blue-500)',
    },
    svg: {
      boxSize: '1rem',
    },
  },
});

const AccordionBlock = (
  props: {
    block?: UITemplateBlock;
    section?: UITemplateSection;
  } & StyleProps
) => {
  const { section, block, ...boxProps } = props;

  const dispatch = useDispatch();
  const controls = useDragControls();
  const blockName = startCase(`${block?.source || 'New'}`);

  const selectedBlockId = useSelector(templateSelectedBlockId);
  const isSelected = React.useMemo(
    () => !!block && !!selectedBlockId && isMatch(block, selectedBlockId),
    [selectedBlockId, block]
  );

  const handleDeleteBlock = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!block || !section) return;
    dispatch(deleteTemplateBlock({ block, section }));
  };
  const handleEditBlock = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(selectTemplateBlock(block));
    dispatch(setEditorMode(EDITOR_MODE.EDIT));
  };

  return (
    <BlockItem
      value={block}
      dragListener={false}
      dragControls={controls}
      bg={isSelected ? SELECTED_BG : undefined}
      {...boxProps}
    >
      <HStack
        role="button"
        flexGrow={1}
        onClick={() => dispatch(selectTemplateBlock(block))}
      >
        {block?.source === 'texts' && <TextFields />}
        {block?.source === 'media' && <Image />}
        {block?.source === 'appointments' && <EventNote />}
        {(!block?.source || block?.source === 'none') && <Tag />}
        <Text ml={2} noOfLines={1}>
          {blockName}
        </Text>
      </HStack>
      <ActionButton
        icon={<Settings size={16} />}
        aria-label="edit-block"
        onClick={handleEditBlock}
      />
      <ActionButton
        icon={<Delete size={16} />}
        aria-label={`Delete Block`}
        onClick={handleDeleteBlock}
      />
      <ActionButton
        icon={<DragIndicator size={16} />}
        aria-label="drag-handler"
        onPointerDown={(e) => {
          e.preventDefault();
          controls.start(e);
        }}
      />
    </BlockItem>
  );
};

export const AccordionSectionBlocks = (props: {
  section?: UITemplateSection;
  blocksStyle?: StyleProps;
}) => {
  const { section, blocksStyle } = props;

  const dispatch = useDispatch();
  const sectionUID = useBlockUID(section);

  /** We debounce the new under until the motion reorder animation stopped */
  const onDispatchNewOrder = React.useCallback(
    debounce((newOrder: UITemplateBlock[]) => {
      dispatch(updateTemplateBlocks({ sectionUID, blocks: newOrder }));
    }, 500),
    [section?.blockUID, section?.id]
  );

  const [blocks, onReorder] = useUITemplateSort(
    section?.blocks,
    'config.sortOrder',
    (newOrder) => {
      onDispatchNewOrder.cancel();
      onDispatchNewOrder(newOrder);
    }
  );

  return (
    <Reorder.Group
      axis="y"
      values={blocks}
      onReorder={onReorder}
      style={{ listStyleType: 'none' }}
    >
      {blocks.map((item, index) => (
        <AccordionBlock
          key={item?.id || item?.blockUID || index}
          block={item}
          section={section}
          {...blocksStyle}
        />
      ))}
    </Reorder.Group>
  );
};

const AccordionSection = ({ section }: { section?: UITemplateSection }) => {
  const dispatch = useDispatch();
  const controls = useDragControls();
  const [isExpanded, setIsExpanded] = React.useState(false);

  const sectionUID = useBlockUID(section);
  const selectedBlockId = useSelector(templateSelectedBlockId);
  /** find if the selected block is within this section */
  const hasBlockSelected = useIsSectionBlock(section, selectedBlockId);
  /** find if the selected is this section */
  const isSelected = React.useMemo(
    () =>
      !!selectedBlockId && sectionUID && isMatch(selectedBlockId, sectionUID),
    [selectedBlockId]
  );

  const handleDeleteSection = (e: React.MouseEvent) => {
    e.stopPropagation();
    sectionUID && dispatch(deleteTemplateSection(sectionUID));
  };
  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(selectTemplateBlock(section));
    if (!isExpanded) setIsExpanded(true);
  };
  const handleDrag = (e: React.PointerEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsExpanded(false);
    controls.start(e);
  };
  const handleEditSection = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(selectTemplateBlock(section));
    dispatch(setEditorMode(EDITOR_MODE.EDIT));
  };

  return (
    <Reorder.Item
      data-group
      value={section}
      dragListener={false}
      dragControls={controls}
    >
      <AnimatePresence>
        <AccordionButton
          data-group
          bg={
            isSelected || (hasBlockSelected && !isExpanded)
              ? SELECTED_BG
              : undefined
          }
          spacing={0}
        >
          {isExpanded ? (
            <Icon as={ExpandLess} onClick={handleToggle} />
          ) : (
            <Icon as={ExpandMore} onClick={handleToggle} />
          )}
          <HStack w="full" spacing={2}>
            <Box
              as={Crop75}
              boxSize="1.5rem"
              onPointerDown={handleDrag}
              cursor="grab"
            />
            <Button
              variant="unstyled"
              display="flex"
              flexGrow={1}
              justifyContent="felx-start"
              onClick={handleSelect}
              children={
                section?.name || `Section ${section?.id || section?.blockUID}`
              }
            />
          </HStack>
          <ActionButton
            icon={<Settings size={16} />}
            aria-label="edit-section"
            onClick={handleEditSection}
          />
          <ActionButton
            icon={<Delete size={16} />}
            aria-label={`Delete Section ${section?.name}`}
            onClick={handleDeleteSection}
          />
          <ActionButton
            icon={<DragIndicator size="1rem" />}
            aria-label="drag-handler"
            onPointerDown={handleDrag}
            cursor="grab"
            aria-selected={isSelected || hasBlockSelected}
          />
        </AccordionButton>
        {isExpanded && (
          <SectionPanel
            key="section-panel"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: `${(section?.blocks?.length || 0) * 42}px`,
              },
              collapsed: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={{ duration: 0.8 } as any}
            bg={isSelected ? '#F2F9FF' : undefined}
          >
            <AccordionSectionBlocks section={section} />
          </SectionPanel>
        )}
      </AnimatePresence>
    </Reorder.Item>
  );
};
const LayoutControls = () => {
  const dispatch = useDispatch();
  const branch = useBranch();
  const editableTemplate = useSelector(selectEditableTemplate);
  const selectedBlockId = useSelector(templateSelectedBlockId);
  const isTemplateDirty = useSelector(selectorIsTemplateDirty);

  const [updateTemplate, { isLoading: isUpdating }] =
    useUpdateDisplayTemplateMutation();
  const [createTemplate, { isLoading: isCreating }] =
    useCreateDisplayTemplateMutation();

  /** We debounce the new under until the motion reorder animation stopped */
  const onDispatchNewOrder = React.useCallback(
    debounce((newOrder: UITemplateSection[]) => {
      dispatch(updateTemplateSections(newOrder));
    }, 500),
    []
  );

  const handleOnAddSection = React.useCallback(
    debounce(() => dispatch(addTemplateSection()), 200),
    []
  );
  const handleOnAddBlock = React.useCallback(
    debounce(() => {
      dispatch(addTemplateBlock());
    }, 200),
    []
  );
  const handleOnSave = React.useCallback(async () => {
    if (editableTemplate && !editableTemplate?.id) {
      return await createTemplate({
        ...editableTemplate,
        name: `${branch?.name} Template`,
        branch: { id: branch?.id },
      });
    } else if (editableTemplate?.id) {
      await updateTemplate({
        ...editableTemplate,
        id: editableTemplate.id,
      });
    }
  }, [editableTemplate, updateTemplate, branch]);

  const [sections, sortSections] = useUITemplateSort(
    editableTemplate?.sections,
    'properties.config.sortOrder',
    (newOrder) => {
      onDispatchNewOrder.cancel();
      onDispatchNewOrder(newOrder);
    }
  );

  // this will set the editor loading state
  React.useEffect(() => {
    dispatch(setEditorLoading(isUpdating || isCreating));
  }, [isUpdating, isCreating]);

  return (
    <VStack spacing={4}>
      <SimpleGrid
        px={2}
        as={ButtonGroup}
        variant="ghost"
        columns={2}
        spacing={1}
      >
        <LayoutButton onClick={handleOnAddSection}>
          <AddSectionIcon boxSize="2rem" />
          <span>Add Section</span>
        </LayoutButton>
        <LayoutButton isDisabled={!selectedBlockId} onClick={handleOnAddBlock}>
          <AddBlockIcon boxSize="2rem" />
          <span>Add Content</span>
        </LayoutButton>
      </SimpleGrid>
      <Box boxSize="full" h="calc(100vh - 17.5rem)">
        <Divider />
        <Reorder.Group
          axis="y"
          values={sections}
          onReorder={sortSections}
          style={{ listStyleType: 'none', height: '100%', overflow: 'auto' }}
          id="layout-controls"
        >
          {sections.map((item, index) => (
            <AccordionSection
              key={item?.id || item?.blockUID || index}
              section={item}
            />
          ))}
        </Reorder.Group>
      </Box>
      <HStack px={2} w="full" hidden={!isTemplateDirty}>
        <Button
          hidden={!editableTemplate?.id || isUpdating || isCreating}
          w="full"
          variant="outline"
          children="Discard"
          onClick={() => dispatch(discardTemplateChanges())}
        />
        <Button
          w="full"
          colorScheme="blue"
          children={editableTemplate?.id ? 'Save' : 'Create'}
          isLoading={isUpdating}
          onClick={handleOnSave}
        />
      </HStack>
    </VStack>
  );
};

export default LayoutControls;
