/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import some from 'lodash/some';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import {
  createStylesContext,
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  HStack,
  IconButton,
  List,
  ListItem,
  Spacer,
  useBreakpointValue,
  VStack,
  useMultiStyleConfig,
  ChakraStyledOptions,
  Fade,
  chakra,
} from '@chakra-ui/react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { ChevronRight, ChevronLeft, Menu } from 'emotion-icons/material';
import { selectNav, setNavIsOpen, setNavVariant } from '../../app/slice';
import { BranchLogo } from './logo';
import { LocationSelectMenu } from './navbar';
import { useAuthContext } from '../../hooks/auth';

type NavItemProps = Partial<NavLinkProps> &
  AccordionButtonProps & {
    text?: string;
    icon?: React.ReactNode;
  };

const [StylesProvider, useStyles] = createStylesContext('SideNav');

const MotionDiv = chakra(motion.div);

const NavOverlay = () => {
  const displatch = useDispatch();
  const { isOpen, variant } = useSelector(selectNav);
  const { overlay } = useStyles();
  if (variant !== 'temporary' || !isOpen) return null;
  return (
    <Fade
      in={isOpen}
      children={
        <Box __css={overlay} onClick={() => displatch(setNavIsOpen(false))} />
      }
    />
  );
};

export const SideNavButton = () => {
  const displatch = useDispatch();
  const { isOpen: ariaExpanded, variant } = useSelector(selectNav);
  const display = variant !== 'temporary' ? 'none' : undefined;
  return (
    <IconButton
      variant="unstyled"
      color="whiteAlpha.800"
      display={{ base: 'block', lg: display }}
      icon={<Menu size="1.5rem" />}
      aria-label="nav-toggle"
      onClick={(e) => displatch(setNavIsOpen(!ariaExpanded))}
    />
  );
};

/** Examples
 * <NavItem to="members" text="Members" icon={<UsersIcon />} />
 * <NavItem text="Test" icon={<UsersIcon />}>
      <NavItem to="test2" text="Test" icon={<UsersIcon />} />
      <NavItem to="members" text="Members" icon={<UsersIcon />} />
  </NavItem>
 *
*/
export const NavItem = (props: NavItemProps) => {
  let { pathname } = useLocation();
  const isRouteMatch = some(props?.children as any, (child) =>
    pathname.includes(child.props?.to)
  );

  const styles = useStyles();
  const { display, color, ...itemStyle } = styles?.item as ChakraStyledOptions;
  const isExpanded = display !== 'none';
  const bgColor = 'blackAlpha.100';
  return (
    <ListItem
      as={Accordion}
      allowMultiple
      index={isExpanded ? undefined : [-1]}
    >
      <AccordionItem border="none">
        <AccordionButton
          as={props?.to ? NavLink : Box}
          {...props}
          variant="unstyled"
          cursor="pointer"
          py={3}
          sx={{ '&.active': { color, bg: isExpanded ? undefined : bgColor } }}
          bg={isRouteMatch && !isExpanded ? bgColor : undefined}
          color={isRouteMatch ? color : undefined}
        >
          <HStack
            {...itemStyle}
            justifyContent={isExpanded ? 'left' : 'center'}
          >
            {props?.icon && props?.icon}
            {isExpanded && (
              <>
                <Text as="span" whiteSpace="nowrap" fontWeight="medium">
                  {props?.text}
                </Text>
                <Spacer />
              </>
            )}
            {props?.children && isExpanded && <AccordionIcon />}
          </HStack>
        </AccordionButton>
        {props?.children && (
          <AccordionPanel pb={0} children={props?.children} />
        )}
      </AccordionItem>
    </ListItem>
  );
};

const SideNav = ({ children }: { children?: React.ReactNode }) => {
  const displatch = useDispatch();
  const { checkPermission } = useAuthContext();
  const { isOpen: ariaExpanded, variant: navVariant } = useSelector(selectNav);

  const variant =
    useBreakpointValue(
      { base: 'temporary', lg: navVariant },
      { fallback: 'lg' }
    ) || navVariant;

  const styles = useMultiStyleConfig('SideNav', {
    variant,
    ariaExpanded,
  });

  const handleToggleVariant = () =>
    displatch(
      setNavVariant(variant === 'permanent' ? 'persistent' : 'permanent')
    );

  const getOnMouseHandler = (isOpen: boolean) => () =>
    variant == 'persistent' && displatch(setNavIsOpen(isOpen));

  const carrotIcon = {
    permanent: <ChevronLeft />,
    persistent: <ChevronRight />,
    temporary: undefined,
  }[variant];

  if (!checkPermission.isAuthorized) return null;
  return (
    <StylesProvider value={styles}>
      <MotionDiv
        onMouseOver={getOnMouseHandler(true)}
        onMouseLeave={getOnMouseHandler(false)}
        animate={{ width: styles?.root?.width as string }}
        __css={styles?.root}
      >
        <VStack as="nav" __css={styles?.nav}>
          <HStack
            w="full"
            justifyContent="space-between"
            display={variant !== 'temporary' ? 'none' : 'flex'}
          >
            <BranchLogo />
            <LocationSelectMenu />
          </HStack>
          <List __css={styles?.navlist} children={children} />
          <Spacer />
          <Box w="full" display={{ base: 'none', md: 'block' }}>
            <IconButton
              variant="unstyled"
              icon={carrotIcon}
              aria-label="Collapse Nav"
              onClick={handleToggleVariant}
            />
          </Box>
        </VStack>
        <NavOverlay />
      </MotionDiv>
    </StylesProvider>
  );
};

export default SideNav;
