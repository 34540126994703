/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import get from 'lodash/get';
import baseApi, {
  StrapiId,
  getListModel,
  ListResponse,
  PaginationRequest,
} from '.';
import { Branch } from './branch';
import { Group } from './groups';

export interface Appointment {
  id?: StrapiId;
  displayName: string;
  scheduledAt: string;
  groups: Group[];
  createdAt?: string;
  updatedAt?: string;
}

type AppointmentsResp = ListResponse<Appointment>;
type PutAppointmentsReq = Required<Pick<Appointment, 'id'>> &
  Partial<Appointment>;

type GetAppointmentsReq = [
  { groupId?: Group['id']; branchId: Branch['id'] },
  PaginationRequest?,
  Object?
];

const appointmentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppointments: builder.query<AppointmentsResp, GetAppointmentsReq>({
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.data.map((apt) => ({
                type: 'Appointments' as const,
                id: get(apt, 'id'),
              })),
              { type: 'Appointments', id: 'LIST' },
            ]
          : [{ type: 'Appointments', id: 'LIST' }];
      },
      query: ([req, pageMeta, filters = {}]) => {
        const query = {
          sort: ['id:desc'],
          populate: {
            groups: { fields: ['id', 'uid', 'name'] },
          },
          filters: {
            ...filters,
            $and: [
              {
                groups: {
                  uid: get(req, 'groupId'),
                  branch: { id: get(req, 'branchId') },
                },
              },
              ...get(filters, '$and', []),
            ],
          },
          pagination: get(pageMeta, 'page')
            ? pageMeta
            : { page: pageMeta, pageSize: 100 },
        };
        return `/appointments?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => ({
        data: getListModel(get(res, 'data', []), {
          groups: (_, groups) => getListModel(get(groups, 'data')),
        }),
        meta: get(res, 'meta', {}),
        original: get(res, 'data'),
      }),
    }),
    createAppointment: builder.mutation<any, Appointment>({
      invalidatesTags: [{ type: 'Appointments', id: 'LIST' }],
      query: (data) => ({
        url: '/appointments',
        method: 'POST',
        body: { data },
      }),
    }),
    updateAppointment: builder.mutation<any, PutAppointmentsReq>({
      query({ id, ...data }) {
        return {
          url: `/appointments/${id}`,
          method: 'PUT',
          body: { data },
        };
      },
      // Invalidates all queries that subscribe to Appointment ids only.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Appointments', id },
      ],
    }),
    bulkDeleteAppointments: builder.mutation<any, Appointment['id'][]>({
      query(ids) {
        return {
          url: `/appointments/bulk/delete`,
          method: 'POST',
          body: { data: { ids } },
        };
      },
      // Invalidates all queries that subscribe to Appointment ids only.
      invalidatesTags: (result, error, ids) =>
        ids.map((id) => ({ type: 'Appointments', id })),
    }),
  }),
});

export const {
  useGetAppointmentsQuery,
  useBulkDeleteAppointmentsMutation,
  useCreateAppointmentMutation,
  useUpdateAppointmentMutation,
} = appointmentsApi;

export default appointmentsApi;
