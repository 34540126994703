/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get, map, groupBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Add,
  DriveFileRenameOutline,
  DeleteOutline,
  Delete,
  Close,
  UploadFile,
  CreateNewFolder as NewFolder,
} from 'emotion-icons/material';
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ScaleFade,
  Spacer,
  useToast,
  VStack,
} from '@chakra-ui/react';

import Page, { PageHeading } from '../../../components/Page';
import {
  Asset,
  AssetsGrid,
  Folder,
  FoldersGrid,
  ViewCore,
  useAssetsGrid,
  useFolderGrid,
} from '../../../components/DataView';
import {
  MediaContentGroup,
  useBulkDeleteContentGroupsMutation,
  useBulkDeleteGroupMediaMutation,
  useGetMediaFilesQuery,
  useGetMediaGroupsQuery,
} from '../../../services/content-library';
import { useBranch } from '../../../hooks/branch';
import NewFolderForm from './NewFolderForm';
import RenameFolderForm from './RenameFolderForm';
import UploadAssetModal from '../UploadAssetDialog';
import EmptyMessage from '../EmptyMessage';

type ModalName =
  | 'openNewFolder'
  | 'openUploadFiles'
  | 'openRenameFolder'
  | 'closed';

export const useBulkDeleteMedia = <T extends Asset>(view: ViewCore<T>) => {
  const toast = useToast();
  const [bulkDeleteMedia, state] = useBulkDeleteGroupMediaMutation();

  /** handles bulk delete of media assets */
  const bulkDelete = async (assets?: Asset[]) => {
    const selectedAssets = assets || view.getSelectedData();
    if (!selectedAssets?.length) return;
    try {
      const assetGroups = groupBy(selectedAssets, 'groupId');
      for (const groupId in assetGroups) {
        await bulkDeleteMedia({
          id: groupId,
          assets: assetGroups[groupId] as Asset[],
        }).unwrap();
      }
      view.resetRowSelection?.();
    } catch (error) {
      toast({
        title: 'Error deleting files. Please try again',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return [bulkDelete, state] as const;
};

export const useBulkDeleteFolders = <T extends MediaContentGroup>(
  view: ViewCore<T>
) => {
  const toast = useToast();
  const [bulkDeleteFolders, state] = useBulkDeleteContentGroupsMutation();

  /** handles bulk delete of folders/groups */
  const bulkDelete = async (folders?: MediaContentGroup[]) => {
    const selectedFolders = folders || view.getSelectedData();
    if (!selectedFolders?.length) return;
    try {
      await bulkDeleteFolders(map(selectedFolders, 'id')).unwrap();
      view.resetRowSelection?.();
    } catch (error) {
      toast({
        title: 'Error deleting folders. Please try again',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return [bulkDelete, state] as const;
};

const MediaLibrary = () => {
  const branch = useBranch();
  const navigate = useNavigate();
  const { data: myFoldersData, isFetching: isFetchingFolder } =
    useGetMediaGroupsQuery(branch?.id);
  const { data: myAssetsData, isFetching: isFetchingFiles } =
    useGetMediaFilesQuery({
      branchId: branch?.id,
      grouped: false,
    });

  const myFolders = useFolderGrid({
    data: myFoldersData?.data,
    enableSelection: true,
  });
  const myAssets = useAssetsGrid({
    data: myAssetsData?.data,
    enableSelection: true,
  });

  const [editableFolder, setEditableFolder] = React.useState<Folder>();
  const [modalForms, setModalState] = React.useState<ModalName>('closed');

  const selectedCountSum =
    myAssets.getSelectedRowModel().rows.length +
    myFolders.getSelectedRowModel().rows.length;

  const handleClearSelected = () => {
    myAssets.resetRowSelection?.();
    myFolders.resetRowSelection?.();
  };

  const [bulkDeleteAssets] = useBulkDeleteMedia(myAssets);
  const [bulkDeleteFolders] = useBulkDeleteFolders(myFolders);

  // navigate to folderId onClick and set folder in the store
  const handleFolderClick = <T extends Folder>(folder: T) => {
    navigate(`/${branch?.slug}/content-manager/media/${folder.id}`, {
      state: folder,
    });
  };

  // close all modals and reset editable folder
  const handleCloseModal = () => {
    setModalState('closed');
    setEditableFolder(undefined);
  };

  const handleDeleteSelected = async () => {
    await Promise.all([bulkDeleteAssets(), bulkDeleteFolders()]);
  };

  const folderActions = [
    {
      label: 'Rename',
      icon: <DriveFileRenameOutline size={24} />,
      onClick: (folder) => {
        setEditableFolder(folder);
        setModalState('openRenameFolder');
      },
    },
    {
      label: 'Delete',
      icon: <DeleteOutline size={24} />,
      onClick: (folder) => {
        myAssets?.openConfirmDialog?.({
          title: 'Delete Folder',
          description: `Are you sure you want to delete folder ${folder.name}?`,
          type: 'delete',
          onSubmit: () => bulkDeleteFolders([folder]),
        });
      },
    },
  ];
  const fileActions = [
    {
      label: 'Delete',
      icon: <DeleteOutline size={24} />,
      onClick: (asset) => {
        myAssets?.openConfirmDialog?.({
          title: 'Delete Asset',
          description: `Are you sure you want to delete ${asset.name}?`,
          type: 'delete',
          onSubmit: () => bulkDeleteAssets([asset]),
        });
      },
    },
  ];

  return (
    <Page>
      <RenameFolderForm
        data={editableFolder}
        isOpen={modalForms === 'openRenameFolder'}
        onClose={handleCloseModal}
      />
      <UploadAssetModal
        data={editableFolder}
        isOpen={modalForms === 'openUploadFiles'}
        onClose={handleCloseModal}
      />
      <NewFolderForm
        isOpen={modalForms === 'openNewFolder'}
        onFolderCreated={(folder) => {
          setEditableFolder(folder);
          setModalState('openUploadFiles');
        }}
        onClose={handleCloseModal}
      />
      <HStack>
        <PageHeading>Media Library</PageHeading>
        <Spacer />
        <Menu>
          <MenuButton
            as={Button}
            size="md"
            colorScheme="blue"
            leftIcon={<Add size="24" />}
            children={'New'}
          />
          <MenuList>
            <MenuItem
              icon={<NewFolder size="24" />}
              children="New Folder"
              onClick={() => setModalState('openNewFolder')}
            />
            <MenuItem
              icon={<UploadFile size="24" />}
              children="Upload Files"
              onClick={() => setModalState('openUploadFiles')}
            />
          </MenuList>
        </Menu>
        <Button
          size="md"
          hidden={selectedCountSum === 0}
          leftIcon={<Delete size="24" />}
          colorScheme="blue"
          children={`Delete (${selectedCountSum})`}
          onClick={() => {
            myAssets?.openConfirmDialog?.({
              title: 'Delete Selected',
              description: `Are you sure you want to delete ${selectedCountSum} assets?`,
              type: 'delete',
              onSubmit: () => handleDeleteSelected(),
            });
          }}
        />
      </HStack>
      <Box mt={5}>
        <Button
          hidden={selectedCountSum === 0}
          leftIcon={<Close />}
          size="sm"
          children={`${selectedCountSum} Clear Selected`}
          onClick={handleClearSelected}
        />
        <VStack align="start" spacing={5} as={ScaleFade} initialScale={0.9} in>
          <EmptyMessage
            hidden={!(myAssets.getIsViewEmpty() && myFolders.getIsViewEmpty())}
          />
          <FoldersGrid
            isEditable
            hidden={myFolders.getIsViewEmpty()}
            actions={folderActions}
            view={myFolders}
            isLoading={isFetchingFolder}
            onClick={handleFolderClick}
          />
          <AssetsGrid
            isEditable
            hidden={myAssets.getIsViewEmpty()}
            actions={fileActions}
            view={myAssets}
            isLoading={isFetchingFiles}
          />
        </VStack>
      </Box>
    </Page>
  );
};

export default MediaLibrary;
