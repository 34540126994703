import { uniqueId, merge } from 'lodash';
import {
  UITemplateBlock,
  UITemplateSection,
} from '../../components/DisplayTemplate';

export const generateUIContentBlock = (props?: object & {}) =>
  ({
    blockUID: uniqueId('block_'),
    config: { style: { flexGrow: 1 } },
    source: 'none',
    ...props,
  } as UITemplateBlock);

export const generateUISectionBlock = (props?: any) => {
  const blanksection = {
    blockUID: uniqueId('section_'),
    name: 'New Section',
    properties: { source: 'none', config: { style: { flexGrow: 1 } } },
    blocks: props?.blocks || [generateUIContentBlock()],
  };
  return merge(blanksection, props) as Required<UITemplateSection>;
};

export const UI_DEFAULT_DISPLAY_TEMPLATE = {
  name: 'New Scratch Template',
  sections: [
    generateUISectionBlock({
      properties: { config: { style: { height: '10%' } } },
      blocks: [
        generateUIContentBlock({ config: { style: { width: '25%' } } }),
        generateUIContentBlock(),
      ],
    }),
    generateUISectionBlock({
      blocks: [generateUIContentBlock(), generateUIContentBlock()],
      properties: { config: { style: { height: '75%' } } },
    }),
    generateUISectionBlock({
      properties: { config: { style: { height: '15%' } } },
    }),
  ],
};
