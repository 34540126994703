/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { get, uniqueId } from 'lodash';
import {
  FormLabel,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  FormErrorMessage,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react';

import { useBranch } from '../../../hooks/branch';
import { useCreateContentGroupMutation } from '../../../services/content-library';

interface NewTextGroupFormProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const schema = yup
  .object({
    name: yup
      .string()
      .label('Text Group Name')
      .min(2, '${path} must be at least 2 characters')
      .max(50, '${path} must not exceed 50 characters')
      .required(),
    texts: yup
      .string()
      .label('Texts')
      .min(2, '${path} must be at least 2 characters')
      .required('${path} can not be empty'),
  })
  .required();

type TextGroupFormData = yup.InferType<typeof schema>;

export const NewTextGroupForm = ({
  isOpen = false,
  onClose = () => {},
}: NewTextGroupFormProps) => {
  const toast = useToast();
  const branch = useBranch();

  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<TextGroupFormData>({
    resolver: yupResolver(schema),
  });

  const handleOnClose = () => {
    onClose?.();
    resetForm();
  };

  const [createGroup, { isLoading }] = useCreateContentGroupMutation();
  const onSubmit = async (formData: TextGroupFormData) => {
    try {
      const texts = formData.texts
        .split('\n')
        .filter((line) => line.trim() !== '')
        .map((content) => ({ content }));
      await createGroup({
        name: formData.name,
        type: 'texts',
        owner: { branch },
        texts,
      }).unwrap();
      onClose?.();
      resetForm();
    } catch (error) {
      toast({
        title: `Error creating text group`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isCentered
      size="xl"
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>New Text Group</ModalHeader>
        <ModalBody as={VStack}>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel>Group name</FormLabel>
            <Input placeholder="Enter group name" {...register('name')} />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.texts}>
            <FormLabel>Texts (one line per value)</FormLabel>
            <Textarea
              resize="none"
              minH={60}
              placeholder={`Ex:\nmorning\nnoon\nevening`}
              {...register('texts')}
            />
            <FormErrorMessage>{errors.texts?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter as={HStack} mt={4}>
          <Button
            isDisabled={isLoading}
            onClick={onClose}
            children="Cancel"
            size="md"
            variant="ghost"
          />
          <Button
            isDisabled={isLoading}
            isLoading={isLoading}
            variant="ghost"
            children="Create"
            colorScheme="blue"
            size="md"
            type="submit"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewTextGroupForm;
