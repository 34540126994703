/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get, reject, isEqual } from 'lodash';
import { FileUpload, ArrowBack, Delete } from 'emotion-icons/material';
import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  useControllableState,
  Tabs,
  TabPanels,
  TabPanel,
  useToast,
  Spacer,
} from '@chakra-ui/react';
import { useBranch } from '../../../hooks/branch';
import { RawAsset } from '../../../services';
import { getAllowedFiles } from '../../../services/utils';
import {
  useUploadMediaContentMutation,
  useCreateContentGroupMutation,
} from '../../../services/content-library';
import {
  AssetsGrid,
  Folder,
  useAssetsGrid,
} from '../../../components/DataView';
import AddFileStep from './AddFileStep';
import FolderCard from '../../../components/DataView/FoldersGrid/FolderCard';

const Steps = {
  AddFiles: 0,
  PreviewAsset: 1,
};

export interface UploadAssetDialogProps {
  isOpen?: boolean;
  data?: Folder;
  onClose?: () => void;
}

export const UploadAssetModal = ({
  isOpen = false,
  onClose,
  data: folder,
}: UploadAssetDialogProps) => {
  const toast = useToast();
  const branch = useBranch();
  const [step, setStep] = React.useState(Steps.AddFiles);
  const [assets, setAssets] = React.useState<RawAsset[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadMedia] = useUploadMediaContentMutation();
  const [createContentGroup] = useCreateContentGroupMutation();

  const assetsGrid = useAssetsGrid({
    data: assets,
    enableSelection: true,
  });

  const fieldAllowedTypes = ['files', 'images', 'videos', 'audios'];
  const validateAssetsTypes = (
    assets: RawAsset[],
    callback: (assets: RawAsset[]) => void
  ) => {
    const allowedAssets = getAllowedFiles(fieldAllowedTypes, assets);
    if (allowedAssets.length > 0) callback(allowedAssets);
    else {
      toast({
        title: 'Error uploading assets',
        description: `Oops! Only ${fieldAllowedTypes.join(
          ', '
        )} are supported.`,
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleAddToPreviewAssets = (nextAssets: RawAsset[]) => {
    validateAssetsTypes(nextAssets, () => {
      setAssets((prevAssets) => prevAssets.concat(nextAssets));
      setStep(Steps.PreviewAsset);
    });
  };

  const handleOnRemoveAssets = (selected?: RawAsset[]) => {
    const selectedCards = selected || assetsGrid.getSelectedData();
    const newAssets = reject(assets, ({ id }) =>
      selectedCards.some(({ id: sId }) => isEqual(id, sId))
    );
    if (!newAssets.length) setStep(Steps.AddFiles);
    setAssets(newAssets);
    assetsGrid.resetRowSelection?.();
  };

  const handleOnClose = () => {
    setStep(Steps.AddFiles);
    setAssets([]);
    onClose?.();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const mediaGroup =
        folder ??
        (await createContentGroup({
          type: 'media',
          owner: { branch },
        }).unwrap());
      if (!mediaGroup?.id) throw new Error('Error missing group indetifier.');
      // upload assets to media group
      await uploadMedia({
        id: mediaGroup.id,
        assets,
      });

      handleOnClose();
      toast({
        title: `Files uploaded successfully.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error Uploading Assets.`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isCentered
      size="4xl"
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent as={Tabs} index={step}>
        {!!folder && <FolderCard isEditable={false} name={folder.name} />}
        <ModalHeader pb={0}>
          Add new assets
          {!!assets.length && (
            <Text fontSize="sm" fontWeight="semibold">
              {assets.length} {assets.length > 1 ? 'assets' : 'asset'} ready to
              upload
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody>
          <TabPanels>
            <TabPanel>
              <AddFileStep onAddFiles={handleAddToPreviewAssets} />
            </TabPanel>
            <TabPanel maxH="30rem" overflowY="scroll" overflow="auto">
              <AssetsGrid
                isEditable
                isFetching={isLoading}
                view={assetsGrid}
                actions={[
                  {
                    label: 'Delete',
                    icon: <Delete size={24} />,
                    onClick: (asset) =>
                      handleOnRemoveAssets([asset as RawAsset]),
                  },
                ]}
              />
            </TabPanel>
          </TabPanels>
        </ModalBody>
        <ModalFooter py={0}>
          <TabPanels>
            <TabPanel px={0}>
              <HStack hidden={!assets?.length}>
                <Button
                  variant="ghost"
                  isDisabled={isLoading}
                  onClick={handleOnClose}
                  children="Cancel"
                />
                <Spacer />
                <Button
                  isDisabled={isLoading || !assets.length}
                  isLoading={isLoading}
                  colorScheme="blue"
                  children="Preview Assets"
                  onClick={() => setStep(Steps.PreviewAsset)}
                />
              </HStack>
            </TabPanel>
            <TabPanel as={HStack} px={0}>
              <Button
                children="Add more"
                leftIcon={<ArrowBack />}
                isDisabled={isLoading}
                onClick={() => setStep(Steps.AddFiles)}
              />
              <Spacer />
              <Button
                hidden={!assetsGrid.getIsRowsSelected()}
                isDisabled={isLoading || !assets.length}
                colorScheme="red"
                leftIcon={<Delete size="24" />}
                onClick={() => handleOnRemoveAssets()}
              >
                {`${assetsGrid.getSelectedRowModel().rows.length} Remove`}
              </Button>
              <Button
                isDisabled={isLoading || !assets.length}
                isLoading={isLoading}
                colorScheme="blue"
                type="submit"
                children={`Upload Assets`}
                leftIcon={<FileUpload size="24" />}
                onClick={onSubmit}
              />
            </TabPanel>
          </TabPanels>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadAssetModal;
