/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get } from 'lodash';
import { Tooltip } from '@chakra-ui/react';
import { useBranch } from '../../hooks/branch';

const logoSrc = new URL('../../assets/logo.png', import.meta.url);
export const AppLogo = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img
    src={`${logoSrc.href}`}
    alt="My WelcomeTv Logo"
    loading="lazy"
    {...props}
  />
);

export type BranchLogoProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  large?: boolean;
};
export const BranchLogo = ({ large = false, ...props }: BranchLogoProps) => {
  const branch = useBranch();
  const logoUrl = get(branch, 'logo.formats.thumbnail.url');
  return (
    <Tooltip label={branch?.name}>
      <img
        src={logoUrl ? `${process.env.API_URL}${logoUrl}` : logoSrc.href}
        alt="Branch Logo"
        loading="lazy"
        width="40px"
        {...props}
      />
    </Tooltip>
  );
};
