import React from 'react';
import get from 'lodash/get';
import once from 'lodash/once';

import { useAnimationControls } from 'framer-motion';
import { ANIMATION_INTERVAL, BlockAppointmentsConfig } from '..';

export enum Animations {
  NONE = 'None',
  FADE = 'Fade',
  SCALE = 'Scale',
  SHAKE = 'Shake',
  SLIDE_RIGHT = 'SlideRight',
  SLIDE_DOWN = 'SlideDown',
}

const listVariants = {
  animate: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  initial: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const listItemVariants = {
  None: {
    animate: {
      opacity: 1,
    },
  },
  Fade: {
    animate: {
      opacity: [0, 0, 1],
    },
  },
  Shake: {
    animate: {
      opacity: 1,
      x: [0, -10, 10, -10, 10, 0],
    },
  },
  SlideDown: {
    animate: {
      y: [0, -50, 0],
      opacity: [0, 0, 1],
    },
  },
  SlideRight: {
    animate: {
      x: [0, -50, 0],
      opacity: [0, 0, 1],
      transition: {
        x: { velocity: -100 },
      },
    },
  },
};

/**
 * Animation Config
 * animation.play: boolean - default=true
 * animation.interval: number - default=5000 (ms)
 * animation.type: Animations - default=Animations.SLIDE_RIGHT
 */
export const useAnimationState = (config: BlockAppointmentsConfig) => {
  const controls = useAnimationControls();
  const animationOpts = get(config, 'animation');
  const variant = get(animationOpts, 'type', Animations.SLIDE_RIGHT);
  const isDisabled: boolean = get(animationOpts, 'isDisabled', false);
  const animationInterval: number = get(
    config,
    'animation.interval',
    ANIMATION_INTERVAL.Appointments
  );
  const animationRef = React.useRef<{
    isDisabled?: boolean;
    interval?: number;
    timeout?: NodeJS.Timeout;
  }>();

  /** init will start the animation sequence once */
  const init = React.useCallback(
    once(() => {
      controls.mount();
      controls.start('animate');
    }),
    []
  );

  const animate = React.useCallback(async () => {
    clearTimeout(animationRef.current?.timeout);
    if (animationRef.current?.isDisabled) return;
    await controls.start('animate');
    animationRef.current = {
      ...animationRef.current,
      timeout: setTimeout(animate, animationRef.current?.interval),
    };
  }, []);

  // /** this will run the animation on reload */
  React.useEffect(() => {
    animationRef.current = {
      ...animationRef.current,
      isDisabled,
      interval: animationInterval,
    };
    animate();
    return () => {
      clearTimeout(animationRef.current?.timeout);
      controls.stop();
      // this will prevent the animation from running on again
      if (animationRef.current) {
        animationRef.current.isDisabled = true;
      }
    };
  }, [isDisabled, animationInterval, variant]);

  return React.useMemo(
    () => ({
      isDisabled,
      listControls: controls,
      listItemVariant: get(listItemVariants, variant),
      listVariants,
      init,
    }),
    [config, isDisabled]
  );
};
