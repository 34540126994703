/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'root',
  'nav',
  'navlist',
  'item',
  'overlay',
]);

const maxWidth = 280;
export const SideNav = defineMultiStyleConfig({
  baseStyle: {
    root: {
      maxWidth,
      width: maxWidth,
      overflow: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 64px)',
      zIndex: 'docked',
    },
    nav: {
      h: 'full',
      p: 4,
      bg: 'body',
      borderRight: '1px solid #F2F4F7',
      zIndex: 'docked',
    },
    navlist: {
      w: 'full',
      spacing: 2,
    },
    item: {
      w: 'full',
      spacing: 3,
      alignContent: 'center',
      color: 'blue',
    },
    overlay: {
      bg: 'blackAlpha.600',
      pos: 'fixed',
      left: '0',
      top: '0',
      w: '100vw',
      h: '100vh',
    },
  },
  variants: {
    // They cannot be closed and is always visible, same elevation as other content
    permanent: { root: { position: 'relative' } },
    // toggle can be open and closed, rendered above other content as mini variant
    persistent: ({ ariaExpanded }) => ({
      ...(!ariaExpanded && {
        item: { display: 'none' },
        nav: { px: 0 },
        root: { width: 80 },
      }),
    }),
    // open above all other content.
    temporary: ({ ariaExpanded }) => ({
      nav: { position: 'relative' },
      navlist: { mt: 5 },
      root: {
        top: 0,
        height: '100vh',
        position: 'fixed',
        zIndex: 'modal',
        width: ariaExpanded ? maxWidth : 0,
      },
    }),
  },
  defaultProps: { variant: 'persistent' },
});
