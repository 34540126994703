/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { GroupAdd, Search } from 'emotion-icons/material';
import * as yup from 'yup';
import { get, find } from 'lodash';
import {
  Button,
  HStack,
  Text,
  Box,
  Avatar,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  CircularProgress,
  useDisclosure,
  FormLabel,
} from '@chakra-ui/react';

import authApi, { useLazyFindUserQuery } from '../../services/auth';
import { useBranch } from '../../hooks/branch';
import {
  useGetBranchUsersQuery,
  useUpdateBranchMutation,
} from '../../services/branch';

interface InviteUserFormProps {}

export const InviteUserForm = () => {
  const toast = useToast();
  const branch = useBranch();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  let searchTimeoutId = React.useRef<any>();
  const { data: branchUsers } = useGetBranchUsersQuery([branch?.id]);
  const [
    findUsers,
    { data: users, isFetching: isSearching },
    { lastArg: searchParams },
  ] = useLazyFindUserQuery();

  const [updateBranch, { isLoading: isUpdating }] = useUpdateBranchMutation();

  const onFindUser = (query: string) =>
    yup.string().email().isValidSync(query) &&
    !find(get(branchUsers, 'data', []), { email: query }) &&
    findUsers(query);

  const onCloseModal = () => {
    // manually reset the search query to empty to clear the results
    dispatch<any>(
      authApi.util.updateQueryData('findUser', searchParams, () => [])
    );
    onClose?.();
  };

  const onAddUser = async () => {
    const user = get(users, '0');
    try {
      if (!users?.length || !user) throw new Error('No user selected');
      await updateBranch({
        id: branch?.id,
        users: {
          disconnect: [],
          connect: [{ id: user.id, position: { end: true } }],
        },
      }).unwrap();

      onCloseModal();
      toast({
        title: `User Added ${user?.email}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error Adding User ${user?.email}`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const isLoading = isSearching || isUpdating;
  return (
    <>
      <Button
        variant="outline"
        size="md"
        leftIcon={<GroupAdd size="24" />}
        colorScheme="blue"
        onClick={onOpen}
        children="Invite"
      />
      <Modal
        isCentered
        size="xl"
        isOpen={isOpen}
        onClose={onCloseModal}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite User to {branch?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  {!isSearching && <Search size={24} />}
                  {isSearching && (
                    <CircularProgress isIndeterminate size="1.5rem" />
                  )}
                </InputLeftElement>
                <Input
                  type="email"
                  placeholder="Enter email..."
                  onChange={(e) => {
                    clearTimeout(searchTimeoutId.current);
                    searchTimeoutId.current = setTimeout(
                      () => onFindUser(e.target.value),
                      500
                    );
                  }}
                />
              </InputGroup>
            </FormControl>
            <Box overflowY="auto" mt={4}>
              {users?.map((user) => (
                <HStack key={user?.id} spacing="1rem" paddingY={2}>
                  <Avatar name={`${user?.firstName} ${user?.lastName}`} />
                  <Box>
                    <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
                    <HStack>
                      <Text fontSize="sm" color="neutral.700">
                        {user?.email}
                      </Text>
                    </HStack>
                  </Box>
                </HStack>
              ))}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={isLoading}
              variant="ghost"
              size="md"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            {!!users?.length && (
              <Button
                isLoading={isLoading}
                size="md"
                colorScheme="blue"
                mr={3}
                onClick={onAddUser}
              >
                Add User
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteUserForm;
