/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { get, map } from 'lodash';
import {
  PinInput,
  PinInputField,
  Button,
  HStack,
  VStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import {
  Display,
  useLinkDisplayMutation,
  useUpdateDisplayMutation,
} from '../../services/displays';
import { MultiSelect } from '../../components/ComboBox';
import { useBranch } from '../../hooks/branch';
import { useGetGroupsQuery } from '../../services/groups';

const schema = yup
  .object({
    id: yup.string(),
    displayName: yup
      .string()
      .label('Display Name')
      .matches(/^[a-zA-Z ]*$/, '${path} must contain only alphabets and spaces')
      .min(2, '${path} must be at least 2 characters')
      .max(50, '${path} must not exceed 50 characters')
      .required(),
    code: yup
      .string()
      .label('Link Code')
      .when('id', {
        is: (id) => !id,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional(),
      }),
    groups: yup
      .array()
      .label('Groups')
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string().required(),
          uid: yup.string().required(),
        })
      )
      .min(1)
      .required(),
  })
  .required();

type DisplayData = yup.InferType<typeof schema>;

interface EditDisplayFormProps {
  isOpen?: boolean;
  data?: Partial<Display>;
  onClose?: () => void;
}

const EditDisplayForm = ({
  data,
  isOpen = false,
  onClose,
}: EditDisplayFormProps) => {
  const formType = data?.id ? 'Edit' : 'Link';
  const toast = useToast();
  const branch = useBranch();
  const { data: groups } = useGetGroupsQuery([branch?.id]);

  const [updateDisplay, { isLoading: isUpdating }] = useUpdateDisplayMutation();
  const [linkDisplay, { isLoading: isLinking }] = useLinkDisplayMutation();
  const isLoading = isUpdating || isLinking;

  const {
    control,
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors, isValid, isDirty },
  } = useForm<DisplayData>({
    resolver: yupResolver<DisplayData>(schema),
    mode: 'onChange',
  });

  // reset the form when the data changes
  React.useEffect(() => {
    resetForm({
      id: data?.id,
      displayName: data?.device?.name,
      groups: data?.groups,
    } as DisplayData);
  }, [data]);

  const onSubmit = async (formData: DisplayData) => {
    const displayReq = data?.id ? updateDisplay : linkDisplay;
    const groups = map(formData.groups, 'id');
    const payload = !data?.id
      ? { ...formData, groups }
      : {
          id: data?.id,
          device: { id: data?.device?.id, name: formData.displayName },
          groups,
        };
    try {
      const res = await displayReq(payload as any);
      if (get(res, 'error')) throw new Error(`Couldn't ${formType} Display`);
      onCloseModal();
      toast({
        title: `${formType} Display`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `${formType} Display Error.`,
        description: error?.message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const onCloseModal = () => {
    resetForm({});
    onClose?.();
  };

  return (
    <Modal
      isCentered
      size="xl"
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{formType} Display</ModalHeader>
        <ModalBody>
          <VStack spacing={5}>
            <FormControl isInvalid hidden={isValid}>
              <FormErrorMessage mt={0}>{errors.code?.message}</FormErrorMessage>
              <FormErrorMessage mt={0}>
                {errors.displayName?.message}
              </FormErrorMessage>
              <FormErrorMessage mt={0}>
                {errors.groups?.message}
              </FormErrorMessage>
            </FormControl>
            <HStack w="full">
              <FormControl isRequired isInvalid={!!errors.displayName}>
                <FormLabel>Display Name</FormLabel>
                <Input {...register('displayName')} />
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!errors.code}
                hidden={!!data?.id}
              >
                <FormLabel>Link Code</FormLabel>
                <HStack>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <PinInput
                        type="alphanumeric"
                        value={field.value}
                        onChange={(v) => field.onChange(v?.toUpperCase())}
                        isInvalid={!!errors.code}
                      >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    )}
                  />
                </HStack>
              </FormControl>
            </HStack>
            <FormControl isRequired isInvalid={!!errors.groups}>
              <FormLabel>Select Groups</FormLabel>
              <Controller
                name="groups"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    labelKey="name"
                    data={get(groups, 'data', [])}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter as={HStack} mt={4}>
          <Button
            isDisabled={isLoading}
            variant="ghost"
            size="md"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            size="md"
            colorScheme="blue"
            mr={3}
            type="submit"
            isDisabled={!isDirty || !isValid}
          >
            {formType}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditDisplayForm;
