/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { useContext } from 'react';
import {
  concat,
  get,
  flatMap,
  reduce,
  map,
  groupBy,
  pick,
  isEqual,
} from 'lodash';
import { ModalProps } from '@chakra-ui/react';

import { AssetResponse } from '../../../../../services';
import { ContentSectionState } from '../../../slice';
import { useAppStore } from '../../../../../hooks/app';
import { useBranch } from '../../../../../hooks/branch';
import { useSelectionState } from '..';
import {
  MediaContentGroup,
  useGetMediaFilesQuery,
  useGetMediaGroupsQuery,
} from '../../../../../services/content-library';
import {
  ViewCore,
  useAssetsGrid,
  useFolderGrid,
} from '../../../../../components/DataView';
import { useGetDefaultBranchQuery } from '../../../../../services/branch';

export type FitlerAssetsFn = <T extends Pick<MediaContentGroup, 'id'>>(
  media: T[] | undefined
) => T[];

export type ModalStateProps = Partial<Pick<ModalProps, 'isOpen' | 'onClose'>>;
type MediaContextType = ModalStateProps & {
  tabIndex?: number;
  setTabIndex?: (index: number) => void;
  selectedFolder?: MediaContentGroup;
  setSelectedFolder?: (folder?: MediaContentGroup) => void;
  clearSelectedFolder?: () => void;
  filterSelectedAssets?: FitlerAssetsFn;
  branchId?: string | number;
  mwtvBranchId?: string | number;
  myFoldersView?: ViewCore<MediaContentGroup>;
  myAssetsView?: ViewCore<AssetResponse>;
  mwtvFoldersView?: ViewCore<MediaContentGroup>;
  mwtvAssetsView?: ViewCore<AssetResponse>;
  selectedFolderView?: ViewCore<AssetResponse>;
  selectedContent?: ContentSectionState;
  onSelectionChange?: (value: Partial<ContentSectionState>) => void;
  isFetching?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  getSelectedContent?: ReturnType<typeof useContentCallback>;
};

export const MediaContext = React.createContext<MediaContextType>({});
export const useMediaContext = () =>
  useContext(MediaContext) as Required<MediaContextType>;

/** helper hook to get selected media from the store directly
 * maybe we can save the content when the user clicks but this
 * way we always have the latest content from cache
 */
const useContentCallback = (
  selection: ContentSectionState,
  filterMediaFn?: FitlerAssetsFn
) => {
  const store = useAppStore();
  /** TODO: This logic could be implemented in the backend instead
   * right now we are getting all the media files and folders from redux cache.
   */
  return React.useCallback(() => {
    const state = store.getState();
    const queries = groupBy(
      pick(
        state.api.queries,
        get(state, 'api.provided.ContentLibraryMedia.LIST', [])
      ),
      'endpointName'
    );
    const content_filters: { [x: string]: { id: string | number }[] } = {};
    const addToFilters = (id: string, media?: { id: string | number }[]) => {
      (content_filters[id] || (content_filters[id] = [])).push(
        ...map(media, ({ id }) => ({ id }))
      );
    };
    // create a map of all folders on cache that are selected
    const folders = reduce(
      flatMap(queries?.getMediaGroups, 'data.data'),
      (res, folder) => {
        if (!selection.folders[folder.id]) return res;
        const selected = filterMediaFn?.(folder?.media);
        const media = selected?.length ? selected : folder?.media;
        res[folder.id] = { ...folder, media };
        // we push the selected assets to the content_filters
        if (selected?.length && !isEqual(selected, folder?.media)) {
          addToFilters(folder.id, selected);
        }
        return res;
      },
      {}
    );
    // create the content array based on the selected assets and folders
    const content = Object.values({
      ...folders,
      // convert all the media files on chache and selected to file groups
      ...reduce(
        groupBy(flatMap(queries?.getMediaFiles, 'data.data'), 'groupId'),
        (res, assets, key) => {
          // if the folder was already selected we skip i
          if (folders[key] || !!get(assets, '0.groupName')) return res;
          const media = filterMediaFn?.(assets);

          if (!media?.length) return res;
          else if (media?.length !== assets?.length) addToFilters(key, media); // add single assets to content_filters

          res[key] = {
            id: Number(key),
            group: null,
            type: 'media',
            media: media,
          };
          return res;
        },
        {}
      ),
    });
    return { content, content_filters };
  }, [selection, store, filterMediaFn]);
};

const useMWTVMediaData = (fetch?: boolean) => {
  const { data: mwtvBranch, isFetching: isFetchingBranch } =
    useGetDefaultBranchQuery(['id'], { skip: !fetch });

  const { data: mwtvFoldersData, isFetching: isFetchingFolder } =
    useGetMediaGroupsQuery(mwtvBranch?.id, {
      skip: !fetch || !mwtvBranch?.id,
    });
  const { data: mwtvAssetsData, isFetching: isFetchingFiles } =
    useGetMediaFilesQuery(
      {
        branchId: mwtvBranch?.id,
        grouped: false,
      },
      { skip: !fetch || !mwtvBranch?.id }
    );

  const [selection, onSelectionChange] = useSelectionState();
  const mwtvFoldersView = useFolderGrid({
    data: mwtvFoldersData?.data,
    enableSelection: true,
    rowSelection: selection.folders,
    onRowSelectionChange: (folders) => onSelectionChange({ folders }),
  });
  const mwtvAssetsView = useAssetsGrid({
    data: mwtvAssetsData?.data,
    enableSelection: true,
    rowSelection: selection.assets,
    onRowSelectionChange: (assets) => onSelectionChange({ assets }),
  });

  return {
    mwtvBranchId: mwtvBranch?.id,
    mwtvFoldersView,
    mwtvAssetsView,
    mwtvBranch,
    isFetching: isFetchingBranch || isFetchingFolder || isFetchingFiles,
  };
};

export const useMediaData = (fetch?: boolean) => {
  const branch = useBranch();
  const mwtvMedia = useMWTVMediaData(fetch);

  const [selectedFolder, setSelectedFolder] =
    React.useState<MediaContentGroup>();
  const { data: myFoldersData, isFetching: isFetchingFolder } =
    useGetMediaGroupsQuery(branch?.id, { skip: !fetch });
  const { data: myAssetsData, isFetching: isFetchingFiles } =
    useGetMediaFilesQuery(
      { branchId: branch?.id, grouped: false },
      { skip: !fetch }
    );
  const { data: selectedGroupData, isFetching: isFetchingFolderFiles } =
    useGetMediaFilesQuery(
      { groupId: selectedFolder?.id },
      { skip: !fetch || !selectedFolder?.id }
    );

  const [selection, onSelectionChange] = useSelectionState();
  const myFoldersView = useFolderGrid({
    data: myFoldersData?.data,
    enableSelection: true,
    rowSelection: selection.folders,
    onRowSelectionChange: (folders) => onSelectionChange({ folders }),
  });
  const myAssetsView = useAssetsGrid({
    data: myAssetsData?.data,
    enableSelection: true,
    rowSelection: selection.assets,
    onRowSelectionChange: (assets) => onSelectionChange({ assets }),
  });

  const selectedFolderView = useAssetsGrid({
    data: selectedGroupData?.data,
    enableSelection: true,
    rowSelection: selection.assets,
    onRowSelectionChange: (assets) => onSelectionChange({ assets }),
  });

  const clearSelectedFolder = React.useCallback(
    () => setSelectedFolder(undefined),
    [selectedFolder, selection.folders]
  );
  const filterSelectedAssets = React.useCallback<FitlerAssetsFn>(
    (media) => media?.filter(({ id }) => !!selection.assets?.[id]) || [],
    [selection.assets]
  );
  const getSelectedContent = useContentCallback(
    selection,
    filterSelectedAssets
  );

  return {
    ...mwtvMedia,
    branchId: branch?.id,
    myFoldersView,
    myAssetsView,
    selectedFolderView,
    selectedFolder,
    setSelectedFolder,
    selectedContent: selection,
    onSelectionChange,
    clearSelectedFolder,
    filterSelectedAssets,
    getSelectedContent,
    isFetching:
      isFetchingFolder ||
      isFetchingFiles ||
      isFetchingFolderFiles ||
      mwtvMedia.isFetching,
  };
};

export const useSelectedMedia = () => {
  const {
    mwtvAssetsView,
    mwtvFoldersView,
    myAssetsView,
    myFoldersView,
    getSelectedContent,
  } = useMediaContext();

  const [selection] = useSelectionState();

  /** NOTE:
   * Using the getSelectedContent is the only way to get the assets selected inside the folders
   * this is a little expensive but it works for now. We can improve this later.
   */
  const mySelectedMedia = React.useMemo(() => {
    const { content } = getSelectedContent();
    const groups = map(myFoldersView.getSelectedRowModel().rows, 'id');
    return concat(
      // individual assets
      map(myAssetsView.getSelectedRowModel().rows, 'original'),
      // assets inside folders
      flatMap(
        content?.filter((c: any) => groups.includes(c?.id)),
        'media'
      )
    );
  }, [selection]);

  const mwtvSelectedMedia = React.useMemo(() => {
    const { content } = getSelectedContent();
    const groups = map(mwtvFoldersView.getSelectedRowModel().rows, 'id');
    return concat(
      // individual assets
      map(mwtvAssetsView.getSelectedRowModel().rows, 'original'),
      // assets inside folders
      flatMap(
        content?.filter((c: any) => groups.includes(c?.id)),
        'media'
      )
    );
  }, [selection]);

  return {
    mySelectedMedia,
    mwtvSelectedMedia,
  };
};
