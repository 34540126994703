/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Flex, FlexProps, StyleProps } from '@chakra-ui/react';
import { UITemplateBlock, UITemplateBlockType } from '..';
import {
  BlockContentAppointments,
  BlockContentMedia,
  BlockContentText,
  BlockContentCustom,
} from './BlockContent';

export type BlockSource = NonNullable<UITemplateBlock['source']>;
export interface TemplateBlockProps extends FlexProps {
  type?: UITemplateBlockType;
  children?: React.ReactNode;
  block?: UITemplateBlock;
  label?: string;
}

const HTML_TYPE_MAP: Record<UITemplateBlockType, string> = {
  content: 'div',
  section: 'section',
  template: 'article',
};
const BLOCK_DEFAULT_STYLE: Partial<Record<BlockSource, StyleProps>> = {
  appointments: {
    justifyContent: 'start',
  },
};

const TemplateBlock = ({
  label,
  block,
  children,
  type,
  justify,
  onClick,
  _hover,
  ...props
}: TemplateBlockProps) => {
  const isType = (types?: (typeof type)[]) => type && types?.includes(type);

  const blockStyle = {
    color: 'white',
    ...props,
    //  if the block type is section, we need to make the backgroud absolute
    ...(isType(['section']) && {
      pos: 'absolute',
      zIndex: -1,
    }),
  };

  /** Stop progation to children object */
  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <Flex
      {...props}
      {...(block?.source && BLOCK_DEFAULT_STYLE[block?.source])}
      as={HTML_TYPE_MAP[type as string]}
      direction={type === 'section' ? 'row' : 'column'}
      aria-label={label}
      data-block-type={type}
      onClick={handleOnClick}
      justify={justify}
      _hover={_hover}
      position={isType(['content']) ? 'relative' : undefined}
      sx={{ ...props.sx, ...block?.config?.style }}
    >
      {children}
      {block?.source === 'texts' && type === 'content' && (
        <BlockContentText block={block} sx={blockStyle} />
      )}
      {block?.source == 'media' && (
        <BlockContentMedia
          block={block}
          sx={blockStyle}
          isAnimationDisabled={isType(['template', 'section'])}
        />
      )}
      {block?.source == 'appointments' && (
        <BlockContentAppointments block={block} sx={blockStyle} />
      )}
      <BlockContentCustom block={block} type={type} />
    </Flex>
  );
};

export default TemplateBlock;
