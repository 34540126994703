/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Add, MoreVert } from 'emotion-icons/material';
import { get, lowerCase, snakeCase, startCase } from 'lodash';
import {
  Button,
  HStack,
  Tag,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  Circle,
  VStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import Page, { PageContainer, PageHeading } from '../../components/Page';
import {
  Display,
  useDeleteDisplayMutation,
  useGetDisplaysQuery,
} from '../../services/displays';
import { useBranch } from '../../hooks/branch';
import DataTable from '../../components/DataTable';
import { DateTime } from 'luxon';
import LinkEditDisplayForm from './LinkEditForm';
import { useSearchParams } from 'react-router-dom';

const iconLaptop = new URL(`../../assets/icons/laptop.png`, import.meta.url),
  iconSmartTv = new URL(`../../assets/icons/smart-tv.png`, import.meta.url),
  iconTvWithSmartUsb = new URL(
    `../../assets/icons/tv-with-smart-usb.svg`,
    import.meta.url
  );

const getDeviceIconFile = (type: string) => {
  const cleantype = lowerCase(type).replace(/\s/g, '');
  switch (snakeCase(cleantype)) {
    case 'desktop':
    case 'pc':
    case 'laptop':
      return iconLaptop;
    case 'tv':
    case 'smarttv':
      return iconSmartTv;
    case 'usb':
    case 'mobile':
    case 'tablet':
    case 'usbstick':
    case 'tvstick':
    case 'tvbox':
      return iconTvWithSmartUsb;
    default:
      return iconSmartTv;
  }
};

const Displays = () => {
  const branch = useBranch();
  const [searchValue, setSearchValue] = React.useState('');
  const [pageSize, onPageSizeChange] = React.useState(10);
  const [page, onPageChange] = React.useState(1);
  const [editableRow, setEditableRow] = React.useState<Partial<Display>>();
  let [searchParams, setSearchParams] = useSearchParams();

  const [deleteDisplay, { isLoading: isDeleting }] = useDeleteDisplayMutation();

  const {
    data: displays,
    isLoading,
    isFetching,
  } = useGetDisplaysQuery([
    branch?.id,
    { page, pageSize },
    {
      $or: [
        { device: { name: { $contains: searchValue } } },
        { groups: { name: { $contains: searchValue } } },
      ],
    },
  ]);

  // open the add display modal when link param is present
  React.useEffect(() => {
    if (searchParams.get('link')) {
      searchParams.delete('link');
      setSearchParams(searchParams);
      setEditableRow({});
    }
  }, [searchParams]);

  const columns = React.useMemo(
    () => [
      {
        header: 'Display',
        accessorKey: 'id',
        cell: (info) => {
          const display: Display = get(info, 'row.original');
          const lastUsed = DateTime.fromISO(display?.lastUsed);
          const isActive = lastUsed.diffNow().as('hours') > -8;
          const { href: deviceTypeSrc } = getDeviceIconFile(display?.category);
          return (
            <HStack spacing="1rem" w="15rem">
              <HStack>
                <Image src={deviceTypeSrc} alt={display?.category} />
                <Tooltip label={isActive ? 'Online' : 'Offline'}>
                  <Circle
                    bg={isActive ? 'green.400' : 'gray.400'}
                    alignSelf="start"
                    size="0.7rem"
                    position="relative"
                    left="-1rem"
                    top="-2px"
                  />
                </Tooltip>
              </HStack>
              <VStack key={info.id} align="start" spacing={'2px'}>
                <Text fontWeight="semibold">{display?.device?.name}</Text>
                <Text fontWeight="medium" fontSize="0.9rem">
                  {display?.screen?.aspectRatio}
                  {' | '}
                  {`${display?.screen?.height}x${display?.screen?.width}`} px
                </Text>
              </VStack>
            </HStack>
          );
        },
      },
      {
        accessorKey: 'category',
        cell: (info) => {
          const display: Display = get(info, 'row.original');
          return (
            <VStack w="15rem" align="start" spacing="0.1rem">
              <Text>{startCase(display?.category)}</Text>
              <Text fontWeight="normal" fontSize="sm">
                {display?.device?.name}
              </Text>
            </VStack>
          );
        },
      },
      {
        accessorKey: 'groups',
        cell: (info) => {
          const groups = info.getValue();
          return (
            <HStack spacing={1}>
              {groups?.map((group, indx) => (
                <Tag key={group?.id || indx} children={group?.name} />
              ))}
            </HStack>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'lastUsed',
        cell: (info) => {
          const { ...display }: Display = get(info, 'row.original');
          const lastUsed = DateTime.fromISO(display?.lastUsed).toRelative();
          const city = get(display, 'device.ipLocation.city', 'Unknown');
          const country = get(display, 'device.ipLocation.country', 'Unknown');
          return (
            <VStack align="start" spacing="0.1rem">
              <Text>
                {city}, {country}
              </Text>
              <Text fontWeight="normal" fontSize="sm">
                Last seen: {lastUsed ?? 'Never'}
              </Text>
            </VStack>
          );
        },
      },
      {
        id: 'actions',
        cell: (info) => {
          const { openConfirmDialog } = info.table?.options;
          return (
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                aria-label="row-actions"
                variant="ghost"
                icon={<MoreVert size={24} />}
              />
              <MenuList>
                <MenuItem
                  onClick={() => setEditableRow(info?.row.original)}
                  children="Edit"
                />
                <MenuItem
                  onClick={() =>
                    openConfirmDialog?.({
                      type: 'delete',
                      labels: { submit: 'Delete' },
                      title: `Delete Display ${get(
                        info,
                        'row.original.device.name'
                      )}`,
                      description:
                        'Are you sure you want to delete this display?',
                      onSubmit: () =>
                        deleteDisplay(get(info, 'row.original.id')),
                    })
                  }
                  children="Delete"
                />
              </MenuList>
            </Menu>
          );
        },
      },
    ],
    []
  );

  return (
    <Page>
      <LinkEditDisplayForm
        isOpen={!!editableRow}
        data={editableRow}
        onClose={() => setEditableRow(undefined)}
      />
      <HStack>
        <PageHeading>Displays</PageHeading>
        <Spacer />
        <Button
          size="md"
          leftIcon={<Add size="24" />}
          colorScheme="blue"
          onClick={() => setEditableRow({})}
        >
          Add
        </Button>
      </HStack>
      <PageContainer>
        <DataTable
          isLoading={isLoading}
          inProgress={isFetching}
          columns={columns}
          data={displays?.data}
          header={{
            onSearchChange: setSearchValue,
          }}
          pagination={{
            pageSize,
            onPageSizeChange,
            page: page,
            onPageChange,
            pageCount: get(displays, 'meta.pagination.pageCount', 1),
          }}
          // isLoading={isLoadingData}
          // inProgress={isFetchingUsers}
          // columns={columns}
          // data={users?.data}
        />
      </PageContainer>
    </Page>
  );
};

export * from './NavLinkDisplay';
export default Displays;
