/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { useStore } from 'react-redux';
import { RootState } from '../store';

// helper hook to Infer the `RootState` types from the store itself
export const useAppStore = () => useStore<RootState>();
