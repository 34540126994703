/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Center,
  Heading,
  Link,
  Container,
  Input,
  Box,
  Button,
  Alert,
  AlertIcon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  BoxProps,
  Text,
  VStack,
} from '@chakra-ui/react';

import { AppLogo } from '../../components/Nav/logo';
import { useLoginMutation } from '../../services/auth';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/auth';

const schema = yup
  .object({
    identifier: yup
      .string()
      .email('Enter a valid email address')
      .required("Email can't be empty"),
    password: yup
      .string()
      .required("Password can't be empty")
      .min(5, 'Invalid password, please try again.'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const Copyright = (props: BoxProps) => (
  <Text color="inherit" align="center" {...props}>
    {'Copyright © '}
    <Link href="https://mywelcometv.com/" isExternal>
      Innovative Internet Solutions, Inc.
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Text>
);

export default () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema) });
  const [loginRequest, { isError, isLoading }] = useLoginMutation();

  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) return <Navigate to="/appointments" replace />;
  return (
    <Center h="100vh">
      <Container as="main" maxW="md">
        <VStack spacing={8}>
          <Box mt={3} mb={2}>
            <AppLogo width={150} />
          </Box>
          <Heading as="h1" fontWeight="medium" size="xl">
            Log Into MyWelcomeTv
          </Heading>
          <Box
            as="form"
            onSubmit={handleSubmit(loginRequest)}
            noValidate
            w="full"
            mt={1}
          >
            <VStack spacing={5}>
              {isError && (
                <Alert status="error">
                  <AlertIcon />
                  Invalid Email or password
                </Alert>
              )}
              <FormControl isRequired isInvalid={!!errors.identifier}>
                <FormLabel fontSize="xl">Email Address</FormLabel>
                <Input
                  {...register('identifier')}
                  type="email"
                  size="lg"
                  id="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                />
                <FormErrorMessage>
                  {errors.identifier?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.password}>
                <FormLabel fontSize="xl">Password</FormLabel>
                <Input
                  size="lg"
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  autoComplete="current-password"
                  {...register('password')}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
            <Box float="right" my={4}>
              <Link as={RouterLink} to="/forgot-password">
                Forgot password?
              </Link>
            </Box>
            <Button
              type="submit"
              size="lg"
              colorScheme="blue"
              isLoading={isLoading}
              w="full"
              my={4}
            >
              Sign In
            </Button>
          </Box>
        </VStack>
        <Copyright mt={8} mb={4} />
      </Container>
    </Center>
  );
};
