/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import get from 'lodash/get';
import { Add } from 'emotion-icons/material';
import {
  Button,
  HStack,
  VStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormHelperText,
  useDisclosure,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  useCreateGroupMutation,
  useGetGroupsQuery,
} from '../../services/groups';
import { useBranch } from '../../hooks/branch';

interface AddGroupFormProps {}

export const groupSchema = yup.object({
  groupName: yup
    .string()
    .label('Group Name')
    .test('unique', '${path} has been already taken.', (value, ctx) => {
      if (value?.trim() === ctx?.options?.context?.groupName) return true;
      return !~ctx?.options?.context?.groups?.findIndex((g) => {
        return g?.name?.toLowerCase() === value?.toLowerCase();
      });
    })
    .required(),
});

export type GroupFormData = yup.InferType<typeof groupSchema>;

export function createGroupId(str: string, size: number = 3) {
  const id = str
    ?.replace(/[^a-zA-Z]/g, '') // remove any non-letter characters
    ?.substring(0, size)
    ?.toUpperCase();
  return id;
}

const AddGroupForm = (props: AddGroupFormProps) => {
  const toast = useToast();
  const branch = useBranch();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { data: groups } = useGetGroupsQuery([branch?.id]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<GroupFormData>({
    resolver: yupResolver(groupSchema),
    context: { groups: groups?.data },
    defaultValues: { groupName: '' },
  });

  const [createGroup, { isLoading: isCreatingGroup }] =
    useCreateGroupMutation();

  const onSubmit = async (aptData: GroupFormData) => {
    let guid: string = createGroupId(aptData.groupName);
    while (groups?.data?.find((g) => g.uid === guid)) {
      guid = createGroupId(aptData.groupName, guid.length + 1);
    }
    try {
      await createGroup({
        name: aptData.groupName,
        uid: guid?.trim()?.toUpperCase(),
        branchId: branch?.id,
      }).unwrap();
      onClose();
    } catch (error) {
      toast({
        title: 'Error creating group. Please try again or contact support',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        size="sm"
        leftIcon={<Add size="24" />}
        colorScheme="blue"
        onClick={onToggle}
        children="Add Group"
      />
      <Modal
        size="xl"
        isOpen={isOpen}
        closeOnOverlayClick={!isCreatingGroup}
        onClose={() => {
          if (isCreatingGroup) return;
          onToggle();
        }}
      >
        <ModalOverlay />
        <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <ModalCloseButton />
          <ModalHeader>Create new group</ModalHeader>
          <ModalBody>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.groupName}>
                <FormLabel>Group Name</FormLabel>
                <Controller
                  name="groupName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value?.toUpperCase());
                      }}
                    />
                  )}
                />
                <FormHelperText>
                  Groups help you organize your appointments and displays.
                </FormHelperText>
                <FormErrorMessage>{errors.groupName?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter as={HStack} mt={4}>
            <Button
              isLoading={isCreatingGroup}
              size="md"
              colorScheme="blue"
              mr={3}
              type="submit"
              children="Create"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddGroupForm;
