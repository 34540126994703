/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { Suspense } from 'react';
import { get, map, groupBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Add,
  DriveFileRenameOutline,
  DeleteOutline,
  Delete,
  Close,
  UploadFile,
  HideImage,
  Warning,
} from 'emotion-icons/material';
import { Button, VStack, Box, Center, Text, Progress } from '@chakra-ui/react';
import { EmptyDataVector } from '../../components/Icons';

export interface EmptyMessageProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  action?: { label: string; onClick: () => void };
  hidden?: boolean;
}

export const EmptyMessage = ({
  icon,
  description = 'No assets here yet.',
  action,
  hidden,
}: EmptyMessageProps) => {
  if (hidden) return null;
  return (
    <Box w="full" p={6} bg="gray.50">
      <Center>
        <VStack spacing={4}>
          <Suspense>
            <EmptyDataVector />
          </Suspense>
          {/* <HideImage size={42} color="var(--chakra-colors-gray-400)" /> */}
          {/* {title && <Text fontSize="2xl">{title}</Text>} */}
          <Text>{description}</Text>
          {action && (
            <Button colorScheme="blue" onClick={action.onClick}>
              {action.label}
            </Button>
          )}
        </VStack>
      </Center>
    </Box>
  );
};

export default EmptyMessage;
