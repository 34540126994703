/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMatch } from 'lodash';
import {
  IconButton,
  Spacer,
  VStack,
  Circle,
  Box,
  Flex,
  TabList,
  TabPanels,
  TabPanel,
  Center,
} from '@chakra-ui/react';
import { Add } from 'emotion-icons/material';
import { selectNav, setNavIsOpen, setNavVariant } from '../../app/slice';
import {
  useGetDefaultTemplateQuery,
  useGetDisplayTemplateQuery,
} from '../../services/display-templates';
import {
  EDITOR_MODE,
  selectEditableTemplate,
  selectEditorMode,
  selectTemplateBlock,
  setEditorMode,
  templateSelectedBlockId,
} from './slice';
import {
  EditorHeader,
  TabsWrapper,
  TemplatePreview,
  TemplateWrapper,
} from './components';
import DisplayTemplate, {
  UITemplateBlockProps,
} from '../../components/DisplayTemplate';
import DesignControls from './Design';
import LayoutControls from './Layout';

const SELECTED_BLOCK_STYLE = {
  boxShadow: 'inset 0 0 0 4px  var(--chakra-colors-blue-400)',
};
const NEW_BLOCK_STYLE = {
  _before: {
    content: "''",
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    border: '2px dashed #000',
    zIndex: -1,
  },
};

const BlockSizeControls = (props: UITemplateBlockProps) => {
  const { block, blockType } = props;
  const dispatch = useDispatch();
  const selectedBlockId = useSelector(templateSelectedBlockId);
  const isSelected = React.useMemo(
    () => !!block && !!selectedBlockId && isMatch(block, selectedBlockId),
    [selectedBlockId, block]
  );
  const isNewContent =
    (!block?.source || block?.source === 'none') && blockType === 'content';
  return (
    <Box
      aria-selected={isSelected}
      {...(isNewContent && NEW_BLOCK_STYLE)}
      {...(isSelected && SELECTED_BLOCK_STYLE)}
    >
      {isSelected && (
        <>
          <VStack pos="absolute" py={2} h="full" left="8px">
            <Circle size={2} bg="white" />
            <Spacer />
            <Circle size={2} bg="white" />
          </VStack>
          <VStack pos="absolute" py={2} h="full" left="calc(100% - 1rem)">
            <Circle size={2} bg="white" />
            <Spacer />
            <Circle size={2} bg="white" />
          </VStack>
        </>
      )}
      {isNewContent && (
        <Center h="full">
          <IconButton
            aria-label="add-content"
            size="lg"
            borderRadius="full"
            boxShadow="md"
            _hover={{ bg: 'blue.50', color: 'blue.500' }}
            icon={<Add size={24} />}
            onClick={() => {
              dispatch(selectTemplateBlock(block));
            }}
          />
        </Center>
      )}
    </Box>
  );
};

const EditorControls = () => {
  const dispatch = useDispatch();
  const editorMode = useSelector(selectEditorMode);
  const handleTabsChange = (index) => dispatch(setEditorMode(index));

  return (
    <TabsWrapper index={editorMode} isManual id="tabs" hideBelow="lg" isLazy>
      <EditorHeader>
        {editorMode === EDITOR_MODE.LAYOUT && 'Layout'}
        {editorMode === EDITOR_MODE.EDIT && 'Design'}
      </EditorHeader>
      <TabPanels>
        <TabPanel p={0}>
          <LayoutControls />
        </TabPanel>
        <TabPanel p={0}>
          <DesignControls />
        </TabPanel>
        <TabPanel p={0}>
          <DesignControls />
        </TabPanel>
      </TabPanels>
    </TabsWrapper>
  );
};

const TemplateEditor = () => {
  const dispatch = useDispatch();
  const { variant: navVariant } = useSelector(selectNav);

  const editableTemplate = useSelector(selectEditableTemplate);
  const { isLoading: isLoadingDefaults } = useGetDefaultTemplateQuery();
  const { isFetching: isFetchingTemplate } = useGetDisplayTemplateQuery(
    editableTemplate?.id as string,
    {
      skip: !editableTemplate?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {
    // Code here runs after the component is mounted.
    dispatch(setNavIsOpen(false));
    dispatch(setNavVariant('temporary'));
    return () => {
      // set the nav mode back to what it was before, we hide it to make space for the editor
      const lastMode = navVariant === 'temporary' ? 'permanent' : navVariant;
      dispatch(setNavVariant(lastMode));
    };
  }, []);

  return (
    <Flex bg="blackAlpha.50" pos="fixed" boxSize="full">
      <TemplateWrapper
        onClick={() => {
          dispatch(selectTemplateBlock(undefined));
          dispatch(setEditorMode(EDITOR_MODE.LAYOUT));
        }}
      >
        <TemplatePreview>
          <DisplayTemplate
            isLoading={isLoadingDefaults || isFetchingTemplate}
            template={editableTemplate}
            isLive={false}
            blockOverlay={BlockSizeControls}
            options={{
              section: (section) => ({
                ...(!section?.blocks?.length && {
                  w: 'full',
                }),
                onClick: (e) => {
                  e.stopPropagation();
                  dispatch(selectTemplateBlock(section));
                },
              }),
              content: (block) => {
                return {
                  ...(!block?.source && {
                    minH: 20,
                    bg: 'blackAlpha.100',
                  }),
                  _hover: SELECTED_BLOCK_STYLE,
                  onClick: (e) => {
                    e.stopPropagation();
                    dispatch(selectTemplateBlock(block));
                  },
                };
              },
            }}
          />
        </TemplatePreview>
      </TemplateWrapper>
      <EditorControls />
    </Flex>
  );
};

export default TemplateEditor;
