/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get, slice, flatMap } from 'lodash';
import {
  Box,
  Text,
  useInterval,
  SystemStyleObject,
  HStack,
  useBreakpointValue,
  SlideFade,
  ScaleFade,
} from '@chakra-ui/react';
import { Reorder } from 'framer-motion';

import { ANIMATION_INTERVAL, useAutoPaginate, useComputedPixelValue } from '.';
import { TemplateBlockText } from '../../../../services/display-templates';
import { UIContentBlock } from '.';
import { TextAsset } from '../../../../services';
import TextFit from '../../../TextFit';

const ANIMATIONS = {
  Fade: SlideFade,
  None: Box,
  Scale: ScaleFade,
  Slide: Box,
};

type FontStyles = Pick<
  React.CSSProperties,
  'fontSize' | 'fontStyle' | 'textDecoration' | 'fontWeight'
>;
type ResponsiveFont = {
  spacing?: React.CSSProperties['margin'];
  fontSize?: React.CSSProperties['fontSize'];
};
export interface BlockContentTextProps
  extends UIContentBlock<TemplateBlockText> {}

export interface TextAnimationProps extends FontStyles {
  texts: TextAsset[];
  interval?: number;
  textAlign?: 'start' | 'center' | 'end';
  isAnimationDisabled?: boolean;
  sx?: SystemStyleObject;
}

type AnimationType = keyof typeof ANIMATIONS;

export interface TextSliderProps extends TextAnimationProps {
  count?: number;
  spacing?: number | string;
}

export interface TextCarouselProps extends TextAnimationProps {
  type?: 'Fade' | 'Scale' | 'None';
  offsetY?: number | string;
}

const TextSlider = ({
  sx,
  texts = [],
  count = 3,
  fontSize = '3rem',
  interval = ANIMATION_INTERVAL.Texts,
  spacing = 16,
  textAlign = 'center',
  fontStyle = 'normal',
  fontWeight = 'normal',
  textDecoration = 'normal',
  isAnimationDisabled = false,
}: TextSliderProps) => {
  const [items, setItems] = React.useState(texts);
  const onDisplayItems = React.useMemo(
    () => slice(items, -count),
    [items, count]
  );

  // Function to move the last item in the array to the front
  const rotateItems = React.useCallback(() => {
    if (isAnimationDisabled) return;
    const lastItem = items[items.length - 1];
    const newArray = [lastItem, ...items.slice(0, -1)];
    setItems(newArray);
  }, [items, isAnimationDisabled]);

  // rotate array
  useInterval(rotateItems, interval);

  const { fontSize: maxFontSize, spacing: maxSpacing } = useBreakpointValue(
    {
      base: { fontSize: `calc(${fontSize} * 0.5)`, spacing: 4 },
      md: { fontSize: `calc(${fontSize} * 0.8)`, spacing: 8 },
      lg: { fontSize, spacing: 10 },
      xl: { fontSize, spacing },
    },
    { fallback: 'base' }
  ) as ResponsiveFont;

  const variants = React.useMemo(
    () => ({
      default: { fontSize: `calc(${maxFontSize} * 0.4)`, opacity: 0.5 },
      [onDisplayItems.length - 2]: {
        fontSize: `calc(${maxFontSize} * 0.7)`,
        opacity: 0.8,
      },
      [onDisplayItems.length - 1]: { fontSize: maxFontSize, opacity: 1 },
    }),
    [maxFontSize]
  );

  // reset the items when the texts change
  React.useEffect(() => {
    setItems(texts);
  }, [texts]);

  return (
    <Reorder.Group as="div" axis="x" values={items} onReorder={rotateItems}>
      <HStack spacing={maxSpacing} px="4" justify={textAlign} sx={sx}>
        {onDisplayItems.map((item, index) => {
          return (
            <Reorder.Item
              as="div"
              key={item?.id || index}
              value={item}
              initial={false}
              animate={get(variants, index, variants.default)}
              dragListener={false}
              children={
                <Text
                  fontStyle={fontStyle}
                  fontWeight={fontWeight}
                  textDecoration={textDecoration}
                  children={item?.content}
                />
              }
            />
          );
        })}
      </HStack>
    </Reorder.Group>
  );
};

const TextCarousel = ({
  sx,
  texts = [],
  type = 'Fade',
  interval = ANIMATION_INTERVAL.Texts,
  fontSize = '4rem',
  offsetY = '20px',
  textAlign = 'center',
  textDecoration = 'normal',
  fontStyle = 'normal',
  isAnimationDisabled = false,
}: TextCarouselProps) => {
  const [text, frame] = useAutoPaginate(texts, interval, isAnimationDisabled);
  const maxFontSize = useComputedPixelValue(fontSize?.toString());

  return (
    <Box
      key={frame}
      as={get(ANIMATIONS, type, ANIMATIONS.Fade)}
      {...(type !== 'None' && { in: true, offsetY })}
    >
      <TextFit
        mode="nowrap"
        whiteSpace="normal"
        textAlign={textAlign}
        maxFontSize={maxFontSize}
        textDecoration={textDecoration}
        fontStyle={fontStyle}
        sx={sx}
      >
        {text?.content}
      </TextFit>
    </Box>
  );
};

export const BlockContentText = ({ block, sx }: BlockContentTextProps) => {
  if (!get(block, 'content.length')) return null;

  const texts = React.useMemo(
    () => flatMap(get(block, 'content'), 'texts'),
    [block?.content]
  );
  const config = get(block, 'config', {});
  const animation = get(config, 'animation');
  const animationType = get(animation, 'type', 'Fade') as AnimationType;
  const fontSize = get(config, 'style.fontSize');
  const fontStyle = get(config, 'style.fontStyle');
  const fontWeight = get(config, 'style.fontWeight');
  const textDecoration = get(config, 'style.textDecoration');
  const align = get(config, 'style.textAlign', 'center');
  if (animationType === 'Slide') {
    return (
      <TextSlider
        sx={sx}
        texts={texts}
        count={get(animation, 'count')}
        spacing={get(animation, 'spacing')}
        interval={get(animation, 'interval')}
        fontSize={fontSize}
        textAlign={align}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        textDecoration={textDecoration}
        isAnimationDisabled={get(animation, 'isDisabled')}
      />
    );
  }
  return (
    <TextCarousel
      sx={sx}
      type={animationType}
      texts={texts}
      interval={get(animation, 'interval')}
      offsetY={get(animation, 'offsetY')}
      textAlign={align}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      textDecoration={textDecoration}
      isAnimationDisabled={get(animation, 'isDisabled')}
    />
  );
};

export default BlockContentText;
