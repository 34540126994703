/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import * as yup from 'yup';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import {
  Button,
  HStack,
  VStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Box,
  Text,
} from '@chakra-ui/react';

import { Folder } from '../../../components/DataView';
import { useUpdateContentGroupMutation } from '../../../services/content-library';

interface RenameFolderForm<T extends Folder> {
  isOpen?: boolean;
  data?: T;
  onClose?: () => void;
}

const schema = yup
  .object({
    name: yup
      .string()
      .label('Folder Name')
      .min(2, '${path} must be at least 2 characters')
      .max(50, '${path} must not exceed 50 characters')
      .required(),
  })
  .required();

type FolderFormData = yup.InferType<typeof schema>;

export const RenameFolderForm = <T extends Folder>({
  isOpen = false,
  onClose = () => {},
  data: folder,
}: RenameFolderForm<T>) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors, isDirty },
  } = useForm<FolderFormData>({
    resolver: yupResolver(schema),
  });

  // reset the form when the data changes
  React.useEffect(() => {
    resetForm({ name: folder?.name });
  }, [folder]);

  const [updateContent, { isLoading }] = useUpdateContentGroupMutation();
  const onSubmit = async (formData: FolderFormData) => {
    try {
      if (!folder?.id) throw new Error('No Folder selected');
      await updateContent({
        id: folder.id,
        name: formData.name,
        type: 'media',
      }).unwrap();

      onClose?.();
      toast({
        title: `Folder successfully edited`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error editing folder - ${folder?.name}`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isCentered
      size="xl"
      isOpen={!!folder && isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Edit Folder</ModalHeader>
        <ModalBody>
          {!!folder?.createdAt && (
            <Box mb={5} px={6} py={4} bg="blackAlpha.100">
              <Text fontSize="sm" fontWeight="medium">
                CREATION DATE
              </Text>
              <Text fontSize="sm">
                {DateTime.fromISO(folder?.createdAt).toLocaleString()}
              </Text>
            </Box>
          )}
          <VStack spacing={5}>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter as={HStack} mt={4}>
          <Button
            isDisabled={isLoading}
            variant="ghost"
            size="md"
            onClick={onClose}
            children="Cancel"
          />
          <Button
            isDisabled={isLoading || !isDirty}
            isLoading={isLoading}
            size="md"
            colorScheme="blue"
            mr={3}
            type="submit"
            children="Save"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RenameFolderForm;
