/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { useState } from 'react';
import get from 'lodash/get';
import {
  useReactTable,
  RowData,
  ColumnSort,
  TableOptions,
  getSortedRowModel,
  getCoreRowModel,
  Table,
} from '@tanstack/react-table';
import { ConfirmDialog, ConfirmDialogProps } from '../ConfirmActionDialog';
import { useControllableState } from '@chakra-ui/react';

// This types are global to all tables and can be extended
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    label?: string;
    style?: any;
  }
}

export type ItemSelectionState = Record<string, boolean>;
export type ViewCore<T extends RowData> = Table<T> & {
  openConfirmDialog: (options?: ConfirmDialog) => void;
  getSelectedData: () => T[];
  getIsRowsSelected: () => boolean;
  getIsViewEmpty: () => boolean;
  /**
   * Gets the length of the data in the view.
   */
  length: number;
};

export interface ItemSelection {
  getIsSelected?: () => boolean;
  getCanSelect?: () => boolean;
  getIsSomeSelected?: () => boolean;
  toggleSelected?: (value?: boolean) => void;
  getToggleSelectedHandler?: () => (event: unknown) => void;
}

/** NOTE: 43 - We need to defined a global empty array to prevent infite loop on react table when data is undefined
 * https://github.com/TanStack/table/issues/4240
 * */
const emptyArray = [];

type ViewOptions<T extends RowData> = {
  data?: T[];
  enableSelection?: boolean;
  onRowSelectionChange?: (value: ItemSelectionState) => void;
};

export interface DataViewOptions<T extends RowData> extends ViewOptions<T> {
  rowSelection?: ItemSelectionState;
  onRowSelectionChange?: (value: ItemSelectionState) => void;
}

export type ViewCoreOptions<T extends RowData> = ViewOptions<T> &
  Pick<TableOptions<T>, 'columns' | 'state' | 'enableRowSelection'>;

// create a sortable and selectable view model default options
export const useDataView = <T extends RowData>({
  data,
  ...props
}: ViewCoreOptions<T>): ViewCore<T> => {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>();
  const [rowSelection, setRowSelection] =
    useControllableState<ItemSelectionState>({
      defaultValue: {},
      value: props.state?.rowSelection,
      onChange: props.onRowSelectionChange,
    });

  const openConfirmDialog = (options?: ConfirmDialog) =>
    setConfirmDialog({
      ...options,
      isOpen: true,
      onClose: () => setConfirmDialog(undefined),
    });

  const table = useReactTable({
    ...props,
    // FIND - NOTE: 43
    data: data ?? emptyArray,
    state: {
      ...props.state,
      sorting,
      rowSelection,
      confirmDialog,
    },
    // first we attempt to get the row id from the data, if that fails we use the index
    getRowId: (data: T, index, parent) =>
      get(data, 'id', `${parent ? [parent.id, index].join('.') : index}`),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    onSortingChange: setSorting,
    enableRowSelection: props.enableRowSelection ?? props?.enableSelection,
    onRowSelectionChange: setRowSelection,
  } as TableOptions<T>);

  /** This method will extract original data from the view */
  const getSelectedData = () =>
    table.getSelectedRowModel().rows?.map((row) => row.original);

  /** Check if any row is selected in the view */
  const getIsRowsSelected = React.useCallback(
    () => Object.keys(rowSelection).length > 0,
    [rowSelection]
  );

  /** Check if the view is empty */
  const getIsViewEmpty = React.useCallback(() => data?.length === 0, [data]);
  return {
    ...table,
    getIsViewEmpty,
    getIsRowsSelected,
    openConfirmDialog,
    getSelectedData,
    length: data?.length ?? 0,
  };
};

export * from './AssetsGrid';
export * from './FoldersGrid';
