/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Add, Delete } from 'emotion-icons/material';
import { get, pick, isEqual, uniqueId, result, map } from 'lodash';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  HStack,
  Progress,
  ScaleFade,
  Skeleton,
  Spacer,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useBranch } from '../../../hooks/branch';
import EditableText from './EditableText';
import NewTextGroupForm from './NewTextGroupForm';
import ConfirmActionDialog from '../../../components/ConfirmActionDialog';
import Page, { PageContainer, PageHeading } from '../../../components/Page';
import {
  useGetTextGroupsQuery,
  TextContentGroup,
  UpdateTextContent,
  useBulkDeleteContentGroupsMutation,
  useUpdateContentGroupMutation,
} from '../../../services/content-library';
import {
  ItemSelection,
  DataViewOptions,
  useDataView,
} from '../../../components/DataView';
import EditableTextList, {
  EditableTextListFC,
  TextListItem,
} from './EditableTextList';
import EmptyMessage from '../EmptyMessage';

interface TextGroupProps extends TextContentGroup, ItemSelection {
  isEditable?: boolean;
  isLoading?: boolean;
  onSave?: (group: UpdateTextContent) => void;
  onDelete?: () => void;
  leftElement?: EditableTextListFC;
}

export const useTextGroupView = <T extends TextContentGroup>({
  data,
  enableSelection,
  rowSelection,
  onRowSelectionChange,
}: DataViewOptions<T>) => {
  return useDataView({
    data,
    enableRowSelection: enableSelection,
    state: { rowSelection },
    onRowSelectionChange,
    columns: [{ accessorKey: 'name', meta: { label: 'Name' } }],
  });
};

export const TextGroup = ({
  getCanSelect,
  getIsSelected,
  getIsSomeSelected,
  getToggleSelectedHandler,
  isEditable,
  isLoading,
  leftElement,
  name,
  onDelete,
  onSave,
  texts: original = [],
  ...props
}: TextGroupProps) => {
  const [groupName, setGroupName] = React.useState(name);
  const [editableTexts, setEditableTexts] =
    React.useState<TextListItem[]>(original);

  const isDirty = groupName !== name || !isEqual(editableTexts, original);

  const handleOnSave = () =>
    onSave?.({ ...props, name: groupName, texts: editableTexts });

  // reset the editable texts when the original texts change
  React.useEffect(() => {
    if (original?.length) setEditableTexts(original);
  }, [original]);

  return (
    <AccordionItem>
      <EditableText
        as={AccordionButton}
        resize="none"
        placeholder="Enter group name"
        fontSize="lg"
        fontWeight="medium"
        defaultValue={name}
        isEditable={isEditable}
        isModified={isDirty}
        onDelete={onDelete}
        onSubmit={setGroupName}
        onDiscard={() => setEditableTexts(original)}
        actions={
          <Button
            children="Save"
            onClick={handleOnSave}
            _hover={{ bg: 'gray.200' }}
          />
        }
        leftIcon={<AccordionIcon />}
        leftElement={
          <Checkbox
            variant="ghost"
            hidden={!getCanSelect?.()}
            size="lg"
            mr={2}
            isIndeterminate={getIsSomeSelected?.()}
            isChecked={getIsSelected?.()}
            onChange={getToggleSelectedHandler?.()}
          />
        }
      />
      <AccordionPanel pt={0}>
        <EditableTextList
          id={props?.id}
          isEditable={isEditable}
          texts={editableTexts}
          onChange={setEditableTexts}
          leftElement={leftElement}
          onAddText={(content) => {
            setEditableTexts((prevTexts) => [
              { guid: uniqueId(), content, isUpdated: true },
              ...prevTexts,
            ]);
          }}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

const TextLibrary = () => {
  const branch = useBranch();
  const toast = useToast();

  const { onOpen: onOpenAddForm, ...addFormProps } = useDisclosure();

  const [bulkDeleteGroups] = useBulkDeleteContentGroupsMutation();
  const [updateGroup] = useUpdateContentGroupMutation();

  const {
    data: textGroupsResp,
    isLoading: isLoadingGroups,
    isFetching: isFetchingGroups,
  } = useGetTextGroupsQuery(branch?.id);

  const view = useTextGroupView({
    data: textGroupsResp?.data,
    enableSelection: true,
  });

  const selectedGroupsCount = view.getSelectedRowModel().rows.length;

  /** TODO: We should create an API endpoint to partialy update the text group data
   * Now we are updating the whole group data, which is not ideal when we have a lot of texts
   */
  const handleUpdateGroup = async (group: UpdateTextContent) => {
    try {
      const texts = map(group.texts, (t) => pick(t, ['id', 'content']));
      await updateGroup({
        id: group.id,
        name: group.name,
        type: 'texts',
        texts,
      }).unwrap();
      toast({
        title: `Text group ${group.name} successfully updated`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error creating text group`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleDeleteSelected = async () => {
    const selectedGroups = view.getSelectedData();
    if (!selectedGroups?.length) return;
    try {
      await bulkDeleteGroups(map(selectedGroups, 'id')).unwrap();
      view.resetRowSelection?.();
    } catch (error) {
      toast({
        title: 'Error deleting text groups. Please try again',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Page>
      <NewTextGroupForm {...addFormProps} />
      <ConfirmActionDialog {...result(view, 'getState.confirmDialog', {})} />
      <HStack>
        <PageHeading>Text Library</PageHeading>
        <Spacer />
        <Button
          size="md"
          leftIcon={<Add size="24" />}
          colorScheme="blue"
          children="Add"
          onClick={onOpenAddForm}
        />
        <Button
          hidden={selectedGroupsCount === 0}
          size="md"
          leftIcon={<Delete size="24" />}
          colorScheme="red"
          variant="outline"
          children={`(${selectedGroupsCount}) Delete`}
          onClick={() => {
            view?.openConfirmDialog?.({
              title: 'Delete Text Groups',
              description: `Are you sure you want to delete ${selectedGroupsCount} items?`,
              type: 'delete',
              onSubmit: () => handleDeleteSelected(),
            });
          }}
        />
      </HStack>
      <PageContainer as={ScaleFade} initialScale={0.9} in>
        <Progress hidden={!isFetchingGroups} isIndeterminate size="sm" />
        <Skeleton minH="2rem" isLoaded={!isLoadingGroups}>
          <EmptyMessage hidden={!view.getIsViewEmpty()} />
          <Accordion allowToggle>
            {view.getRowModel().rows.map((row) => (
              <TextGroup
                key={row.original?.id || row.id}
                {...row.original}
                {...pick(row, [
                  'getCanSelect',
                  'getIsSelected',
                  'getIsSomeSelected',
                  'getToggleSelectedHandler',
                  'toggleSelected',
                ])}
                onSave={(group) => {
                  view?.openConfirmDialog?.({
                    title: 'Save Text Group',
                    description: `Are you sure you want to save ${group.name}?`,
                    type: 'info',
                    labels: { submit: 'Save' },
                    onSubmit: () => handleUpdateGroup(group),
                  });
                }}
                onDelete={() => {
                  view?.openConfirmDialog?.({
                    title: 'Delete Text Group',
                    description: `Are you sure you want to delete ${row.original.name}?`,
                    type: 'delete',
                    onSubmit: () => bulkDeleteGroups([row.original?.id]),
                  });
                }}
              />
            ))}
          </Accordion>
        </Skeleton>
      </PageContainer>
    </Page>
  );
};

export default TextLibrary;
