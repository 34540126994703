/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

export type ChangeHandler = (event: {
  target: any;
  type?: any;
}) => Promise<any>;

export type ComboBoxOption = {
  [key: string]: any;
};

export * from './color-picker';
export * from './editable-select';
export * from './file-select';
export * from './multi-select';
