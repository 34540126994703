/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get, isFunction } from 'lodash';
import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Checkbox,
  useControllableState,
  Skeleton,
  Box,
  Center,
  VStack,
  Button,
  Progress,
  SystemStyleObject,
} from '@chakra-ui/react';
import {
  TableOptions,
  ColumnDef,
  ColumnSort,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  HeaderContext,
  OnChangeFn,
  RowData,
  RowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowDownward, ArrowUpward, Warning } from 'emotion-icons/material';
import { TableHeader, TableHeadersProps } from './header';
import Pagination, { PaginationProps } from './pagination';
import ConfirmActionDialog, {
  ConfirmDialogProps,
  ConfirmDialog,
} from '../ConfirmActionDialog';

export interface TableMessageProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  action?: { label: string; onClick: () => void };
}

export interface DataTableProps<T extends RowData> {
  data?: T[];
  columns: ColumnDef<T, any>[];
  enableSorting?: boolean;
  enableRowSelection?: boolean;
  selectedRows?: RowSelectionState;
  onSelectedRowChange?: OnChangeFn<RowSelectionState>;
  header?: TableHeadersProps<T>;
  pagination?: PaginationProps;
  isLoading?: boolean;
  inProgress?: boolean;
  empty?: TableMessageProps;
  onStyleRow?: (rowData: T) => SystemStyleObject;
}

export const sortableHeader = (info: HeaderContext<any, any>) => {
  const sortIcon = {
    asc: <ArrowUpward size={18} />,
    desc: <ArrowDownward size={18} />,
  }[info.column.getIsSorted() as string] ?? (
    <ArrowDownward size={18} opacity={0} />
  );

  const header = isFunction(info.column.columnDef.header)
    ? info.column.columnDef.header(info)
    : info.column.columnDef.header || info.column.id;

  return (
    <HStack>
      <Text
        as="div"
        color={info.column.getIsSorted() ? 'blackAlpha.900' : 'blackAlpha.600'}
        children={header}
      />
      {info.column.getCanSort() ? sortIcon : null}
    </HStack>
  );
};

export const TableMessage = ({
  title,
  icon,
  description = 'There are not objects here yet.',
  action,
}: TableMessageProps) => {
  return (
    <Box w="full" p={6}>
      <Center>
        <VStack spacing={4}>
          {icon || <Warning size={32} color="var(--chakra-colors-gray-400)" />}
          {title && <Text fontSize="2xl">{title}</Text>}
          <Text>{description}</Text>
          {action && (
            <Button colorScheme="blue" onClick={action.onClick}>
              {action.label}
            </Button>
          )}
        </VStack>
      </Center>
    </Box>
  );
};

export const DataTable = <T extends RowData>({
  data = [],
  columns,
  enableSorting = true,
  enableRowSelection = false,
  selectedRows,
  onSelectedRowChange,
  header,
  empty,
  pagination,
  isLoading,
  inProgress,
  onStyleRow,
}: DataTableProps<T>) => {
  const [rowSelection, setRowSelection] =
    useControllableState<RowSelectionState>({
      value: selectedRows,
      onChange: onSelectedRowChange,
      defaultValue: {},
    });

  const [sorting, setSorting] = React.useState<ColumnSort[]>([]);
  const [confirmDialog, setConfirmDialog] =
    React.useState<ConfirmDialogProps>();

  const openConfirmDialog = (options?: ConfirmDialog) =>
    setConfirmDialog({
      ...options,
      isOpen: true,
      onClose: () => {
        table.resetRowSelection();
        setConfirmDialog(undefined);
      },
    });

  const table = useReactTable({
    state: {
      sorting,
      rowSelection,
    },
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting,
    enableRowSelection,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    openConfirmDialog,
  } as TableOptions<T>);

  return (
    <>
      <ConfirmActionDialog {...confirmDialog} />
      <TableHeader table={table} {...header} />
      <TableContainer>
        {isLoading || inProgress ? (
          <Progress size="xs" isIndeterminate />
        ) : null}
        <Skeleton isLoaded={!isLoading}>
          <Table>
            <Thead bg="blackAlpha.50">
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {enableRowSelection && (
                    <Th w={2}>
                      <Checkbox
                        size="lg"
                        isChecked={table.getIsAllRowsSelected()}
                        isIndeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                      />
                    </Th>
                  )}
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      sx={get(header, 'column.columnDef.meta.style.th')}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(sortableHeader, header.getContext())}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id} sx={onStyleRow?.(row.original)}>
                  {enableRowSelection && (
                    <Td>
                      <Checkbox
                        size="lg"
                        isDisabled={!row.getCanSelect()}
                        isChecked={row.getIsSelected()}
                        onChange={row.getToggleSelectedHandler()}
                      />
                    </Td>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      sx={get(cell, 'column.columnDef.meta.style.td')}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          {data.length === 0 && <TableMessage {...empty} />}
        </Skeleton>
      </TableContainer>
      {pagination && !!data.length && <Pagination {...pagination} />}
    </>
  );
};

export { createColumnHelper, RowModel, RowData };
export * from './header';
export * from './pagination';
export default DataTable;
