/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import {
  BoxProps,
  Card,
  CardProps,
  Container,
  HStack,
  Heading,
  HeadingProps,
  Link,
  Spacer,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

export const PageLinks = () => (
  <HStack py={6}>
    <Text fontSize="xs" color="gray.500">
      © Innovative Internet Solutions, Inc.
    </Text>
    <Spacer />
    <Link isExternal href="#test" color="gray.500" fontSize="xs">
      About Us
    </Link>
    <Link isExternal href="#test" color="gray.500" fontSize="xs">
      Terms
    </Link>
  </HStack>
);
export const Page = (props: BoxProps) => (
  <Container maxW={{ base: '100vw', md: 'container.xl' }} mt={8}>
    {props.children}
    <PageLinks />
  </Container>
);

export const PageHeading = (props: HeadingProps) => (
  <Heading as="h4" size="lg" {...props} />
);

export const PageContainer = forwardRef((props: CardProps, ref) => (
  <Card ref={ref} mt={8} {...props} />
));

export default Page;
