/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { useState } from 'react';
import { pick, startCase, result } from 'lodash';
import { ArrowUpward, ArrowDownward, SwapVert } from 'emotion-icons/material';
import {
  Box,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  SimpleGrid,
  Spacer,
  Checkbox,
  Skeleton,
} from '@chakra-ui/react';

import { useDataView, ViewCore, DataViewOptions } from '..';
import { AssetAction, AssetCard } from './AssetCard';
import ConfirmActionDialog from '../../ConfirmActionDialog';

export enum AssetType {
  VIDEO = 'video',
  IMAGE = 'image',
  DOCUMENT = 'doc',
  AUDIO = 'audio',
}

export interface Asset {
  id: string | number;
  name: string;
  mime: string;
  createdAt?: string;
  ext?: string;
  hash?: string;
  isLocal?: boolean;
  path?: string;
  rawFile?: File;
  size: number;
  source?: string;
  type?: string;
  url: string;
  height?: string | number;
  width?: string | number;
  alternativeText?: string;
  extension?: string;
  hostname?: string;
  formats?: { thumbnail?: Asset; small?: Asset; medium?: Asset; large?: Asset };
}

export interface AssetGridListProps<T extends Asset = Asset> {
  actions?: AssetAction[];
  view: ViewCore<T>;
  isEditable?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  title?: string;
  hidden?: boolean;
  isIndeterminateEnabled?: boolean;
}

export const useAssetsGrid = <T extends Asset>({
  data,
  enableSelection,
  rowSelection,
  onRowSelectionChange,
}: DataViewOptions<T>) => {
  return useDataView({
    data,
    enableRowSelection: enableSelection,
    state: { rowSelection },
    onRowSelectionChange,
    columns: [
      { accessorKey: 'name', meta: { label: 'Name' } },
      { accessorKey: 'type', meta: { label: 'Type' } },
      { accessorKey: 'size', meta: { label: 'Size' } },
    ],
  });
};

export const AssetsGrid = <T extends Asset>({
  title,
  actions,
  view,
  isEditable,
  isLoading,
  isFetching,
  hidden,
  isIndeterminateEnabled = true,
}: AssetGridListProps<T>) => {
  const [sortBy, setSortBy] = useState<string>('name');

  // set sorting values
  const sortColumn = view.getColumn(sortBy);
  const sortIcon = {
    asc: {
      icon: <ArrowUpward size={18} />,
      value: true,
    },
    desc: {
      icon: <ArrowDownward size={18} />,
      value: false,
    },
  }[sortColumn?.getIsSorted() as string] ?? {
    icon: <SwapVert size={18} />,
    value: true,
  };

  return (
    <Box w="full" hidden={hidden}>
      <ConfirmActionDialog {...result(view, 'getState.confirmDialog', {})} />
      <HStack paddingTop={2} paddingBottom={2}>
        <Heading fontSize="lg" variant="delta" fontWeight="semibold">
          {title} Files
        </Heading>
        <Spacer />
        <HStack>
          <Menu>
            <MenuButton children={startCase(sortBy)} />
            <MenuList>
              {view.getAllColumns().map((col) => (
                <MenuItem
                  key={col.id}
                  children={col.columnDef.meta?.label}
                  onClick={() => setSortBy(col.id)}
                />
              ))}
            </MenuList>
          </Menu>
          <IconButton
            variant="ghost"
            size="sm"
            aria-label={`sort-folders-by-${sortBy}`}
            icon={sortIcon?.icon}
            onClick={sortColumn?.getToggleSortingHandler()}
          />
        </HStack>
        <Checkbox
          // TODO: Sometimes select all hidden can be confusing
          // hidden={!view.getIsRowsSelected()}
          isChecked={view.getIsAllRowsSelected()}
          isIndeterminate={
            isIndeterminateEnabled ? view.getIsSomeRowsSelected() : false
          }
          onChange={view.getToggleAllRowsSelectedHandler()}
          size="lg"
        />
      </HStack>
      <Skeleton isLoaded={!isLoading} minH="2rem">
        <SimpleGrid columns={{ md: 2, lg: 4 }} spacing={2}>
          {view.getRowModel().rows.map((row) => (
            <AssetCard
              key={row.id}
              actions={actions}
              enbaleAssetSelection={!!view.options.enableRowSelection}
              asset={row.original}
              isEditable={isEditable}
              isLoading={isFetching}
              {...pick(row, [
                'getCanSelect',
                'getIsSelected',
                'getIsSomeSelected',
                'getToggleSelectedHandler',
                'toggleSelected',
              ])}
            />
          ))}
        </SimpleGrid>
      </Skeleton>
    </Box>
  );
};

export default AssetsGrid;
