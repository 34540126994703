/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  isObjectLike,
  attempt,
  isError,
  isFunction,
  assign,
  pick,
  result,
  replace,
  get,
  isString,
  set,
} from 'lodash';

import { RawData, ResponseIterator } from './services.d';

export const BASE_API_URL = process.env.API_URL || 'http://localhost:1337';
export const API_ENDPOINT = `${BASE_API_URL}/api`;
export const ApiTags = [
  'Appointments',
  'Branches',
  'ContentLibraryMedia',
  'ContentLibraryText',
  'Displays',
  'DisplayTemplates',
  'Groups',
  'UserContext',
  'Users',
] as const;

export const getDataModel = <E, D extends object = never>(
  item: RawData<D>,
  iteratee?: ResponseIterator<E, D>
): E => {
  const base = assign({ ...item }, pick(item, 'id'), get(item, 'attributes'));
  if (iteratee && isFunction(iteratee)) iteratee(base);
  if (iteratee && isObjectLike(iteratee)) {
    assign(
      base,
      Object.entries(iteratee).reduce((p, [k, v]) => {
        const id = get(
          base,
          `${replace(iteratee[k], /(.*)\.attributes/, '$1.id')}`
        );
        const value = isString(iteratee[k])
          ? get(base, iteratee[k], iteratee[k])
          : isFunction(iteratee[k])
          ? attempt(iteratee[k], base, base[k])
          : iteratee[k];
        if (id && isObjectLike(value)) set(value, 'id', id);
        return {
          ...p,
          [k]: isError(value) ? undefined : value,
        };
      }, {})
    );
  }
  return base as E;
};

export const getListModel = <E extends any[], D extends object = never>(
  data: D[],
  iteratee?: ResponseIterator<E[number], D>
) => (get(data, 'length') ? data.map((v) => getDataModel(v, iteratee)) : []);

export const baseQuery = fetchBaseQuery({
  baseUrl: API_ENDPOINT,
  prepareHeaders: (headers, api) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    // TOOD: we need to change this to use service workers or something else. This is not secure.
    const token = result(api, 'getState.app.auth.jwt');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseApi = createApi({
  reducerPath: 'api',
  tagTypes: ApiTags,
  baseQuery,
  endpoints: () => ({}),
});

export * from './services.d';
export default baseApi;
