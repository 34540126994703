/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { Tabs, Box, chakra, Skeleton } from '@chakra-ui/react';

export const PreviewLoader = chakra(Skeleton, {
  baseStyle: {
    pos: 'absolute',
    boxSize: 'full',
    opacity: 1,
    zIndex: 'modal',
  },
});

export const TabsWrapper = chakra(Tabs, {
  baseStyle: {
    w: 'full',
    maxW: '240px',
    bg: 'white',
    boxShadow:
      '0px 1px 1px 0px rgba(0,0,0,.14), 0px 2px 1px -1px rgba(0,0,0,.12), 0px 1px 3px 0px rgba(0,0,0,.2)',
  },
});

export const EditorHeader = chakra(Box, {
  baseStyle: {
    p: 3,
    fontWeight: 'medium',
    w: 'full',
    textAlign: 'center',
    borderBottom: '1px solid var(--chakra-colors-blue-600)',
  },
});

export const TemplateWrapper = chakra('div', {
  baseStyle: {
    flexGrow: 1,
    overflow: 'hidden',
    maxH: 'calc(100vh - 64px)',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      overflow: 'visible',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '0.5em',
    },
  },
});

export const TemplatePreview = chakra('div', {
  baseStyle: {
    pos: 'relative',
    bg: 'white',
    m: { base: '8px 30px', md: '8px 60px' },
    overflowY: 'auto',
    minH: 'calc(100vh - 80px)',
    boxShadow:
      '0px 2px 2px 0px rgba(0,0,0,.14), 0px 3px 1px -2px rgba(0,0,0,.12), 0px 1px 5px 0px rgba(0,0,0,.2)',
  },
});
