/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

export type ElementSize = {
  width: number;
  height: number;
};

/** track element resize and return a cleanup function to disconnect */
export const trackResize = (
  elements: Array<Element | null | undefined>,
  cb: (entries?: ResizeObserverEntry[]) => void
) => {
  if (!elements) return;

  const observers = elements.reduce((result, el) => {
    if (!el) return result;
    const win = el.ownerDocument?.defaultView ?? window;
    const observer = new win.ResizeObserver((entries) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        cb(entries);
      });
    });
    observer.observe(el, { box: 'border-box' });
    result.push(observer);
    return result;
  }, [] as ResizeObserver[]);

  return () => {
    observers.forEach((observer) => observer.disconnect());
  };
};

// Calculate height without padding.
export const innerHeight = (
  el?: Element | null,
  style?: CSSStyleDeclaration
) => {
  if (!el) return 0;
  const innerStyle = style ?? window.getComputedStyle(el, null);
  // Hidden iframe in Firefox returns null, https://github.com/malte-wessel/react-textfit/pull/34
  if (!innerStyle) return el.clientHeight;
  return (
    el.clientHeight -
    parseInt(innerStyle.getPropertyValue('padding-top'), 10) -
    parseInt(innerStyle.getPropertyValue('padding-bottom'), 10)
  );
};

// Calculate width without padding.
export function innerWidth(el?: Element | null, style?: CSSStyleDeclaration) {
  if (!el) return 0;
  const innerStyle = style ?? window.getComputedStyle(el, null);
  // Hidden iframe in Firefox returns null, https://github.com/malte-wessel/react-textfit/pull/34
  if (!innerStyle) return 0;
  return (
    el.clientWidth -
    parseInt(innerStyle.getPropertyValue('padding-left'), 10) -
    parseInt(innerStyle.getPropertyValue('padding-right'), 10)
  );
}
