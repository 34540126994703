/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { pick, some, matches, debounce } from 'lodash';
import { UISelectedBlock, UITemplateBlockId } from './slice';
import {
  UITemplateBlock,
  UITemplateSection,
} from '../../components/DisplayTemplate';

/** typescript check if block is a section */
export const isUISectionBlockType = (
  block?: UISelectedBlock
): block is UITemplateSection =>
  (block as UITemplateSection)?.blocks !== undefined;

/** typescript check if block is a content block */
export const isUIContentBlockType = (
  block?: UISelectedBlock
): block is UITemplateBlock => {
  const _block = block as UITemplateBlock;
  return (
    _block?.source !== undefined ||
    _block?.config !== undefined ||
    _block?.blockUID !== undefined
  );
};

/** check if block is within a section */
export const getIsBlockInSection = (
  section?: UITemplateSection,
  blockUID?: UITemplateBlockId
) => !!blockUID && some(section?.blocks, matches(blockUID));

/** re-export check as a helper hook to use withing react components */
export const useIsUISectionBlockType = isUISectionBlockType;
/** re-export check as a helper hook to use withing react components */
export const useIsUIContentBlockType = isUIContentBlockType;

/** helper hook to get block id and blockUID
 *  block identity is made of an `id` and/or `blockUID` property
 */
export const useBlockUID = <T extends UISelectedBlock | undefined>(block: T) =>
  React.useMemo(() => pick(block, ['id', 'blockUID']), [block]);

/** helper hook to check if block is within a section */
export const useIsSectionBlock = (
  section?: UITemplateSection,
  blockUID?: UITemplateBlockId
) =>
  React.useMemo(
    () => getIsBlockInSection(section, blockUID),
    [section, blockUID]
  );

export const useDebounceCallback = <T extends (...args: any) => any>(
  callback: T,
  deps: React.DependencyList
) => React.useCallback(debounce(callback, 500), deps);
