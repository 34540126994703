/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import { get, pick } from 'lodash';

import baseApi, { StrapiId, StrapiRelationUpdate, getListModel } from '.';
import { Branch } from './branch';

export type USER_ROLES = 'ADMIN' | 'MEMBER' | 'VIEWER';

export const ROLE_TYPE_MAP = {
  authenticated: 'ADMIN',
  authenticated_member: 'MEMBER',
  public: 'VIEWER',
};

export interface UserRole {
  id: StrapiId;
  label?: USER_ROLES;
  name: string;
  type?: keyof typeof ROLE_TYPE_MAP;
}
export interface User {
  id: StrapiId;
  avatarUrl: string;
  blocked: boolean;
  confirmed: boolean;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  provider: string;
  createdAt: string;
  updatedAt: string;
  role: UserRole;
}
export interface UserResponse {
  user: User;
  jwt: string;
}
export interface UserRequest extends Partial<Omit<User, 'role'>> {
  id: User['id'];
  role: StrapiRelationUpdate;
}
export interface LoginRequest {
  identifier: string;
  password: string;
}
export interface Context {
  id?: StrapiId;
  createdAt: string;
  updatedAt: string;
  context: {
    user?: User | User['id'];
    organizationId: string;
    branchId: StrapiId;
    branch?: Branch;
    branchHistory: StrapiId[];
    branches: Branch[];
  };
}

type ContextUpdateRequest = { id: Context['id'] } & Partial<Context['context']>;
type CreateUserRequest = Omit<UserRequest, 'id'>;
type ResetPasswordReq = {
  code: string;
  password: string;
  passwordConfirmation: string;
};

const transformUserContextResponse = (res, ...args): Context => ({
  ...pick(res.data, 'id'),
  ...get(res.data, 'attributes', {}),
});

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth/local',
        method: 'POST',
        body: credentials,
      }),
    }),
    getUser: builder.query<User, string>({
      query: () => `/users/me?populate=role`,
    }),
    findUser: builder.query<User[], string>({
      query: (searchValue) => {
        const query = {
          populate: {
            role: { fields: ['id'] },
          },
          filters: {
            $or: [{ email: searchValue }],
          },
        };
        return `/users?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
    }),
    updateUser: builder.mutation<any, UserRequest>({
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
      query: (user) => ({
        url: `/users/${user.id}`,
        method: 'PUT',
        body: user,
      }),
    }),
    getRoles: builder.query<UserRole[], void>({
      query: (user) => ({
        url: `/users-permissions/roles`,
        method: 'GET',
        body: user,
      }),
      transformResponse: (res) =>
        getListModel<UserRole[]>(get(res, 'roles', []), {
          label: (role) => get(ROLE_TYPE_MAP, role.type),
        }),
    }),
    getUserContext: builder.query<Context, Branch['slug'] | void>({
      providesTags: ['UserContext'],
      query: (slug) => `/user-context${slug ? `?slug=${slug}` : ''}`,
    }),
    createUserContext: builder.mutation<Context, Partial<Context['context']>>({
      query: ({ user, ...context }) => ({
        url: `/portal-contexts`,
        method: 'POST',
        body: {
          data: {
            user: get(user, 'id', user),
            context,
          },
        },
      }),
      transformResponse: transformUserContextResponse,
    }),
    updateUserContext: builder.mutation<Context, ContextUpdateRequest>({
      invalidatesTags: ['UserContext'],
      query: ({ id, ...context }) => ({
        url: `/portal-contexts/${id}`,
        method: 'PUT',
        body: {
          data: { context },
        },
      }),
      transformResponse: transformUserContextResponse,
    }),
    createUser: builder.mutation<User, Partial<CreateUserRequest>>({
      invalidatesTags: (result, error) => [{ type: 'Users', id: 'LIST' }],
      query: (user) => ({
        url: `/users`,
        method: 'POST',
        body: user,
      }),
    }),
    forgotPassword: builder.mutation<any, string>({
      query: (email) => ({
        url: `/auth/forgot-password`,
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation<any, ResetPasswordReq>({
      query: (body) => ({
        url: `/auth/reset-password`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateUserContextMutation,
  useCreateUserMutation,
  useFindUserQuery,
  useForgotPasswordMutation,
  useGetRolesQuery,
  useGetUserContextQuery,
  useGetUserQuery,
  useLazyFindUserQuery,
  useLoginMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
} = authApi;

export default authApi;
