/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import get from 'lodash/get';
import {
  Button,
  HStack,
  VStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormHelperText,
} from '@chakra-ui/react';
import {
  useCreateGroupMutation,
  useGetGroupsQuery,
} from '../../services/groups';
import { useBranch } from '../../hooks/branch';
import { LoadingOverlay } from '../../components/DisplayTemplate';
import {
  GroupFormData,
  createGroupId,
  groupSchema,
} from '../BranchSettings/AddGroupForm';

const AddFirstGroup = () => {
  const toast = useToast();
  const branch = useBranch();
  const { data: groups, isLoading: isLoadingGroups } = useGetGroupsQuery([
    branch?.id,
  ]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<GroupFormData>({
    resolver: yupResolver(groupSchema),
    context: { groups: groups?.data },
    defaultValues: { groupName: '' },
  });

  const [createGroup, { isLoading: isCreatingGroup }] =
    useCreateGroupMutation();

  const onSubmit = async (aptData: GroupFormData) => {
    let guid: string = createGroupId(aptData.groupName);
    while (groups?.data?.find((g) => g.uid === guid)) {
      guid = createGroupId(aptData.groupName, guid.length + 1);
    }
    try {
      await createGroup({
        name: aptData.groupName,
        uid: guid?.trim()?.toUpperCase(),
        branchId: branch?.id,
      }).unwrap();
    } catch (error) {
      toast({
        title: 'Error creating group. Please try again or contact support',
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal
        size="xl"
        isCentered={isLoadingGroups}
        isOpen={(groups?.data?.length || 0) < 1}
        onClose={() => {}}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          {isLoadingGroups ? (
            <LoadingOverlay />
          ) : (
            <>
              <ModalHeader>Create your appointments group</ModalHeader>
              <ModalBody>
                <VStack spacing={5}>
                  <FormControl isRequired isInvalid={!!errors.groupName}>
                    <FormLabel>Group Name</FormLabel>
                    <Controller
                      name="groupName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value?.toUpperCase());
                          }}
                        />
                      )}
                    />
                    <FormHelperText>
                      Enter the name of your first group. Groups help you
                      organize your appointments.
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.groupName?.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              </ModalBody>
              <ModalFooter as={HStack} mt={4}>
                <Button
                  isLoading={isCreatingGroup}
                  size="md"
                  colorScheme="blue"
                  mr={3}
                  type="submit"
                  children="Create"
                />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddFirstGroup;
