/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import pick from 'lodash/pick';
import defaults from 'lodash/defaults';
import isEmpty from 'lodash/isEmpty';
import { ExpandMore, Check } from 'emotion-icons/material';
import {
  Portal,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Box,
  useControllableState,
  Button,
  Input,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  IconButton,
  InputProps,
  TooltipProps,
  useDisclosure,
  chakra,
  VStack,
} from '@chakra-ui/react';

import './styles.css';
import { NumberInputProps, useDragRange, useInputStyle } from './hooks';
import { ComboBoxOption } from '..';

type SizeProps = Pick<
  InputProps,
  | 'size'
  | 'maxWidth'
  | 'maxW'
  | 'maxH'
  | 'maxHeight'
  | 'w'
  | 'paddingLeft'
  | 'paddingRight'
>;

export interface EditableInputBase {
  type?: InputProps['type'];
  value?: string;
  defaultValue?: string;
  onChange?: (value?: string) => void;
  format?: (value?: string) => string;
  isReadOnly?: boolean;
}
export type EditableInputNumber = EditableInputBase & {
  type: 'number';
  direction?: 'x' | 'y';
  /** The step used to increment or decrement the value */
  step?: number;
  /** The number of decimal points used to round the value */
  precision?: number;
  min?: number;
  max?: number;
};
export type EditableInput = EditableInputBase | EditableInputNumber;
export type SelectOption = ComboBoxOption &
  EditableInput & { icon?: React.ReactElement; isEditable?: boolean };

interface PropsBase extends SizeProps {
  tooltip?: TooltipProps['label'];
  leftElement?: React.ReactNode;
  matchWidth?: boolean;
}
interface EditableOptionsProps<T extends SelectOption> extends PropsBase {
  hidden?: boolean;
  value?: never;
  type?: never;
  onChange?: never;
  defaultValue?: never;
  defaultOption?: number;
  options?: T[];
  optionsLabelKey?: keyof T;
  optionsValueKey?: keyof T;
  onOptionChange?: (value?: string) => void;
}
type EditableInputProps = PropsBase & EditableInput;
export type EditableSelectProps<T extends SelectOption> =
  | EditableInputProps
  | EditableOptionsProps<T>;

const OptionButton = chakra(Button, {
  baseStyle: {
    bg: 'transparent',
    borderRadius: 'none',
    fontWeight: 'normal',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    _focusVisible: {
      bg: 'inherit',
      boxShadow: 'inset 0 0 0 1px #3182ce',
    },
  },
});

export const EditableSelect = React.forwardRef(
  <T extends SelectOption>(
    props: EditableSelectProps<T>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const {
      size = 'sm',
      tooltip,
      leftElement,
      matchWidth,
      paddingLeft,
      paddingRight,
    } = props;
    const {
      value: valueProp,
      defaultValue: defaultValueProp = '',
      onChange: onChangeProp,
      type: typeProp,
      isReadOnly: isReadOnlyProp = false,
      format: formatProp,
    } = props as EditableInputProps;
    const {
      options = [],
      optionsLabelKey = 'label',
      optionsValueKey = 'value',
      defaultOption = 0,
      onOptionChange,
    } = props as EditableOptionsProps<T>;
    const styleProps = useInputStyle(props);

    const leftElementRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    // const innerRefs = useMergeRefs(inputRef, ref);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isFocus,
      onOpen: onFocus,
      onClose: onBlur,
    } = useDisclosure();

    const [selectedOption, setSelectedOption] = React.useState(defaultOption);
    const selected = options?.[selectedOption];
    const inputType = selected?.type || typeProp;
    const defaultValue = selected?.defaultValue || defaultValueProp || '';
    const inputFormat = selected?.format || formatProp || ((v: string) => v);
    const inputValue = selected?.[optionsValueKey] || valueProp;
    const [value, setValue] = useControllableState<string>({
      defaultValue,
      value: inputValue,
      onChange: selected?.onChange || onChangeProp,
    });
    const isReadOnly =
      isReadOnlyProp || (!selected?.isEditable && options?.length >= 1);
    const numberInputProps = defaults(
      pick(selected || props, ['direction', 'precision', 'step', 'min', 'max']),
      {
        direction: 'x',
        step: 1,
        precision: 0,
        max: Number.MAX_VALUE,
      }
    ) as Required<NumberInputProps>;

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (inputType !== 'number') return setValue(e.target.value);
      else {
        const value = Number(e.target.value);
        if (value <= numberInputProps.max) return setValue(e.target.value);
      }
    };
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur();
      /** this will ensure we always remove focus */
      if (e.relatedTarget instanceof HTMLElement) e.relatedTarget.focus();
      if (defaultValue && isEmpty(value)) setValue(defaultValue);
      else if (inputType === 'number' && Number(value) < numberInputProps.min)
        setValue(`${numberInputProps.min}`);
    };
    const handleSelect = (index: number) => {
      onClose();
      setSelectedOption(index);
    };

    React.useEffect(() => {
      if (inputValue || isEmpty(defaultValue)) return;
      switch (inputType) {
        case 'number':
          const val = parseFloat(defaultValue);
          if (isNaN(val)) return;
          setValue(
            `${Math.min(
              numberInputProps.max,
              Math.max(numberInputProps.min, val)
            )}`
          );
          break;
        default:
          setValue(defaultValue);
          break;
      }
    }, [defaultValue, inputValue]);

    React.useEffect(() => {
      setSelectedOption(defaultOption);
    }, [defaultOption]);

    React.useEffect(() => {
      if ('options' in props) onOptionChange?.(value);
    }, [value, selected?.onChange]);

    useDragRange({
      ...numberInputProps,
      ref: leftElementRef,
      onMouseDown: onFocus,
      onMouseUp: onBlur,
      value,
      onChange: !isReadOnly && inputType === 'number' ? setValue : undefined,
    });

    return (
      <VStack spacing={0} data-group {...pick(props, ['w', 'maxW'])}>
        <Tooltip hasArrow label={tooltip} size="xs" openDelay={500}>
          <InputGroup size={size} {...styleProps.group}>
            {(leftElement || selected?.icon) && (
              <InputLeftElement
                ref={leftElementRef}
                fontWeight="semibold"
                color="gray.800"
                children={leftElement || selected?.icon}
                sx={{ '> *': { pointerEvents: 'none' } }}
              />
            )}
            <Input
              ref={inputRef}
              borderColor={!isOpen ? 'transparent' : undefined}
              value={isFocus && !isReadOnly ? value : inputFormat(value)}
              type={isFocus ? inputType : 'text'}
              onChange={handleOnChange}
              isReadOnly={isReadOnly}
              onFocus={onFocus}
              onBlur={handleOnBlur}
              _readOnly={isReadOnlyProp ? { color: 'gray.500' } : undefined}
              _groupHover={styleProps.field?._hover}
              {...{ paddingLeft, paddingRight }}
              {...(inputType === 'number' && pick(numberInputProps, ['step']))}
              {...((isOpen || isFocus) && styleProps.field?._focusVisible)}
            />
            {options?.length >= 1 && (
              <InputRightElement w="fit-content" right="1px">
                <IconButton
                  hidden={isReadOnlyProp}
                  variant="unstyled"
                  bg="gray.100"
                  borderEndRadius="sm"
                  borderStartRadius="none"
                  size={size}
                  h="95%"
                  minW="auto"
                  icon={<ExpandMore size="1.5rem" />}
                  aria-label="expand-option"
                  isDisabled={isReadOnlyProp}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    onOpen();
                  }}
                  opacity={isOpen || isFocus ? 1 : 0}
                  _groupHover={{ opacity: 1 }}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Tooltip>
        <Menu
          matchWidth={matchWidth}
          isLazy
          gutter={1}
          isOpen={isOpen}
          onClose={onClose}
        >
          <Box as={MenuButton} w="full" bg="red" />
          <Portal>
            <MenuList minW="fit-content" py={0}>
              <MenuOptionGroup value={inputValue} type="radio">
                {options?.map((item, index) => (
                  <MenuItemOption
                    key={index}
                    hidden={item.hidden}
                    as={OptionButton}
                    value={item?.[optionsValueKey]}
                    size={size}
                    icon={<Check />}
                    iconSpacing={2}
                    onClick={() => {
                      handleSelect(index);
                      inputRef.current?.focus();
                    }}
                  >
                    {item.icon && <Box as="span" mr={2} children={item.icon} />}
                    {item?.[optionsLabelKey] || item?.[optionsValueKey]}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </VStack>
    );
  }
);

export default EditableSelect;
