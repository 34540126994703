/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { StrictMode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from '../store';

import theme from './theme';
import AppLayout from './layout';

const App = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <AppLayout />
        </ChakraProvider>
      </Provider>
    </StrictMode>
  );
};

export default App;
