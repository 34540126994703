/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { DateTime } from 'luxon';
import { AnimatePresence, motion } from 'framer-motion';
import { useInterval, VStack, Box, StyleProps, chakra } from '@chakra-ui/react';

import { useAnimationState, Animations } from './animation-types';
import { UIContentBlock, useComputedPixelValue } from '..';
import { TemplateBlockAppointments } from '../../../../../services/display-templates';
import { useGetAppointmentsQuery } from '../../../../../services/appointments';
import { useBranch } from '../../../../../hooks/branch';
import { Group, useGetGroupsQuery } from '../../../../../services/groups';
import { LoadingOverlay } from '../../..';
import useMockAppointments from './mock-appointments';

type Groups = Group[] | undefined;
export interface AppointmentsProps
  extends UIContentBlock<TemplateBlockAppointments> {}

export interface BlockAppointmentsConfig {
  options?: {
    /** How many minutes before scheduledAt we should display the appointment  */
    showBeforeMins?: number;
    /** Duration in minutes to keep showing the appointment after scheduledAt passed */
    showAfterMins?: number;
    // How often to refetch the appointments
    refetchInterval?: number;
    // Max number of appointments to display at a time
    displayMax?: number;
  };
  animation?:
    | string
    | {
        type?: Animations;
        // How often to paginate the appointments
        interval?: number;
      };
}

const MotionBox = chakra(motion.div);

const useBlockAppointments = (config: BlockAppointmentsConfig) => {
  const branch = useBranch();
  const { data: allGroups } = useGetGroupsQuery([branch?.id], {
    skip: !!branch?.groups,
  });
  const groupIds = React.useMemo(() => {
    const grps = (branch?.groups || allGroups?.data) as Groups;
    return grps?.map(({ id }) => id);
  }, [branch?.groups, allGroups?.data]);

  /** `default=60 minutes` duration in minutes to keep showing the appointment after scheduledAt passed */
  const showAfterMins = get(config, 'options.showAfterMins', 60);
  /** `default=60 minutes`  Max duration in minutes to show upcoming appointments. */
  const showBeforeMins = get(config, 'options.showBeforeMins', 60);
  /** `default=10000 milliseconds` re-fetch data frequency.*/
  const refetchInterval = get(config, 'options.refetchInterval', 10000);
  /** `default=10000 milliseconds`, frequency to automatically change appointments page  */
  const paginateInterval = get(config, 'options.paginateInterval', 10000);
  /** `default=100 appointments` max number of appointments to display at once */
  const maxPageSize = get(config, 'options.displayMax', 100);

  const [page, setPage] = React.useState(1);
  const { data: appointments, ...query } = useGetAppointmentsQuery(
    [
      { branchId: branch?.id },
      { page, pageSize: maxPageSize },
      {
        groups: groupIds,
        scheduledAt: {
          // we need to create a custom iso format to omit the seconds
          $gte: DateTime.local()
            .minus({ minutes: showAfterMins })
            .toFormat("yyyy-MM-dd'T'HH:mm"),
        },
      },
    ],
    {
      skip: !groupIds?.length,
      pollingInterval: refetchInterval,
    }
  );

  // // Auto paginate the appointments based on the interval
  useInterval(() => {
    const pageCount = get(appointments, 'meta.pagination.pageCount', 1);
    if (pageCount <= 1) return;
    setPage((prev) => (prev < pageCount ? prev + 1 : 1));
  }, paginateInterval);

  // Display XX minutes before scheduledAt
  // Display for XX min after scheduledAt
  return {
    data: get(appointments, 'data', []).filter((appt) => {
      const now = DateTime.now();
      const scheduledAt = DateTime.fromISO(appt.scheduledAt);
      const showTimeStart = scheduledAt.minus({ minutes: showBeforeMins });
      const showTimeEnd = scheduledAt.plus({ minutes: showAfterMins });
      // Check scheduledAt current is within the range of showBeforeMins and showAfterMins minutes
      return now >= showTimeStart && now <= showTimeEnd;
    }),
    ...appointments?.meta,
    ...query,
  };
};

/**
 * config options:
 * maxFontSize `default=48`
 * minFontSize `default=20`
 */
export const BlockContentAppointments = ({ block }: AppointmentsProps) => {
  const guid = React.useId();
  const internalRef = React.useRef<HTMLDivElement>(null);
  const prevWidth = React.useRef<number>(0);

  const config = get(block, 'config', {});
  const style = get(config, 'style', {}) as StyleProps;
  /** `default=48` */
  const maxFontSize: number = useComputedPixelValue(
    get(style, 'maxFontSize', '3rem')
  );
  /** `default=20` */
  const minFontSize: number = useComputedPixelValue(
    get(style, 'minFontSize', '1.25rem')
  );

  const animation = useAnimationState(config);
  const { data: appointments, isLoading } = useBlockAppointments(config);
  // const { data: appointments } = useMockAppointments(100);

  /* helper function to find the display status of siblings */
  const handleSiblingsDisplay = () => {
    if (!internalRef.current) return;
    const innerBox = internalRef.current;
    const innerList = innerBox?.firstElementChild as HTMLElement;

    innerList.style.width = 'fit-content';
    // remove the nearest offset parent until we fit
    let sibling = innerBox?.parentElement?.nextElementSibling;
    while (sibling instanceof HTMLElement) {
      const isHidden = sibling.style.display === 'none';

      if (isHidden && innerList.clientWidth < prevWidth.current) {
        sibling.style.removeProperty('display');
        handleTextFit();
        prevWidth.current = innerList.clientWidth;
      } else if (!isHidden && innerBox.scrollWidth > innerBox.clientWidth) {
        sibling.style.display = 'none';
        prevWidth.current = innerList.clientWidth;
        handleTextFit();
      }
      sibling = sibling?.nextElementSibling;
    }
    innerList.style.removeProperty('width');
  };

  /* helper function to unhide all siblings
   * we need to prevent bugs where the siblings are hidden
   */
  const handleUnhideSiblings = () => {
    if (!internalRef.current) return;
    const innerBox = internalRef.current;
    // remove the nearest offset parent until we fit
    let sibling = innerBox?.parentElement?.nextElementSibling;
    while (sibling instanceof HTMLElement) {
      if (sibling.style.display === 'none') {
        sibling.style.removeProperty('display');
      }
      sibling = sibling?.nextElementSibling;
    }
  };

  const handleHideOnEmpty = () => {
    // hide the parent if there are no appointments
    const parent = internalRef.current?.parentElement;
    if (parent && !appointments?.length)
      internalRef.current.parentElement.style.display = 'none';
    else if (parent)
      internalRef.current.parentElement.style.removeProperty('display');
  };

  const handleTextFit = () => {
    if (!internalRef.current || !appointments.length) return;

    const innerBox = internalRef.current;
    const innerList = innerBox?.firstElementChild as HTMLElement;
    const originalWidth = innerBox.clientWidth;

    let low = minFontSize,
      high = maxFontSize,
      size = minFontSize;

    // binary search to find the right font size
    while (low <= high) {
      innerList.style.width = 'fit-content';
      const mid = (high + low) >> 1;
      innerList.style.fontSize = mid + 'px';
      if (originalWidth <= innerList.clientWidth) {
        high = mid - 1;
      } else {
        size = mid;
        low = mid + 1;
      }
      innerList.style.removeProperty('width');
    }
    // size was the last one to fit
    if (parseInt(innerList.style.fontSize) != size) {
      innerList.style.fontSize = size + 'px';
    }
    // initial process to set the prevWidth
    if (!prevWidth.current) prevWidth.current = innerList.clientWidth;
    handleSiblingsDisplay();
  };

  React.useLayoutEffect(() => {
    handleUnhideSiblings();
    handleHideOnEmpty();
    handleTextFit();
    // we only need to start animating if there are appointments
    if (appointments.length) animation.init();
  }, [appointments]);

  const handleResize = React.useCallback(throttle(handleTextFit, 200), []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [handleResize]);

  return (
    <Box
      overflow="auto"
      pos="relative"
      display="flex"
      boxSize="full"
      alignItems="center"
      ref={internalRef}
      __css={{
        /* Hide scrollbar for Chrome, Safari and Opera */
        '&::-webkit-scrollbar': { display: 'none' },
        /* Hide scrollbar for IE, Edge and Firefox */
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <VStack
        color="white"
        px={4}
        {...style}
        position="absolute"
        alignItems="flex-start"
        alignContent="stretch"
        flexWrap="wrap"
        boxSize="full"
        spacing={0}
        as={MotionBox}
        animate={animation.listControls}
        variants={animation.listVariants}
      >
        {isLoading && <LoadingOverlay />}
        <AnimatePresence
          key={`${guid}${animation.isDisabled}`}
          mode="popLayout"
        >
          {appointments.map((apt, index) => (
            <MotionBox
              layout
              key={apt?.id || index}
              mr={`${style?.marginRight || '0.5rem'}!important`}
              whiteSpace="nowrap"
              children={apt?.displayName}
              opacity={1}
              variants={animation.listItemVariant}
              // if isAnimated the parent will animate the children, otherwise animate the children
              animate={animation.isDisabled ? 'animate' : undefined}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ type: 'spring' }}
            />
          ))}
        </AnimatePresence>
      </VStack>
    </Box>
  );
};

export default BlockContentAppointments;
