/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Folder as FolderIcon, MoreVert } from 'emotion-icons/material';
import {
  Box,
  Heading,
  Card,
  CardBody,
  Text,
  HStack,
  Checkbox,
  CardProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Icon,
} from '@chakra-ui/react';

import { Folder } from '.';
import { ItemSelection } from '..';

export interface FolderAction {
  label: string;
  icon?: React.ReactElement;
  onClick?: (folder: Folder) => void;
}

export interface FolderCardProps
  extends Folder,
    ItemSelection,
    Omit<CardProps, 'id'> {
  isSelectable?: boolean;
  isEditable?: boolean;
  actions?: FolderAction[];
  leftElement?: React.ReactNode;
}

const FolderCard = ({
  actions,
  color,
  isEditable = true,
  isSelectable,
  onClick,
  variant,
  leftElement,
  ...props
}: FolderCardProps) => (
  <Card variant={variant}>
    <CardBody as={HStack} p={2}>
      {isSelectable && (
        <Checkbox
          size="lg"
          borderColor={color}
          isDisabled={!props?.getCanSelect?.()}
          isChecked={props?.getIsSelected?.()}
          onChange={props?.getToggleSelectedHandler?.()}
        />
      )}
      <HStack flexGrow={1} role="button" onClick={onClick}>
        <Box color={color} pos="relative">
          <Icon
            as={FolderIcon}
            boxSize="2.5rem"
            color={(color as string) || 'var(--chakra-colors-blackAlpha-700)'}
          />
          {!!props?.assets?.length && (
            <Text
              top="50%"
              left="50%"
              pos="absolute"
              transform="translate(-50%, -50%);"
              color="white"
              children={props?.assets?.length || 0}
            />
          )}
        </Box>
        <Heading flexGrow={1} size="sm" noOfLines={1}>
          <Tooltip label={props?.name}>
            <Text>{props?.name}</Text>
          </Tooltip>
        </Heading>
      </HStack>
      {leftElement}
      {!!actions && isEditable && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Folder Options"
            variant="ghost"
            size="sm"
            icon={<MoreVert size="24" color={color as string} />}
          />
          <MenuList>
            {actions?.map(({ label, onClick, icon }) => (
              <MenuItem
                key={label}
                onClick={() => onClick?.(props)}
                children={label}
                icon={icon}
              />
            ))}
          </MenuList>
        </Menu>
      )}
    </CardBody>
  </Card>
);

export default FolderCard;
