/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { pick, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExpandMore,
  TextFields,
  Image,
  EventNote,
  Tag as TagIcon,
} from 'emotion-icons/material';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Portal,
} from '@chakra-ui/react';

import { ContentSource } from '../../../../services/display-templates';
import { UITemplateBlock } from '../../../../components/DisplayTemplate';
import { useDesignContext } from '..';
import {
  ContentSectionState,
  selectorSelectedContent,
  setSelectedContent,
} from '../../slice';

const ContentTypes: NonNullable<ContentSource>[] = [
  'none',
  'texts',
  'media',
  'appointments',
];

/** we can re-use this hook because the state is store in redux and not in the hook itself. */
export const useSelectionState = () => {
  const dispatch = useDispatch();
  const selected = useSelector(selectorSelectedContent);
  const onChange = React.useCallback((value: Partial<ContentSectionState>) => {
    dispatch(setSelectedContent(value));
  }, []);
  return [selected, onChange] as const;
};

export const ContentTypeMenu = () => {
  const { block, updateContent } = useDesignContext<UITemplateBlock>();
  const label = startCase(block?.source || 'none');
  const selectedIcon = React.useMemo(() => {
    switch (block?.source) {
      case 'appointments':
        return <EventNote />;
      case 'media':
        <Image />;
      case 'texts':
        return <TextFields />;
      default:
        return <TagIcon />;
    }
  }, [block?.source]);

  return (
    <>
      <Menu gutter={1} placement="bottom-end" isLazy>
        <MenuButton
          as={Button}
          size="sm"
          w="full"
          leftIcon={selectedIcon}
          rightIcon={<ExpandMore />}
          children={label}
          variant="outline"
          margin="0!important"
        />
        <Portal>
          <MenuList>
            <MenuOptionGroup
              type="radio"
              value={block?.source || 'none'}
              onChange={(source: ContentSource & any) =>
                updateContent?.({
                  ...block,
                  content: [],
                  config: {
                    style: pick(block?.config?.style, [
                      'width',
                      'height',
                      'flexGow',
                      'background',
                      'flexGrow',
                    ]) || { flexGrow: 1 },
                  },
                  content_filters: {},
                  source,
                })
              }
            >
              {ContentTypes.map((type) => (
                <MenuItemOption key={type} value={type}>
                  {startCase(type)}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
};

export * from './appointments';
export * from './fill';
export * from './layout';
export * from './texts';
export * from './transform';
