/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { get, map } from 'lodash';
import {
  Button,
  HStack,
  VStack,
  Spacer,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';
import {
  Appointment,
  useCreateAppointmentMutation,
  useUpdateAppointmentMutation,
} from '../../services/appointments';
import { DateTime } from 'luxon';
import { MultiSelect } from '../../components/ComboBox';
import { useBranch } from '../../hooks/branch';
import { useGetGroupsQuery, Group } from '../../services/groups';

interface AddEditFormProps {
  isOpen?: boolean;
  data?: Partial<Appointment>;
  onClose?: () => void;
}

const schema = yup
  .object({
    displayName: yup
      .string()
      .label('Full Name')
      .matches(
        /^[a-zA-Z ]*$/,
        'Full name must contain only alphabets and spaces'
      )
      .min(2, 'Full name must be at least 2 characters')
      .max(50, 'Full name must not exceed 50 characters')
      .required(),
    scheduledAt: yup
      .string()
      .label('Scheduled Date')
      .test('is-iso', '${path} must be a `date` type.', (value, context) =>
        value ? DateTime.fromISO(value).isValid : false
      )
      .transform((val) => DateTime.fromISO(val).toUTC().toISO())
      .required(),
    groups: yup
      .array()
      .label('Groups')
      .of(yup.object({ id: yup.string() }))
      .min(1)
      .required(),
  })
  .required();

type AppointmentData = yup.InferType<typeof schema>;

export const AddEditForm = ({
  isOpen = false,
  data,
  onClose = () => {},
}: AddEditFormProps) => {
  const formType = data?.id ? 'Edit' : 'Create';
  const branch = useBranch();
  const toast = useToast();
  const [createMore, setCreateMore] = React.useState(false);
  const { data: groups } = useGetGroupsQuery([branch?.id]);

  const [postAppointment, { isLoading: isCreating }] =
    useCreateAppointmentMutation();
  const [editAppointment, { isLoading: isUpdating }] =
    useUpdateAppointmentMutation();

  const getScheduledDate = (date?: string) =>
    (date
      ? DateTime.fromISO(date)
      : DateTime.local().plus({ minutes: 30 })
    ).toFormat("yyyy-MM-dd'T'HH:mm");

  const {
    control,
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<AppointmentData>({
    resolver: yupResolver(schema),
  });

  // reset the form when the data changes
  React.useEffect(() => {
    resetForm({
      ...data,
      groups: groups?.data?.slice(0, 1),
      scheduledAt: getScheduledDate(data?.scheduledAt),
    } as AppointmentData);
  }, [data]);

  const onSubmit = async (aptData: AppointmentData) => {
    const appointmentReq = data?.id ? editAppointment : postAppointment;
    try {
      await appointmentReq({
        ...aptData,
        id: data?.id as string,
        groups: aptData.groups?.map(({ id }) => ({ id })) as Group[],
      }).unwrap();

      // show success toast
      resetForm({
        displayName: '',
        groups: aptData?.groups,
        scheduledAt: getScheduledDate(data?.scheduledAt),
      });
      toast({
        title: `Appointment ${formType}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      if (!createMore || formType === 'Edit') onClose();
    } catch (error) {
      toast({
        title: `Error ${formType} Appointments.`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const isLoading = isCreating || isUpdating;
  return (
    <Modal
      isCentered
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{formType} Appointment</ModalHeader>
        <ModalBody>
          <VStack spacing={5}>
            <FormControl isRequired isInvalid={!!errors.displayName}>
              <FormLabel>Full Name</FormLabel>
              <Input {...register('displayName')} />
              <FormErrorMessage>{errors.displayName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.scheduledAt}>
              <FormLabel>Scheduled Date</FormLabel>
              <Input
                type="datetime-local"
                {...register('scheduledAt')}
                min={getScheduledDate()}
              />
              <FormErrorMessage>{errors.scheduledAt?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.groups}>
              <FormLabel>Select Groups</FormLabel>
              <Controller
                name="groups"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    labelKey="name"
                    data={get(groups, 'data', [])}
                    value={field.value as Group[]}
                    onChange={field.onChange}
                  />
                )}
              />
              <FormErrorMessage>{errors.groups?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter as={HStack} mt={4}>
          <FormControl
            display="flex"
            alignItems="center"
            hidden={formType === 'Edit'}
          >
            <FormLabel htmlFor="create-more" mb="0">
              Create More
            </FormLabel>
            <Switch
              isChecked={createMore}
              onChange={() => setCreateMore(!createMore)}
              size="sm"
            />
          </FormControl>
          <Spacer />
          <Button
            isDisabled={isLoading}
            variant="ghost"
            size="md"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            size="md"
            colorScheme="blue"
            mr={3}
            type="submit"
          >
            {formType}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditForm;
