/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import flatMap from 'lodash/flatMap';
import { motion, AnimatePresence } from 'framer-motion';
import {
  chakra,
  SystemStyleObject,
  Box,
  forwardRef,
  StyleProps,
} from '@chakra-ui/react';
import { BASE_API_URL } from '../../../../services';
import { ANIMATION_INTERVAL, UIContentBlock, useAutoPaginate } from '.';
import {
  TemplateBlockMedia,
  ContentSource,
} from '../../../../services/display-templates';

type MediaBlock = { type?: ContentSource; src?: string };
export interface MediaAnimationProps
  extends UIContentBlock<TemplateBlockMedia> {
  /** Disable auto animation frames */
  isAnimationDisabled?: boolean;
}

const animationTypes = {
  None: {
    initial: {},
    animate: {},
    exit: {},
  },
  Fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  Scale: {
    initial: { scale: 0.8, opacity: 0 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.15,
        ease: [0, 0, 0.2, 1],
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.1,
        ease: [0.4, 0, 1, 1],
      },
    },
  },
  Slide: {
    initial: { x: '100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: {
      x: '-100%',
      opacity: 0,
    },
  },
  Shake: {
    animate: {
      x: [0, -10, 10, -10, 10, 0],
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 0.5,
        loop: Infinity,
        ease: 'easeInOut',
      },
    },
  },
};

type AnimationType = keyof typeof animationTypes;

const SizeProps = ['boxSize', 'w', 'h', 'width', 'height'];
const LayoutProps = [
  'pos',
  'position',
  'maxW',
  'maxH',
  'minW',
  'minH',
  'align',
  'alignContent',
  'alignItems',
  'justifyContent',
  'zIndex',
];
const VideoExtension = ['.mp4', '.webm', '.ogg', '.ogv'];
const ImageExtension = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.svg',
  '.svgz',
  '.webp',
];

const MotionBox = chakra(motion.div);
const MotionImg = chakra(motion.img);
const MotionVideo = chakra(motion.video);

const MotionAsset = forwardRef(
  <T extends MediaBlock>({ type, ...props }: T, ref) => {
    if (!type) return null;
    if (ImageExtension.includes(type))
      return (
        <MotionImg
          ref={ref}
          objectFit="contain"
          maxH="100%"
          maxW="100%"
          {...props}
        />
      );
    if (VideoExtension.includes(type))
      return (
        <MotionVideo
          ref={ref}
          objectFit="cover"
          autoPlay
          loop
          muted
          {...props}
        />
      );
    return null;
  }
);

export const BlockContentMedia = ({
  block,
  isAnimationDisabled,
  sx,
}: MediaAnimationProps) => {
  const style: SystemStyleObject = get(block, 'config.style.asset', {});
  const animation = get(block, 'config.animation') as string;
  const animationType = get(animation, 'type', 'Fade') as AnimationType;
  const isAnimating = !!get(animation, 'isDisabled', isAnimationDisabled);
  const assets = React.useMemo(
    () => flatMap(get(block, 'content'), 'media'),
    [block?.content]
  );

  const [asset, frame] = useAutoPaginate(
    assets,
    get(animation, 'interval', ANIMATION_INTERVAL.Media),
    isAnimating
  );

  const src = asset?.url?.startsWith('http')
    ? asset?.url
    : `${BASE_API_URL}${asset?.url}`;

  const wrapperProps = React.useMemo(
    () =>
      pick({ ...sx, ...omit(style, 'width', 'height') }, [
        ...LayoutProps,
        ...SizeProps,
      ]),
    [sx]
  ) as StyleProps;
  const mediaProps = React.useMemo(
    () => omit({ ...sx, ...style }, LayoutProps),
    [sx]
  );

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      <MotionBox
        key={frame}
        pos="relative"
        boxSize="full"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={wrapperProps}
        {...get(animationTypes, animationType, animationTypes.Fade)}
      >
        <Box
          pos="absolute"
          type={asset?.ext}
          as={MotionAsset}
          src={src}
          alt={asset?.alternativeText}
          filter={
            style?.dropShadow
              ? 'drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2))'
              : undefined
          }
          sx={mediaProps}
        />
      </MotionBox>
    </AnimatePresence>
  );
};

export default BlockContentMedia;
