/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { createContext, useContext } from 'react';
import { HTMLChakraProps, Center, Spinner } from '@chakra-ui/react';
import find from 'lodash/find';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import set from 'lodash/set';
import merge from 'lodash/merge';

import TemplateBlock from './TemplateBlock';
import TemplateSection from './TemplateSection';
import {
  DisplayTemplate,
  TemplateSection as TemplateSectionType,
  TemplateBlock as TemplateBlockBase,
} from '../../services/display-templates';

export type UITemplateBlockType = 'section' | 'content' | 'template';
export type UITemplateBlock = TemplateBlockBase & {
  blockUID?: string;
};
export type UITemplateSection = Omit<TemplateSectionType, 'blocks'> & {
  id?: DisplayTemplate['id'];
  blockUID?: string;
  blocks?: UITemplateBlock[];
};
export type UIDisplayTemplate = Partial<Omit<DisplayTemplate, 'sections'>> & {
  id?: DisplayTemplate['id'];
  blockUID?: string;
  sections?: UITemplateSection[];
};
export type UITemplateBlockProps = {
  block?: UITemplateBlock;
  blockType?: UITemplateBlockType;
};

interface TemplateContextType {
  isLive: boolean;
  isLoading?: boolean;
  template?: UIDisplayTemplate;
  options?: {
    section?: (props?: UITemplateSection) => HTMLChakraProps<'div'>;
    content?: (props?: UITemplateBlock) => HTMLChakraProps<'div'>;
  };
  /** This blocks are only rendered by the UI, when `isLive=false` */
  blockOverlay?: React.FC<UITemplateBlockProps>;
}

interface TemplateProps extends TemplateContextType {
  children?: React.ReactNode;
}

export const TemplateContext = createContext<TemplateContextType>({
  isLive: false,
});

/** get the style property from context */
export const useCtxOptions = () => {
  const { options } = useContext(TemplateContext);
  return options;
};

const overlayProps = {
  content: '""',
  pos: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  boxSize: 'full',
  background: 'template.bg',
};

export const LoadingOverlay = () => (
  <Center pos="absolute" bg="black" boxSize="full" opacity={0.5} zIndex={10}>
    <Spinner color="white" size="xl" />
  </Center>
);

type UISortableBlock = UITemplateSection | UITemplateBlock;
/** sort sections or blocks based on the sortOrder porperty */
export const useUITemplateSort = <T extends UISortableBlock>(
  collection?: T[],
  sortPath = 'config.sortOrder',
  onChange?: (newOrder: T[]) => void
) => {
  const [_blocks, _setBlocks] = React.useState<T[]>([]);

  /** sort the blocks based on the sortPath property */
  const sortBlocks = React.useCallback(
    () =>
      _setBlocks(
        orderBy(collection, (item) =>
          get(item, sortPath, item.id || item.blockUID)
        )
      ),
    [collection, sortPath]
  );
  /** set the new order of the blocks */
  const setBlocksOrder = React.useCallback(
    (newOrder: T[]) => {
      const order = newOrder.map((block, sortOrder) => {
        const item = find(_blocks, pick(block, ['id', 'blockUID']));
        if (item) {
          const newSort = set({}, sortPath, sortOrder);
          return merge({}, item, newSort);
        }
        return block;
      }) as T[];
      _setBlocks(order);
      onChange?.(order);
    },
    [_blocks]
  );

  React.useEffect(() => {
    sortBlocks();
  }, [sortBlocks]);

  return [_blocks, setBlocksOrder] as const;
};

/**
 *
 * Section Config
 * sortOrder - default=id - sort order of the sections
 */
const DisplayTemplate = ({ children, ...props }: TemplateProps) => {
  const { template, isLoading } = props;
  const [sections] = useUITemplateSort(
    template?.sections,
    'properties.config.sortOrder'
  );
  return (
    <TemplateContext.Provider value={props}>
      {isLoading && <LoadingOverlay />}
      <TemplateBlock
        boxSize="full"
        pos="absolute"
        type="template"
        block={template?.properties}
        bg="template.bg"
        _before={{
          ...overlayProps,
          ...get(template, 'properties.config.style', {}),
        }}
        sx={{ overflow: 'hidden' }}
      >
        {sections?.map((section, index) => (
          <TemplateSection
            key={section?.id || section?.blockUID || index}
            {...section}
          />
        ))}
      </TemplateBlock>
    </TemplateContext.Provider>
  );
};

export default DisplayTemplate;
