/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useAuthContext } from '../../hooks/auth';
import { get } from 'lodash';
import {
  NavLink as RouterNavLink,
  useNavigate,
  Link as RouterLink,
} from 'react-router-dom';
import {
  HStack,
  Box,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Button,
  Divider,
  Avatar,
  Spacer,
  Text,
  MenuItemProps,
  ButtonProps,
  MenuDivider,
  BoxProps,
  useBreakpointValue,
  Icon,
} from '@chakra-ui/react';
import {
  ExpandMore,
  UnfoldMore,
  Logout,
  Person,
  HelpCenter,
  LocationCity,
  Add,
} from 'emotion-icons/material';
import { useDispatch, useSelector } from 'react-redux';
import { BranchLogo } from './logo';
import { selectNav } from '../../app/slice';
import { SideNavButton } from './sidenav';
import { useGetBranchesQuery } from '../../services/branch';
import { useBranch } from '../../hooks/branch';

type NavHeaderItemProps = { text: string; to?: string } & (
  | MenuItemProps
  | ButtonProps
);

const ProfileMenuButton = ({ text, ...props }: NavHeaderItemProps) => (
  <MenuItem as={RouterNavLink} {...props}>
    {text}
  </MenuItem>
);

const UserNavProfile = () => {
  const { user, logout, checkPermission } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Menu>
      <MenuButton
        aria-label="User Profile"
        textAlign="left"
        borderRadius={5}
        _hover={{ bg: 'blackAlpha.500' }}
      >
        <HStack spacing={4} p={1}>
          <Avatar
            borderRadius={5}
            name={`${user?.firstName} ${user?.lastName}`}
            // src="/static/images/avatar/2.jpg"
            // src="https://bit.ly/broken-link"
            boxSize={10}
          />
          <Box display={{ base: 'none', md: 'block' }}>
            <Text color="whiteAlpha.900" noOfLines={1} fontSize="sm">
              {`${user?.firstName} ${user?.lastName}`}
            </Text>
            <Text fontSize="sm" color="whiteAlpha.800">
              {user?.email}
            </Text>
          </Box>
          <ExpandMore color="white" />
        </HStack>
      </MenuButton>
      <MenuList>
        <ProfileMenuButton
          hidden // hide until we have a profile page
          icon={<Person />}
          text="Account"
          to="profile"
        />
        <ProfileMenuButton
          hidden={!checkPermission.isAuthorized}
          icon={<LocationCity />}
          text="Branch Settings"
          to="settings"
        />
        <ProfileMenuButton
          icon={<HelpCenter />}
          text="Help Center"
          command="?"
        />
        <MenuDivider />
        <ProfileMenuButton icon={<Logout />} text="Log Out" onClick={logout} />
      </MenuList>
    </Menu>
  );
};

export const LocationSelectMenu = (props: BoxProps) => {
  const { user } = useAuthContext();
  const branch = useBranch();
  const { data: branches } = useGetBranchesQuery(user?.id, {
    skip: !user?.id,
  });

  const colorScheme = useBreakpointValue(
    { base: 'gray', lg: 'blackAlpha' },
    { fallback: 'lg' }
  );

  return (
    <Box {...props}>
      <Menu>
        <MenuButton
          isDisabled={get(branches, 'data.length', 0) <= 1}
          size="md"
          colorScheme={colorScheme}
          as={Button}
          data-group
          rightIcon={
            <Icon as={UnfoldMore} _groupDisabled={{ display: 'none' }} />
          }
          _disabled={{ color: 'whiteAlpha.900', cursor: 'default' }}
        >
          {branch?.name || 'Select Location'}
        </MenuButton>
        <MenuList px={2}>
          {branches?.data?.map((branch) => (
            <MenuItem
              as={RouterLink}
              justifyContent="start"
              key={branch?.id}
              to={`/${branch?.slug}/appointments`}
            >
              {branch?.name}
            </MenuItem>
          ))}
          {/* Hidding until we can create a new location */}
          <MenuDivider hidden />
          <MenuItem as={HStack} hidden>
            <Add size={24} />
            <Text>New Location</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const MainAppBar = () => {
  const { variant } = useSelector(selectNav);
  return (
    <HStack
      bg="gray.900"
      minH="64px"
      px={5}
      pos="fixed"
      w="full"
      zIndex="sticky"
      as="header"
    >
      <HStack spacing={5}>
        <BranchLogo />
        <Divider orientation="vertical" h="36px" />
        <SideNavButton />
        {variant !== 'temporary' && (
          <LocationSelectMenu display={{ base: 'none', lg: 'block' }} />
        )}
      </HStack>
      <Spacer />
      <UserNavProfile />
    </HStack>
  );
};

export default MainAppBar;
