/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import throttle from 'lodash/throttle';
import isMatch from 'lodash/isMatch';

export type ElementSize = {
  width: number;
  height: number;
};
export type ElementPosition = {
  /** x coordinate relative to parent or viewport */
  clientX: number;
  /** x coordinate relative to parent or viewport */
  clientY: number;
};

export type ElementRect = ElementSize & ElementPosition;

/** track element resize and return a cleanup function to disconnect */
export const trackResize = (
  el: HTMLElement | null,
  cb: (entry?: ResizeObserverEntry[]) => void
) => {
  if (!el) return;
  const win = el.ownerDocument?.defaultView ?? window;
  const observer = new win.ResizeObserver(cb);
  observer.observe(el, { box: 'border-box' });
  return observer;
};

/** track element resize and return a cleanup function to disconnect
 * @param root root element to observe
 * @default root null - set document viewport as root
 */
const getElementPosition = (
  el?: HTMLElement | null,
  root?: HTMLElement | null
): ElementPosition | undefined => {
  if (!el) return;
  const parentRect = root?.getBoundingClientRect();
  const childRect = el.getBoundingClientRect();
  return {
    clientX: Math.floor(
      parentRect ? childRect.left - parentRect.left : childRect.left
    ),
    clientY: Math.floor(
      parentRect ? childRect.top - parentRect.top : childRect.top
    ),
  };
};

// Calculate height without padding.
export const innerHeight = (el: HTMLElement, style?: CSSStyleDeclaration) => {
  const innerStyle = style ?? window.getComputedStyle(el, null);
  // Hidden iframe in Firefox returns null, https://github.com/malte-wessel/react-textfit/pull/34
  if (!innerStyle) return el.clientHeight;
  return (
    el.clientHeight -
    parseInt(innerStyle.getPropertyValue('padding-top'), 10) -
    parseInt(innerStyle.getPropertyValue('padding-bottom'), 10)
  );
};

// Calculate width without padding.
export function innerWidth(el: HTMLElement, style?: CSSStyleDeclaration) {
  const innerStyle = style ?? window.getComputedStyle(el, null);
  // Hidden iframe in Firefox returns null, https://github.com/malte-wessel/react-textfit/pull/34
  if (!innerStyle) return el.clientWidth;
  return (
    el.clientWidth -
    parseInt(innerStyle.getPropertyValue('padding-left'), 10) -
    parseInt(innerStyle.getPropertyValue('padding-right'), 10)
  );
}

export const useSize = <T extends HTMLElement | null | undefined>(
  ref: React.RefObject<T>
) => {
  const [isPending, startTransition] = React.useTransition();
  const [size, setSize] = React.useState<ElementSize>({
    width: 0,
    height: 0,
  });

  const handleOnResize = React.useCallback(() => {
    if (!ref.current) return;
    const newSize = {
      width: innerWidth(ref.current),
      height: innerHeight(ref.current),
    };
    startTransition(() => setSize(newSize));
  }, [isPending]);

  React.useEffect(() => {
    if (!ref.current) return;
    const observer = trackResize(ref.current, handleOnResize);
    return () => {
      observer?.disconnect();
    };
  }, [ref]);

  return size;
};
/**
 * React hook to measure a component's dimensions
 *
 * @param ref ref of the component to measure
 * @param root get x,y coordinates relative to this element
 *
 */
export const useDimensions = <T extends HTMLElement | null | undefined>(
  ref: React.RefObject<T>,
  root?: React.RefObject<T>
) => {
  const [isPending, startTransition] = React.useTransition();
  const [dimensions, _setDimensions] = React.useState<ElementRect>({
    width: 0,
    height: 0,
    clientX: 0,
    clientY: 0,
  });
  const requestRef = React.useRef<number>(0);
  const pos = React.useRef<ElementPosition>({
    clientX: 0,
    clientY: 0,
  });
  const size = React.useRef<ElementSize>({
    width: 0,
    height: 0,
  });

  /** Update dimensions using refs */
  const updateDimensions = React.useCallback(
    (rect: Partial<ElementRect>) =>
      startTransition(() => {
        const prev = { ...size.current, ...pos.current };
        _setDimensions({
          ...prev,
          ...rect,
        });
      }),
    [isPending, startTransition]
  );

  const handleDimensionsChange = React.useCallback(
    throttle(() => {
      const position = getElementPosition(ref.current, root?.current);
      if (position && !isMatch(position, pos.current)) {
        pos.current = position;
        updateDimensions(position);
      }
    }, 100),
    []
  );
  const handleOnResize = React.useCallback(() => {
    if (!ref.current) return;
    const newSize = {
      width: innerWidth(ref.current),
      height: innerHeight(ref.current),
    };
    if (!isMatch(newSize, size.current)) {
      size.current = newSize;
      updateDimensions(newSize);
    }
  }, []);

  const animate = () => {
    handleDimensionsChange();
    // The 'state' will always be the initial value here
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    if (!ref.current) return;
    const observer = trackResize(ref.current, handleOnResize);
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      observer?.disconnect();
      cancelAnimationFrame(requestRef.current);
    };
  }, [ref.current, root?.current]);

  return dimensions;
};
