/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { uniqueId } from 'lodash';
import { RawAsset, AssetType } from './services.d';

export const typeFromMime = (mime) => {
  if (mime.includes(AssetType.IMAGE)) {
    return AssetType.IMAGE;
  }
  if (mime.includes(AssetType.AUDIO)) {
    return AssetType.AUDIO;
  }
  if (mime.includes(AssetType.AUDIO)) {
    return AssetType.AUDIO;
  }

  return AssetType.DOCUMENT;
};

export const rawFileToAsset = (
  rawFile: File,
  assetSource: string
): RawAsset => ({
  id: uniqueId('tempAsset_'),
  size: rawFile.size / 1000,
  createdAt: new Date(rawFile.lastModified).toISOString(),
  name: rawFile.name,
  source: assetSource,
  type: typeFromMime(rawFile.type),
  url: URL.createObjectURL(rawFile),
  ext: rawFile.name.split('.').pop(),
  mime: rawFile.type,
  rawFile,
  isLocal: true,
});

/**
 * Transforms an array of plural type to singular one
 * @param types Array of string (plural types)
 * @returns Array of string (singular types)
 */
const toSingularTypes = (types: string[]) => {
  if (!types) {
    return [];
  }
  return types.map((type) => type.substring(0, type.length - 1));
};

/**
 * Returns the files that can be added to the media field
 * @param pluralTypes Array of string (allowedTypes)
 * @param files Array of files
 * @returns Array of files that match the allowed types
 */
export const getAllowedFiles = (pluralTypes: string[], files: RawAsset[]) => {
  const singularTypes = toSingularTypes(pluralTypes);
  const allowedFiles = files.filter((file) => {
    const fileType = file.mime.split('/')[0];
    return (
      singularTypes.includes('file') &&
      (['video', 'image', 'audio'].includes(fileType) ||
        singularTypes.includes(fileType))
    );
  });
  return allowedFiles;
};
