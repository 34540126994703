/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { DateTime } from 'luxon';
import { get, startCase } from 'lodash';
import { Star, Delete, GroupAdd } from 'emotion-icons/material';
import {
  Button,
  HStack,
  IconButton,
  Spacer,
  useToast,
  Text,
  Tooltip,
  Box,
  Circle,
  Avatar,
  Select,
  SelectProps,
} from '@chakra-ui/react';

import { useIsMe } from '../../hooks/auth';
import { useBranch } from '../../hooks/branch';
import { useGetRolesQuery, useUpdateUserMutation } from '../../services/auth';
import {
  useDeleteBranchUserMutation,
  useGetBranchUsersQuery,
} from '../../services/branch';
import Page, { PageContainer, PageHeading } from '../../components/Page';
import DataTable from '../../components/DataTable';
import InviteUser from './InviteUser';
import CreateUser from './CreateUser';

// TOOD: replace this in the new auth system, this is temporary until we have a proper permissions system
export const RolesList = React.forwardRef((props: SelectProps, ref) => {
  const { data: roles } = useGetRolesQuery();
  return (
    <Select ref={ref} w="fit-content" {...props} placeholder="Select Role">
      {roles?.map(({ label, id }) => (
        <option key={id} value={id}>
          {startCase(label)}
        </option>
      ))}
    </Select>
  );
});

const BranchUsers = () => {
  const toast = useToast();
  const branch = useBranch();
  const {
    data: users,
    isLoading: isLoadingData,
    isFetching: isFetchingUsers,
  } = useGetBranchUsersQuery([branch?.id]);

  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeleting }] = useDeleteBranchUserMutation();

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: 'Name',
        cell: (info) => {
          const isMemberMe = useIsMe(get(info, 'row.original'));
          return (
            <HStack spacing="1rem">
              <Tooltip label="Me">
                <Box w="20px">{isMemberMe ? <Star /> : null}</Box>
              </Tooltip>
              <Avatar
                name={get(info, 'row.original.fullName')}
                src={get(info, 'row.original.avatarUrl')}
              />
              <Box>
                <Text>{info.getValue()}</Text>
                <HStack>
                  <Text fontSize="sm" color="neutral.700">
                    {get(info, 'row.original.email')}
                  </Text>
                </HStack>
              </Box>
            </HStack>
          );
        },
      },
      {
        header: 'Member Since',
        accessorKey: 'createdAt',
        cell: (info) => {
          const createdAt = DateTime.fromISO(info.getValue());
          const isActive = !get(info, 'row.original.blocked');
          return (
            <HStack>
              <Circle bg={isActive ? 'green.400' : 'gray.400'} size="0.7rem" />
              <Text>{createdAt.toRelative()}</Text>
            </HStack>
          );
        },
      },
      {
        header: 'Role ID',
        accessorKey: 'role.id',
        cell: (info) => {
          const isMemberMe = useIsMe(get(info, 'row.original'));
          return (
            <RolesList
              isDisabled={isMemberMe}
              value={get(info, 'row.original.role.id')}
              onChange={async (e) => {
                const id = parseInt(e.target.value);
                const userId = get(info, 'row.original.id');
                const email = get(info, 'row.original.email');
                try {
                  await updateUser({
                    id: userId,
                    role: {
                      disconnect: [],
                      connect: [{ id, position: { end: true } }],
                    },
                  }).unwrap();
                  toast({
                    title: `User Role ${email} updated`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                  });
                } catch (error) {
                  toast({
                    title: 'Error updating member role',
                    description: get(
                      error,
                      'data.error.message',
                      error?.message
                    ),
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                  });
                }
              }}
            />
          );
        },
      },
      {
        id: 'actions',
        cell: (info) => {
          const isMemberMe = useIsMe(get(info, 'row.original'));
          const { openConfirmDialog } = info.table?.options;
          if (isMemberMe) return null;
          return (
            <IconButton
              aria-label="delete-branch"
              variant="ghost"
              icon={<Delete width={24} />}
              onClick={() =>
                openConfirmDialog?.({
                  type: 'delete',
                  title: `Delete ${info.row.getValue('fullName')}`,
                  description: 'Are you sure you want to delete this user?',
                  labels: { submit: 'Delete' },
                  onSubmit: () =>
                    deleteUser({
                      userId: info?.row?.original?.id,
                      branchId: branch?.id,
                    }),
                })
              }
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Page>
      <HStack>
        <PageHeading>Users</PageHeading>
        <Spacer />
        <InviteUser />
        <CreateUser />
      </HStack>
      <PageContainer>
        <DataTable
          isLoading={isLoadingData}
          inProgress={isFetchingUsers}
          columns={columns}
          data={users?.data}
        />
      </PageContainer>
    </Page>
  );
};

export default BranchUsers;
