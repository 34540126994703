/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import { concat, get } from 'lodash';

import baseApi, {
  ListResponse,
  PaginationRequest,
  StrapiId,
  StrapiRelationUpdate,
  getListModel,
} from '.';
import { Group } from './groups';
import { Branch } from './branch';

export interface ScreenDetails {
  aspectRatio: '4:3' | '16:9' | '5:4' | '21:9' | (string & {});
  height: number;
  width: number;
}

export interface BrowserDetails {
  browserName: string;
  browserMajorVersion: string;
  browserFullVersion: string;
  // Operating system name. Examples: 'Mac OS X', 'Android'.
  os: string;
  osVersion: string;
  // Device. For desktop/laptop devices, the value will be "Other". Example: 'Samsung SM-J330F'.
  device: string;
  userAgent: string;
}

export interface Display {
  id: StrapiId;
  displayId: string;
  groups: Group[] | StrapiRelationUpdate;
  createdAt: string;
  lastUsed: string;
  updatedAt: string;
  category:
    | 'other'
    | 'desktop'
    | 'pc'
    | 'laptop'
    | 'tv'
    | 'smarttv'
    | 'usb'
    | 'usbstick'
    | 'tvstick'
    | 'tvbox';
  /** For schema information go to: https://dev.fingerprint.com/docs/js-agent */
  device: {
    id?: string;
    browserDetails: BrowserDetails;
    firstSeenAt: string;
    ip: string;
    ipLocation: object & {
      ip: string;
      loc: string;
      org: string;
      city: string;
      postal: string;
      region: string;
      country: string;
      timezone: string;
    };
    name: string;
  };
  screen: ScreenDetails;
}

type DisplayRequest = [Branch['id'], PaginationRequest?, Object?];
type PutDisplayRequest = Required<Pick<Display, 'id'>> & Partial<Display>;
type LinkDisplayRequest = {
  code: string;
  displayName?: string;
  groups?: Pick<Group, 'id'>[] | StrapiRelationUpdate;
};

const displayApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDisplays: builder.query<ListResponse<Display>, DisplayRequest>({
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.data.map((apt) => ({
                type: 'Displays' as const,
                id: get(apt, 'id'),
              })),
              { type: 'Displays', id: 'LIST' },
            ]
          : [{ type: 'Displays', id: 'LIST' }];
      },
      query: ([branchId, pageMeta, filters = {}]) => {
        const query = {
          filters: {
            ...filters,
            $and: [
              { groups: { branch: { id: branchId } } },
              ...get(filters, '$and', []),
            ],
          },
          populate: {
            device: '*',
            screen: '*',
            groups: { fields: ['id', 'uid', 'name'] },
          },
          pagination: get(pageMeta, 'page')
            ? pageMeta
            : { page: pageMeta, pageSize: 100 },
        };
        return `/displays?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => ({
        data: getListModel(get(res, 'data', []), {
          groups: (_, group) => getListModel(get(group, 'data', [])),
        }),
        meta: get(res, 'meta', {}),
        original: get(res, 'data'),
      }),
    }),
    updateDisplay: builder.mutation<any, PutDisplayRequest>({
      // Invalidates all queries that subscribe to Display id only.
      invalidatesTags: (result, error, { id }) => [{ type: 'Displays', id }],
      query({ id, ...data }) {
        return {
          url: `/displays/${id}`,
          method: 'PUT',
          body: { data },
        };
      },
    }),
    deleteDisplay: builder.mutation<any, Display['id']>({
      // Invalidates all queries that subscribe to Displays id only.
      invalidatesTags: (result, error, id) => [{ type: 'Displays', id }],
      query(id) {
        return {
          url: `/displays/${id}`,
          method: 'DELETE',
        };
      },
    }),
    linkDisplay: builder.mutation<any, LinkDisplayRequest>({
      // invalidates the list of Displays
      invalidatesTags: [{ type: 'Displays', id: 'LIST' }],
      query(data) {
        return {
          url: `/display/link`,
          method: 'POST',
          body: { data },
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useDeleteDisplayMutation,
  useGetDisplaysQuery,
  useLinkDisplayMutation,
  useUpdateDisplayMutation,
} = displayApi;

export default displayApi;
