/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { Dictionary } from 'lodash';

export type StrapiId = string | number;

export enum AssetType {
  VIDEO = 'video',
  IMAGE = 'image',
  DOCUMENT = 'doc',
  AUDIO = 'audio',
}

export enum AssetSource {
  URL = 'url',
  COMPUTER = 'computer',
}

type ResponseIterator<TResult, D extends object> = {
  [key in keyof TResult]?:
    | string
    | string[]
    | TResult[key]
    | ((v: D) => TResult[key])
    | ((v: D, k: D[keyof D]) => TResult[key]);
};

export type StrapiRelationOrder = {
  before?: StrapiId;
  after?: StrapiId;
  start?: boolean;
  end?: boolean;
};
export type StrapiRelations =
  | Array<{ id: StrapiId; position?: StrapiRelationOrder }>
  | StrapiId[];
export interface StrapiRelationUpdate {
  disconnect?: StrapiRelations;
  connect?: StrapiRelations;
}

export type PaginationRequest =
  | Pick<PaginationMeta, 'page' | 'pageSize'>
  | PaginationMeta['page'];

export interface PaginationMeta {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

export interface TextAsset {
  id: string | number;
  content: string;
}

export interface Asset {
  id: StrapiId;
  createdAt?: string;
  ext?: string;
  hash?: string;
  height?: string | number;
  isLocal?: boolean;
  mime: string;
  name: string;
  path?: string;
  hostname?: string;
  size: number;
  source?: string;
  type?: AssetType;
  url: string;
  width?: string | number;
  uploadProgress?: number;
  alternativeText?: string;
}
export interface RawAsset extends Asset {
  rawFile: File;
}

export interface RawData<T extends object> {
  id?: string | number;
  attributes?: T;
}

export interface RawListResponse<T extends RawData<T>> {
  data: T[];
  meta: { pagination?: PaginationMeta };
}

export interface ListResponse<T extends object> {
  data: T[];
  dataByKey?: [Dictionary<T>, keyof T];
  meta: RawListResponse<T>['meta'];
  original: any;
}
export interface AssetResponse extends Asset {
  caption?: string;
  formats?: { thumbnail?: Asset; small?: Asset; medium?: Asset; large?: Asset };
  previewUrl?: string;
  provider_metadata?: null;
  provider?: string;
  createdAt?: string;
  updatedAt?: string;
  groupId?: StrapiId;
  groupName?: string;
  groupAttributes?: {
    group?: string;
    createdAt?: string;
    updatedAt?: string;
  };
}
