import React from 'react';
import { Navigate } from 'react-router-dom';
import { useBranch } from '../../hooks/branch';

// redirect to the branch context display page
export const NavigateToLinkDisplay = () => {
  const branch = useBranch();
  return <Navigate to={`/${branch?.slug}/displays?link=true`} replace />;
};

export default NavigateToLinkDisplay;
