/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { get, startCase } from 'lodash';
import {
  HStack,
  ButtonGroup,
  IconButton,
  Icon,
  Spacer,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatItalic,
  FormatUnderlined,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from 'emotion-icons/material';

import { EditableSelect } from '../../../../../components/ComboBox';
import { useComputedPixelValue } from '../../../../../components/DisplayTemplate/TemplateBlock/BlockContent';
import { useDebounceCallback } from '../../../hooks';
import { useDesignContext } from '../../';
import { AnimationControls } from '../animation';

const FONT_WEIGHTS = ['normal', 'medium', 'semibold', 'bold'];
const FONT_WEIGHT_OPTIONS = FONT_WEIGHTS.map((val) => ({
  value: startCase(val),
}));
// list of standard supported fontSizes
const fontSizes = [
  10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128,
];
const sizeOptions = fontSizes.map((val) => ({ value: `${val}` }));
const MIN_FONT_SIZE = 10;
const MAX_FONT_SIZE = 200;

const useBlockFontSize = (fontSizeProp: string) => {
  const remValue = useComputedPixelValue('1rem');

  const baseFontSize = React.useMemo(
    () => parseFloat(fontSizeProp),
    [fontSizeProp, remValue]
  );

  const fontSize = React.useMemo(
    () => Math.floor(baseFontSize * remValue),
    [baseFontSize]
  );

  const standardOption = React.useMemo(() => {
    const index = fontSizes.indexOf(fontSize);
    return index > -1 ? index + 1 : 0;
  }, [fontSize, fontSizeProp]);

  const getRemValue = React.useCallback(
    (value?: string) => {
      const size = Number(value);
      if (size < MIN_FONT_SIZE || size > MAX_FONT_SIZE) return undefined;
      return `${size / remValue}rem`;
    },
    [remValue]
  );

  return { fontSize, standardOption, getRemValue };
};

export const TextFontWeightOption = () => {
  const { config, updateConfig } = useDesignContext();
  const fontWeight = get(config, 'style.fontWeight', 'normal');
  return (
    <EditableSelect
      w="full"
      paddingLeft={2}
      matchWidth
      tooltip="Font Style"
      defaultOption={FONT_WEIGHTS.indexOf(fontWeight as string)}
      onOptionChange={(w) =>
        updateConfig?.({ style: { fontWeight: w?.toLocaleLowerCase() } })
      }
      options={FONT_WEIGHT_OPTIONS}
    />
  );
};

export const TextFontStyleOption = () => {
  const { config, updateConfig } = useDesignContext();

  const fontItalic = get(config, 'style.fontStyle');
  const fontUnderline = get(config, 'style.textDecoration');

  const handleToggleItalic = React.useCallback(() => {
    if (fontItalic === 'italic') updateConfig?.({}, [`style.fontStyle`]);
    else updateConfig?.({ style: { fontStyle: 'italic' } });
  }, [fontItalic]);

  const handleToggleUnderline = React.useCallback(() => {
    if (fontUnderline === 'underline')
      updateConfig?.({}, [`style.textDecoration`]);
    else updateConfig?.({ style: { textDecoration: 'underline' } });
  }, [fontUnderline]);

  return (
    <ButtonGroup size="xs" isAttached variant="unstyled" color="gray.500">
      <Tooltip label="Font Italic">
        <IconButton
          w="inherit"
          aria-label="Italic"
          icon={<Icon as={FormatItalic} />}
          isActive={fontItalic === 'italic'}
          _active={{ color: 'gray.900' }}
          onClick={handleToggleItalic}
        />
      </Tooltip>
      <Tooltip label="Font Underline">
        <IconButton
          w="inherit"
          aria-label="Underline"
          icon={<Icon as={FormatUnderlined} />}
          isActive={fontUnderline === 'underline'}
          _active={{ color: 'gray.900' }}
          onClick={handleToggleUnderline}
        />
      </Tooltip>
    </ButtonGroup>
  );
};

export const TextsFontOptions = ({
  onFontSizeChange: onFontSizeChangeProp,
}: {
  onFontSizeChange?: (value?: string) => void;
}) => {
  const { config, content } = useDesignContext();
  const styleFontSize = get(config, 'style.fontSize', '3rem') as string;
  const { fontSize, standardOption, getRemValue } =
    useBlockFontSize(styleFontSize);

  const onFontSizeChange = useDebounceCallback(
    (value?: string) => {
      const size = getRemValue(value);
      if (size && size !== styleFontSize) {
        onFontSizeChangeProp?.(size);
      }
    },
    [onFontSizeChangeProp, getRemValue, styleFontSize]
  );

  return (
    <HStack>
      <TextFontWeightOption />
      <TextFontStyleOption />
      <EditableSelect
        w="7rem"
        paddingRight={0}
        tooltip="Maximum Font Size"
        defaultOption={standardOption}
        onOptionChange={onFontSizeChange}
        options={[
          {
            label: 'Custom',
            isEditable: true,
            min: MIN_FONT_SIZE,
            max: MAX_FONT_SIZE,
            defaultValue: fontSize?.toString(),
            type: 'number',
          },
          ...sizeOptions,
        ]}
      />
    </HStack>
  );
};

export const TextAlignOptions = () => {
  const { updateConfig, config } = useDesignContext();
  const handleUpdateAlign = React.useCallback(
    (align?: 'start' | 'center' | 'end') => {
      const isActive = get(config, 'style.justifyContent') === align;
      if (isActive) updateConfig?.({}, [`style.justifyContent`]);
      else updateConfig?.({ style: { justifyContent: align } });
    },
    [config?.style]
  );
  const handleUpdateTextAlign = React.useCallback(
    (align?: 'start' | 'center' | 'end') => {
      const isActive = get(config, 'style.textAlign') === align;
      if (isActive) updateConfig?.({}, [`style.textAlign`]);
      else updateConfig?.({ style: { textAlign: align } });
    },
    [config?.style]
  );
  return (
    <HStack spacing={1}>
      <ButtonGroup size="sm" isAttached variant="outline">
        <IconButton
          w="inherit"
          aria-label="Text align left"
          icon={<Icon as={FormatAlignLeft} />}
          isActive={get(config, 'style.textAlign') === 'start'}
          onClick={() => handleUpdateTextAlign('start')}
        />
        <IconButton
          w="inherit"
          aria-label="Text align center"
          icon={<Icon as={FormatAlignCenter} />}
          isActive={get(config, 'style.textAlign') === 'center'}
          onClick={() => handleUpdateTextAlign('center')}
        />
        <IconButton
          w="inherit"
          aria-label="Text align right"
          icon={<Icon as={FormatAlignRight} />}
          isActive={get(config, 'style.textAlign') === 'end'}
          onClick={() => handleUpdateTextAlign('end')}
        />
      </ButtonGroup>
      <Spacer />
      <ButtonGroup size="sm" isAttached variant="outline">
        <IconButton
          w="inherit"
          aria-label="Align top"
          icon={<Icon as={VerticalAlignTop} />}
          isActive={get(config, 'style.justifyContent') === 'start'}
          onClick={() => handleUpdateAlign('start')}
        />
        <IconButton
          w="inherit"
          aria-label="Align middle"
          icon={<Icon as={VerticalAlignCenter} />}
          isActive={get(config, 'style.justifyContent') === 'center'}
          onClick={() => handleUpdateAlign('center')}
        />
        <IconButton
          w="inherit"
          aria-label="Align bottom"
          icon={<Icon as={VerticalAlignBottom} />}
          isActive={get(config, 'style.justifyContent') === 'end'}
          onClick={() => handleUpdateAlign('end')}
        />
      </ButtonGroup>
    </HStack>
  );
};

export const TextsControls = () => {
  const { updateConfig } = useDesignContext();
  return (
    <VStack>
      <TextsFontOptions
        onFontSizeChange={(fontSize) => updateConfig?.({ style: { fontSize } })}
      />
      <AnimationControls />
    </VStack>
  );
};

export const AppointmentsTextControls = () => {
  const { config, updateConfig } = useDesignContext();
  /** `default=48` */
  const maxFontSize: string = get(config, 'style.maxFontSize', '3rem');
  /** `default=20` */
  const minFontSize: string = get(config, 'style.minFontSize', '1rem');

  // 3rem which is the default maxFontSize for the appointments component
  const {
    fontSize: maxFontSizePx,
    standardOption: maxDefaultOption,
    getRemValue,
  } = useBlockFontSize(maxFontSize);

  // 1.3rem which is the default minFontSize for the appointments component
  const { fontSize: minFontSizePx, standardOption: minDefaultOption } =
    useBlockFontSize(minFontSize);

  const handleMaxOnChange = useDebounceCallback(
    (value?: string) => {
      const size = getRemValue(value);
      if (size && size !== maxFontSize) {
        updateConfig?.({ style: { maxFontSize: size } });
      }
    },
    [getRemValue, maxFontSize]
  );

  const handleMinOnChange = useDebounceCallback(
    (value?: string) => {
      if (minFontSizePx == Number(value)) return;
      const size = getRemValue(value);
      if (size && size !== minFontSize) {
        updateConfig?.({ style: { minFontSize: size } });
      }
    },
    [getRemValue, minFontSizePx, maxFontSize]
  );

  React.useEffect(() => {
    if (maxFontSizePx < minFontSizePx) {
      updateConfig?.({ style: { maxFontSize: minFontSize } });
    }
  }, [maxFontSizePx, minFontSizePx, updateConfig]);

  return (
    <>
      <HStack>
        <TextFontWeightOption />
        <TextFontStyleOption />
      </HStack>
      <HStack w="full" spacing={6}>
        <EditableSelect
          paddingLeft={2}
          tooltip="Minimum Font Size"
          defaultOption={minDefaultOption}
          onOptionChange={handleMinOnChange}
          options={[
            {
              label: `Max: ${maxFontSizePx}`,
              value: maxFontSizePx?.toString(),
            },
            ...sizeOptions?.filter((s) => Number(s.value) <= maxFontSizePx),
          ]}
        />
        <EditableSelect
          tooltip="Maximum Font Size"
          defaultOption={maxDefaultOption}
          onOptionChange={handleMaxOnChange}
          options={[
            {
              label: 'Custom',
              isEditable: true,
              min: MIN_FONT_SIZE,
              max: MAX_FONT_SIZE,
              defaultValue: maxFontSizePx?.toString(),
              type: 'number',
            },
            ...sizeOptions,
          ]}
        />
      </HStack>
      <AnimationControls />
    </>
  );
};

export default TextsControls;
