/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { SystemStyleObject, useInterval } from '@chakra-ui/react';
import { TemplateBlock } from '../../../../services/display-templates';

export enum ANIMATION_INTERVAL {
  Texts = 2000,
  Media = 5000,
  Appointments = 10000,
  None = Infinity,
}

export interface UIContentBlock<T extends TemplateBlock> {
  block?: T;
  sx?: SystemStyleObject;
}

/* hook to auto paginate */
export const useAutoPaginate = <T,>(
  assets: T[],
  interval = 5000,
  stop?: boolean
) => {
  const [frame, setFrame] = React.useState(0);
  const [index, setIndex] = React.useState(0);
  const paginate = () => {
    if (stop) return;
    let randomIndex = Math.floor(Math.random() * assets.length);
    if (assets.length <= 1) setFrame(Math.random());
    else if (assets.length > 1) {
      setIndex(randomIndex);
      setFrame(randomIndex);
    }
  };
  useInterval(paginate, interval);
  // reset index and frame when assets change
  React.useEffect(() => {
    if (index >= assets.length) setIndex(0);
  }, [assets.length]);

  return [assets?.[index], frame] as const;
};

/** useComputedPixelValue function is used to convert a css value to pixels
 * This is optimized to be used as hook.
 */
export const useComputedPixelValue = (cssValue: string) => {
  return React.useMemo(() => {
    const numericValue = parseFloat(cssValue);
    const unit = cssValue.match(/[a-zA-Z]+/)?.[0];

    // handle simple cases early
    if (isNaN(numericValue)) return 0;
    else if ((!unit || unit == 'px') && isFinite(numericValue))
      return numericValue;

    const tempElement = document.createElement('div');
    tempElement.style.width = `1${unit}`;
    document.body.appendChild(tempElement);

    const computedStyle = window.getComputedStyle(tempElement);
    const pixelValue = parseFloat(computedStyle.width);

    document.body.removeChild(tempElement);

    return pixelValue * numericValue;
  }, [cssValue]);
};

export * from './Media';
export * from './Texts';
export * from './Appointments';
export * from './Custom';
