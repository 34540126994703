/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { NavigateNext, NavigateBefore } from 'emotion-icons/material';
import {
  chakra,
  Box,
  Text,
  Spacer,
  Button,
  IconButton,
  HStack,
  useTheme,
  forwardRef,
  VStack,
  List,
  Select,
  StackProps,
  Divider,
  useControllableState,
} from '@chakra-ui/react';

import { PaginationMeta } from '../../../services';

export interface PaginationProps extends Partial<PaginationMeta> {
  defaultPageSize?: number;
  defaultPage?: number;
  pageSizes?: number[];
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
}

const Pagination = ({
  page: pageProp = 1,
  pageCount = 1,
  defaultPageSize = 10,
  defaultPage = 1,
  onPageSizeChange,
  onPageChange,
  pageSize: pageSizeProp,
  pageSizes = [10, 20, 40, 50],
}: PaginationProps) => {
  const [pageSize, setPageSize] = useControllableState<number>({
    value: pageSizeProp,
    onChange: onPageSizeChange,
    defaultValue: defaultPageSize,
  });
  const [page, setPage] = useControllableState<number>({
    value: pageProp,
    onChange: onPageChange,
    defaultValue: defaultPage,
  });

  const paginateTo = (page: number) => {
    if (page < 1 || page > pageCount) return;
    setPage(page);
  };

  return (
    <HStack p={4} borderTop="1px" borderColor="blackAlpha.100">
      <HStack spacing={4}>
        <Select
          value={pageSize}
          w="5rem"
          onChange={(e) => {
            setPageSize?.(parseInt(e.target.value));
          }}
        >
          {pageSizes.map((size, indx) => (
            <option key={indx} value={size}>
              {size}
            </option>
          ))}
        </Select>
        <Divider orientation="vertical" h="36px" />
        <Text>
          Page {page} of {pageCount}
        </Text>
      </HStack>
      <Spacer />
      <HStack>
        <IconButton
          icon={<NavigateBefore size={32} />}
          aria-label="Pagination Prev"
          variant="ghost"
          onClick={() => paginateTo(page - 1)}
          isDisabled={page === 1}
        />
        <IconButton
          icon={<NavigateNext size={32} />}
          aria-label="Pagination Next"
          variant="ghost"
          onClick={() => paginateTo(page + 1)}
          isDisabled={page === pageCount}
        />
      </HStack>
    </HStack>
  );
};

export default Pagination;
