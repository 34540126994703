/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AddSectionIcon = (props: IconProps) => (
  <Icon boxSize="1.5rem" viewBox="0 0 24 24" fill="#ea4335" {...props}>
    <path d="M19 13H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM19 3H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    <path
      d="M14.019 17.464h-1.786v1.786h-.596v-1.786H9.851v-.596h1.786v-1.786h.596v1.786h1.786v.596Z"
      fill="#fff"
    />
  </Icon>
);
export const AddBlockIcon = (props: IconProps) => (
  <Icon boxSize="1.5rem" viewBox="0 0 24 24" fill="#4285f4" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 3h8v8H3zm10 0h8v8h-8zM3 13h8v8H3zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3z" />
  </Icon>
);
export const TransformWidthIcon = (props: IconProps) => (
  <Icon height="1rem" viewBox="0 0 16 16" {...props}>
    <path fillRule="evenodd" d="M3 7V5H2v5h1V8h9v2h1V5h-1v2H3z" />
  </Icon>
);
export const TransformHeightIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path fillRule="evenodd" d="M8 3h2V2H5v1h2v9H5v1h5v-1H8V3z" />
  </Icon>
);
export const TransformHugVertIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="m4.646 12.646.708.708L8 10.707l2.646 2.646.708-.707L8 9.293l-3.354 3.354zm.708-10L8 5.294l2.646-2.647.708.708L8 6.707 4.646 3.354l.708-.707z" />
  </Icon>
);
export const TransformHugHorzIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="m3.354 4.646-.708.708L5.293 8l-2.646 2.646.707.708L6.707 8 3.354 4.646zm10 .708L10.707 8l2.647 2.646-.708.708L9.293 8l3.354-3.354.707.708z" />
  </Icon>
);
export const TransformFillVertIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="m4.646 10.354.708-.707L8 12.293l2.647-2.646.707.707L8 13.707l-3.354-3.353zm.708-4L8 3.706l2.646 2.646.708-.707L8 2.292 4.646 5.646l.708.707z" />
  </Icon>
);
export const TransformFillHorzIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="m5.647 4.646.707.708L3.707 8l2.646 2.647-.707.707L2.293 8l3.354-3.354zm4 .708L12.293 8l-2.646 2.646.707.708L13.708 8l-3.354-3.354-.707.708z" />
  </Icon>
);
export const TransformRotateIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 8 10" {...props}>
    <path
      fillRule="evenodd"
      d="M0 0v8h8V7H5c0-2.21-1.79-4-4-4V0H0zm1 4v3h3c0-1.657-1.343-3-3-3z"
    />
  </Icon>
);
export const TransformBorderIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="M15.917 1.81H8.92c-3.864 0-6.991 3.127-6.991 6.991v6.997H.176V8.801C.176 3.972 4.091.057 8.92.057h6.997V1.81Z" />
  </Icon>
);
export const PositioningToggleIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="M8 7V5H7v2H5v1h2v2h1V8h2V7H8z"></path>
    <path d="M2 2h3v1H3v2H2V2zm8 0h3v3h-1V3h-2V2zM3 12v-2H2v3h3v-1H3zm10-2v3h-3v-1h2v-2h1z"></path>
  </Icon>
);
export const LayoutVerticalGapIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="M11 13v-2H1v2H0v-3h12v3h-1zm1-10H0V0h1v2h10V0h1v3zM9 7V6H3v1h6z" />
  </Icon>
);
export const LayoutHorizontalGapIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 13 12" {...props}>
    <path d="M13 1h-2v10h2v1h-3V0h3v1zM3 0v12H0v-1h2V1H0V0h3zm4 3H6v6h1V3z" />
  </Icon>
);
export const LayoutWrapIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      d="M8 3h2c1.933 0 3.5 1.567 3.5 3.5S11.933 10 10 10H4.707l2.147 2.146-.708.708-3-3-.353-.354.353-.354 3-3 .708.708L4.707 9H10c1.38 0 2.5-1.12 2.5-2.5S11.38 4 10 4H8V3z"
    />
  </Icon>
);
export const LayoutHorizontalPaddingIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      d="M0 12V0h1v12H0zm3-9h6v6H3V3zm1 1v4h4V4H4zm7 8V0h1v12h-1z"
    />
  </Icon>
);
export const LayoutVerticalPaddingIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      d="M3 3h6v6H3V3zm1 1v4h4V4H4zm8 8H0v-1h12v1zm0-11H0V0h12v1z"
    />
  </Icon>
);
export const LayoutIndividualPaddingIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 16 16" {...props}>
    <path d="M4.82 2.13h6v1h-6v-1Zm-3 3v6h1v-6h-1Zm11 0v6h1v-6h-1Zm-8 9h6v-1h-6v1Z" />
  </Icon>
);
export const LayoutPaddingLeftIcon = (props: IconProps) => (
  <Icon boxSize="12px" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      d="M0 12V0h1v12H0zm3-9h6v6H3V3zm1 1v4h4V4H4z"
    ></path>
  </Icon>
);
export const LayoutPaddingTopIcon = (props: IconProps) => (
  <Icon boxSize="12px" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      d="M0 0h12v1H0V0zm3 3h6v6H3V3zm1 1v4h4V4H4z"
    ></path>
  </Icon>
);
export const LayoutPaddingRightIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 12 12" {...props}>
    <path d="M12 0v12h-1V0h1zM3 3h6v6H3V3zm1 1v4h4V4H4z"></path>
  </Icon>
);
export const LayoutPaddingBottomIcon = (props: IconProps) => (
  <Icon boxSize="1rem" viewBox="0 0 12 12" {...props}>
    <path d="M3 3h6v6H3V3zm1 1v4h4V4H4zm8 8H0v-1h12v1z"></path>
  </Icon>
);
