/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  chakra,
  HStack,
  Heading,
  IconButton,
  VStack,
  StackProps,
  Spacer,
  Tooltip,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  IconButtonProps,
  useEditableControls,
} from '@chakra-ui/react';
import { ArrowBack, Edit, Cancel } from 'emotion-icons/material';

import {
  EDITOR_MODE,
  UISelectedBlock,
  setEditorMode,
  templateSelectedBlock,
  updateSelectedBlock,
  updateTemplateSection,
} from '../slice';
import { UITemplateBlock } from '../../../components/DisplayTemplate';
import { SectionControls, ContentControls } from './editors';
import { useIsUISectionBlockType } from '../hooks';

interface DesignControlContext<T extends UISelectedBlock> {
  block?: T;
  config?: UITemplateBlock['config'];
  content?: UITemplateBlock['content'];
  isSectionBlock?: boolean;
  /**
   * @param config - new config to merge with existing config
   * @param remove - array of keys to remove from config
   */
  updateConfig?: (config: UITemplateBlock['config'], remove?: string[]) => void;
  /**
   * @param content - new content to replace with existing content
   */
  updateContent?: (config: Partial<UITemplateBlock>) => void;
}

const DesignControlsCtx = React.createContext<DesignControlContext<any>>({});

interface DesignControlGroupProps extends StackProps {
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
}

const DesignControlsWrapper = chakra(VStack, {
  baseStyle: {
    h: 'calc(100vh - 115px)',
    overflowY: 'hidden',
    svg: {
      boxSize: '1rem',
      fillOpacity: 0.9,
    },
  },
});

export const useDesignContext = <T extends UISelectedBlock>() =>
  React.useContext<DesignControlContext<T>>(DesignControlsCtx);

const RenameSectionButton = (props: Omit<IconButtonProps, 'aria-label'>) => {
  const { isEditing, getEditButtonProps, getCancelButtonProps } =
    useEditableControls();
  return isEditing ? (
    <IconButton
      icon={<Cancel size="1rem" />}
      aria-label="cancel-rename"
      size="xs"
      {...props}
      {...getCancelButtonProps()}
    />
  ) : (
    <IconButton
      icon={<Edit size="1rem" />}
      aria-label="rename-section"
      size="xs"
      {...props}
      {...getEditButtonProps()}
    />
  );
};

export const DesignControlGroup = ({
  children,
  leftElement,
  rightElement,
  title,
  ...props
}: DesignControlGroupProps) => {
  return (
    <VStack align="start" w="full" p={2} {...props}>
      <HStack w="full" mb={2}>
        {leftElement}
        {title && <Heading size="xs" children={title} />} <Spacer />
        {rightElement}
      </HStack>
      {children}
    </VStack>
  );
};

const DesignControls = () => {
  const dispatch = useDispatch();
  const selectedBlock = useSelector(templateSelectedBlock);
  const selectedBlockId = selectedBlock?.id || selectedBlock?.blockUID;
  const isSectionBlock = useIsUISectionBlockType(selectedBlock);
  const blockPrefix = isSectionBlock ? 'Section' : 'Content';
  const blockTitle = isSectionBlock
    ? `${selectedBlock?.name || selectedBlockId}`
    : ` Block ${selectedBlockId}`;

  const handleRenameSection = (newName?: string) => {
    if (!isSectionBlock || selectedBlock?.name === newName) return;
    const sectionUID = pick(selectedBlock, ['blockUID', 'sectionUID']);
    dispatch(updateTemplateSection({ ...sectionUID, name: newName }));
  };

  /** update config change */
  const handleConfigChange = React.useCallback(
    (config: UITemplateBlock['config'], removeConfig?: string[]) => {
      dispatch(updateSelectedBlock({ block: { config }, removeConfig }));
    },
    [selectedBlock]
  );
  /** update block content  */
  const handleContentChange = React.useCallback(
    (block: Pick<UITemplateBlock, 'content' | 'content_filters' | 'source'>) =>
      dispatch(
        updateSelectedBlock({ block: block as UITemplateBlock, partial: false })
      ),
    [selectedBlock]
  );

  return (
    <DesignControlsCtx.Provider
      value={{
        isSectionBlock,
        block: selectedBlock,
        updateConfig: handleConfigChange,
        updateContent: handleContentChange,
        ...(isSectionBlock ? get(selectedBlock, 'properties') : selectedBlock),
      }}
    >
      <DesignControlsWrapper spacing={0}>
        <Editable
          key={selectedBlockId}
          as={HStack}
          w="full"
          boxShadow="xs"
          p={2}
          isPreviewFocusable={false}
          defaultValue={blockTitle}
          onSubmit={handleRenameSection}
        >
          <IconButton
            colorScheme="blue"
            size="xs"
            variant="link"
            onClick={() => dispatch(setEditorMode(EDITOR_MODE.LAYOUT))}
            icon={<Icon as={ArrowBack} />}
            aria-label="back to layout"
            _hover={{ color: 'gray.500' }}
          />
          <Tooltip label={`${blockPrefix} ${blockTitle}`}>
            <EditablePreview
              flexGrow={1}
              textAlign="center"
              fontWeight="semibold"
            />
          </Tooltip>
          <Input
            size="sm"
            as={EditableInput}
            placeholder="Enter amount"
            _focusVisible={{ boxShadow: 'none' }}
          />
          <RenameSectionButton
            isDisabled={!isSectionBlock}
            _disabled={{ opacity: 0, pointerEvents: 'none' }}
          />
        </Editable>
        <VStack flexGrow={1} overflow="hidden" overflowY="scroll">
          {isSectionBlock ? <SectionControls /> : <ContentControls />}
        </VStack>
      </DesignControlsWrapper>
    </DesignControlsCtx.Provider>
  );
};

export default DesignControls;
