/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { configureStore } from '@reduxjs/toolkit';

// redux slice and reducers
import appSlice from './app/slice';
import templateEditor from './pages/TemplateEditor/slice';

// redux query api services
import baseApi from './services';

export const rootReducer = {
  // slices & reducer
  [appSlice.name]: appSlice.reducer,
  [templateEditor.name]: templateEditor.reducer,
  // api reducers
  [baseApi.reducerPath]: baseApi.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export type AppDispatch = typeof store.dispatch;

export default store;
