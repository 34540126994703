/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { flatMap, get, pick, every, isEqual, map, set, reduce } from 'lodash';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Skeleton,
  Accordion,
  Checkbox,
  Button,
  HStack,
  Spacer,
  useDisclosure,
  Tooltip,
  IconButton,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Highlight,
  Box,
  Tag,
  Text,
} from '@chakra-ui/react';
import { Close, Settings } from 'emotion-icons/material';

import { TextListItem } from '../../../../ContentManager/TextLibrary/EditableTextList';
import { UITemplateBlock } from '../../../../../components/DisplayTemplate';
import { useBranch } from '../../../../../hooks/branch';
import { useDesignContext } from '../..';
import {
  TextContentGroup,
  useGetTextGroupsQuery,
} from '../../../../../services/content-library';
import { useSelectionState } from '..';
import {
  TextGroup,
  useTextGroupView,
} from '../../../../ContentManager/TextLibrary';
import EmptyMessage from '../../../../ContentManager/EmptyMessage';
import { useGetDefaultBranchQuery } from '../../../../../services/branch';
import { ViewCore } from '../../../../../components/DataView';

interface TextGroupsProps {
  view: ViewCore<TextContentGroup>;
  isLoading?: boolean;
}

const useTextGroupData = (branchId?: string | number, fetch?: boolean) => {
  const [selection, onSelectionChange] = useSelectionState();
  const { data: textGroupsResp, isLoading } = useGetTextGroupsQuery(branchId, {
    skip: !fetch || !branchId,
  });

  const view = useTextGroupView({
    data: textGroupsResp?.data,
    rowSelection: selection.textGroups,
    onRowSelectionChange: (textGroups) => onSelectionChange({ textGroups }),
    enableSelection: true,
  });

  const selectedCount = React.useMemo(
    () => view?.getSelectedRowModel()?.rows?.length,
    [selection, textGroupsResp?.data]
  );

  return {
    view,
    data: textGroupsResp?.data || [],
    isLoading,
    selectedCount,
  };
};

const TextsGroups = ({ isLoading, view }: TextGroupsProps) => {
  const [selection, onSelectionChange] = useSelectionState();

  const getIsSomeSelected = React.useCallback(
    (texts?: TextListItem[]) => {
      const selected = texts?.map(
        (text) => selection.textItems?.[`${text?.id}`] ?? true
      );
      return !every(selected, (item) => item === selected?.[0]);
    },
    [selection.textItems]
  );

  return (
    <Skeleton minH="2rem" isLoaded={!isLoading}>
      <EmptyMessage hidden={!view.getIsViewEmpty()} />
      <Accordion allowToggle>
        {view.getRowModel().rows.map((row) => {
          return (
            <TextGroup
              key={row.original?.id || row.id}
              getIsSomeSelected={() =>
                row.getIsSelected() && getIsSomeSelected(row.original.texts)
              }
              {...row.original}
              {...pick(row, [
                'getCanSelect',
                'getIsSelected',
                'getToggleSelectedHandler',
                'toggleSelected',
              ])}
              isEditable={false}
              leftElement={({ groupId, ...props }) => {
                const isChecked = get(
                  selection,
                  `textItems.${props.id}`,
                  false
                );
                return (
                  <Checkbox
                    size="lg"
                    variant="ghost"
                    isChecked={isChecked}
                    onChange={(e) => {
                      onSelectionChange({
                        textGroups: {
                          ...selection.textGroups,
                          ...(e.target.checked && {
                            [`${groupId}`]: true,
                          }),
                        },
                        textItems: {
                          ...selection.textItems,
                          [`${props.id}`]: e.target.checked,
                        },
                      });
                    }}
                  />
                );
              }}
            />
          );
        })}
      </Accordion>
    </Skeleton>
  );
};

export const TextsSelectorModal = () => {
  const { updateContent, ...ctx } = useDesignContext<UITemplateBlock>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selection, onSelectionChange] = useSelectionState();
  const branch = useBranch();
  const { data: mwtvBranch } = useGetDefaultBranchQuery(['id'], {
    skip: !isOpen,
  });

  // update selection when content changes
  React.useEffect(() => {
    const content = get(ctx, 'content', []) as [];
    // crete a map of selected assets and folders from content
    onSelectionChange?.({
      textGroups: reduce(
        content,
        (res, item: any) => item && set(res, item?.id, true) && res,
        {}
      ),
      textItems: reduce(
        flatMap(content, 'texts'),
        (res, item) => item && set(res, item?.id, true) && res,
        {}
      ),
    });
  }, [ctx.block?.id, ctx.block?.blockUID, onSelectionChange]);

  const {
    selectedCount: mySelectedTextsCount,
    data: myTextGroupsData,
    view: myTextGroupsView,
    isLoading: isLoadingMyGroups,
  } = useTextGroupData(branch?.id, isOpen);
  const {
    selectedCount: mwtvSelectedTextsCount,
    data: mwtvTextGroupsData,
    view: mwtvTextGroupsView,
    isLoading: isLoadingMwtvGroups,
  } = useTextGroupData(mwtvBranch?.id, isOpen);

  const allTextGroups = React.useMemo(
    () => [...myTextGroupsData, ...mwtvTextGroupsData],
    [myTextGroupsData, mwtvTextGroupsData]
  );

  const handleClearSelection = React.useCallback(() => {
    onSelectionChange({ textGroups: {}, textItems: {} });
  }, [selection.textItems, selection.textGroups]);

  const handleSaveContent = React.useCallback(() => {
    onClose?.();
    const content_filters: { [x: string]: { id: string | number }[] } = {};
    const addToFilters = (id: string, media?: { id: string | number }[]) => {
      (content_filters[id] || (content_filters[id] = [])).push(
        ...map(media, ({ id }) => ({ id }))
      );
    };
    const content = allTextGroups?.reduce((res, group) => {
      if (!selection.textGroups?.[`${group?.id}`]) return res;
      const selected = group.texts?.filter(
        (text) => selection.textItems?.[`${text?.id}`]
      );
      res.push({ ...group, texts: selected?.length ? selected : group.texts });
      // we push the selected assets to the content_filters
      if (selected?.length && !isEqual(selected, group.texts)) {
        addToFilters(`${group.id}`, selected);
      }
      return res;
    }, [] as typeof allTextGroups);
    updateContent?.({ content, content_filters });
  }, [
    ctx.content,
    updateContent,
    allTextGroups,
    selection.textItems,
    selection.textGroups,
  ]);

  return (
    <>
      <Tooltip label="Select Texts">
        <IconButton
          size="xs"
          aria-label="Font size"
          icon={<Icon as={Settings} />}
          variant="outline"
          onClick={onOpen}
          color={
            !(get(ctx, 'content', []) as unknown[])?.length
              ? 'gray.500'
              : undefined
          }
        />
      </Tooltip>
      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Texts Library</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>
                  <HStack>
                    <Text>My Texts</Text>
                    <Tooltip label="Selected Assets">
                      <Tag hidden={mySelectedTextsCount <= 0}>
                        {mySelectedTextsCount}
                      </Tag>
                    </Tooltip>
                  </HStack>
                </Tab>
                <Tab hidden={branch?.id === mwtvBranch?.id}>
                  <HStack>
                    <Text>MWTV Texts</Text>
                    <Tooltip label="Selected Assets">
                      <Tag hidden={mwtvSelectedTextsCount <= 0}>
                        {mwtvSelectedTextsCount}
                      </Tag>
                    </Tooltip>
                  </HStack>
                </Tab>
              </TabList>
              <Box bg="blue.100" mt={2} p={2}>
                <Highlight
                  query={['Please note:', 'group', 'active']}
                  styles={{ fontWeight: 'bold' }}
                >
                  "Please note: Texts will only appear if their respective group
                  is selected. Feel free to individually select texts for more
                  specific filtering, but remember, the group needs to be active
                  for any texts to be displayed."
                </Highlight>
              </Box>
              <TabPanels>
                <TabPanel maxH="md" overflowY="scroll">
                  <TextsGroups
                    view={myTextGroupsView}
                    isLoading={isLoadingMyGroups}
                  />
                </TabPanel>
                <TabPanel maxH="md" overflowY="scroll">
                  <TextsGroups
                    view={mwtvTextGroupsView}
                    isLoading={isLoadingMwtvGroups}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter as={HStack}>
            <Button
              hidden={!Object.values(selection.textGroups)?.length}
              variant="ghost"
              leftIcon={<Close />}
              children="Clear Selection"
              onClick={handleClearSelection}
            />
            <Spacer />
            <Button
              variant="ghost"
              colorScheme="blue"
              children="Save"
              onClick={handleSaveContent}
              hidden={!Object.values(selection.textGroups)?.length}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TextsSelectorModal;
