/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import { HStack, Text } from '@chakra-ui/react';

import { useDesignContext } from '..';
import { EditableSelect } from '../../../../components/ComboBox';
import {
  TransformBorderIcon,
  TransformFillHorzIcon,
  TransformFillVertIcon,
  TransformHeightIcon,
  TransformHugHorzIcon,
  TransformHugVertIcon,
  TransformRotateIcon,
  TransformWidthIcon,
} from '../../../../components/Icons';
import { useDimensions, useSize } from '../../../../hooks/use-size';
import { useBlockUID, useDebounceCallback } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectedBlockSection } from '../../slice';

export enum SizeTypes {
  Fixed = 'Fixed',
  Hug = 'Hug',
  Fill = 'Fill',
}

const useBlockDimensions = () => {
  const { block } = useDesignContext();
  const blockUID = useBlockUID(block);

  const templateRef = React.useRef<HTMLElement>();
  const blockRef = React.useRef<HTMLElement>();
  const tempSize = useSize(templateRef);
  const dimensions = useDimensions(blockRef, templateRef);
  const autoSize = React.useMemo(
    () => ({
      width: Math.ceil((dimensions.width / tempSize.width) * 100) || 0,
      height: Math.ceil((dimensions.height / tempSize.height) * 100) || 0,
    }),
    [dimensions, tempSize, block]
  );

  const updateBlockRefs = () => {
    const overlay = document.querySelector(
      '[data-block-type] [aria-selected="true"]'
    );
    const template = overlay?.closest('[data-block-type="template"]');
    const section = overlay?.closest('[data-block-type="section"]');
    const content = overlay?.closest('[data-block-type="content"]');
    const element = content || section;
    if (template instanceof HTMLElement && element instanceof HTMLElement) {
      templateRef.current = template;
      blockRef.current = element;
    }
  };

  // find the template and block elements
  React.useEffect(() => updateBlockRefs(), [blockUID]);

  return {
    ...dimensions,
    ...autoSize,
  };
};

const TransformSize = () => {
  const { config, isSectionBlock, updateConfig } = useDesignContext();
  const dimensions = useBlockDimensions();
  const section = useSelector(selectedBlockSection);
  const direction = get(
    section,
    'properties.config.style.flexDirection',
    'row'
  ) as string;

  const getSizeOption = React.useCallback(
    (prop?: 'width' | 'height') => {
      const value = get(config, `style.${prop}`) as string;
      const option = get(config, `options.${prop}Type`);
      if (option) return option as SizeTypes;
      else if (value === 'fit-content') return SizeTypes.Hug;
      else if (value) return SizeTypes.Fixed;
      else return SizeTypes.Fill;
    },
    [config]
  );

  const onHeightOptionChange = React.useCallback(
    (value?: string) => {
      // return if value is the same as current
      if (
        value === get(config, 'options.heightType') ||
        value === get(config, `style.height`)
      )
        return;

      if (value === SizeTypes.Hug) {
        // todo: implement
      } else if (value === SizeTypes.Fill) {
        updateConfig?.(
          {
            options: { heightType: SizeTypes.Fill },
            style: direction === 'row' ? {} : { flexGrow: 1 },
          },
          ['style.height']
        );
      } else if (!!value && value !== get(config, `style.height`)) {
        updateConfig?.(
          {
            options: { heightType: SizeTypes.Fixed },
            style: { height: `${value}%` },
          },
          direction === 'column' || isSectionBlock ? ['style.flexGrow'] : []
        );
      }
    },
    [config]
  );

  const onWidthOptionChange = React.useCallback(
    (value?: string) => {
      // return if value is the same as current
      if (
        value === get(config, 'options.widthType') ||
        value === get(config, `style.width`)
      )
        return;

      if (value === SizeTypes.Hug) {
        // not implemented
      } else if (value === SizeTypes.Fill) {
        updateConfig?.(
          {
            options: { widthType: SizeTypes.Fill },
            style: direction === 'column' ? {} : { flexGrow: 1 },
          },
          ['style.width']
        );
      } else if (!!value && value !== get(config, `style.width`)) {
        updateConfig?.(
          {
            options: { widthType: SizeTypes.Fixed },
            style: { width: `${value}%` },
          },
          direction === 'row' ? ['style.flexGrow'] : []
        );
      }
    },
    [config]
  );

  return (
    <HStack>
      <EditableSelect
        isReadOnly={isSectionBlock}
        leftElement={<Text>W</Text>}
        defaultOption={Object.values(SizeTypes).indexOf(getSizeOption('width'))}
        onOptionChange={onWidthOptionChange}
        options={[
          {
            type: 'number',
            label: 'Fixed Width',
            icon: <TransformHeightIcon boxSize="18px" />,
            isEditable: true,
            defaultValue: `${
              parseInt(
                get(config, 'style.width', dimensions.width)?.toString()
              ) || dimensions.width
            }`,
            min: 0,
            max: 100,
            format: (v) => `${parseInt(v || '0')}%`,
          },
          {
            label: 'Hug contents',
            icon: <TransformHugVertIcon boxSize="18px" />,
            value: 'Hug',
            hidden: true,
          },
          {
            label: 'Fill container',
            icon: <TransformFillVertIcon boxSize="18px" />,
            value: 'Fill',
            hidden: isSectionBlock,
          },
        ]}
      />
      <EditableSelect
        leftElement={<Text>H</Text>}
        defaultOption={Object.values(SizeTypes).indexOf(
          getSizeOption('height')
        )}
        onOptionChange={onHeightOptionChange}
        options={[
          {
            type: 'number',
            label: 'Fixed Height',
            icon: <TransformWidthIcon boxSize="18px" />,
            isEditable: true,
            defaultValue: `${
              parseInt(
                get(config, 'style.height', dimensions.height)?.toString()
              ) || dimensions.height
            }`,
            min: 0,
            max: 100,
            format: (v) => `${parseInt(v || '0')}%`,
          },
          {
            label: 'Hug contents',
            icon: <TransformHugHorzIcon boxSize="18px" />,
            value: SizeTypes.Hug,
            hidden: true,
          },
          {
            label: 'Fill container',
            icon: <TransformFillHorzIcon boxSize="18px" />,
            value: SizeTypes.Fill,
            hidden: isSectionBlock,
          },
        ]}
      />
    </HStack>
  );
};

export const TransformControls = () => {
  const { config, updateConfig } = useDesignContext();
  const dimensions = useBlockDimensions();

  const rotate = React.useMemo(() => {
    const value = get(config, 'style.transform') as string | undefined;
    const match = value?.match(/rotate\((\d+)deg\)/);
    return match?.[1] || '0';
  }, [config?.style?.transform]);

  const onRotateChange = useDebounceCallback(
    (value?: string) => {
      if (!value || value === '0') {
        updateConfig?.({}, ['style.transform']);
      } else {
        updateConfig?.({ style: { transform: `rotate(${value}deg)` } });
      }
    },
    [updateConfig]
  );

  const onBorderChange = useDebounceCallback(
    (value?: string) => {
      if (!value || value === '0') {
        updateConfig?.({}, ['style.borderRadius']);
      } else {
        updateConfig?.({ style: { borderRadius: `${value}%` } });
      }
    },
    [updateConfig]
  );

  return (
    <>
      <HStack
        // TODO: hidden enable when we add support for absolute positioning
        hidden
      >
        <EditableSelect
          leftElement={<Text>X</Text>}
          type="number"
          value={`${dimensions.clientX}`}
          isReadOnly={true}
        />
        <EditableSelect
          leftElement={<Text>Y</Text>}
          type="number"
          value={`${dimensions.clientY}`}
          isReadOnly={true}
        />
      </HStack>
      <TransformSize />
      <HStack>
        <EditableSelect
          leftElement={<TransformRotateIcon />}
          type="number"
          defaultValue={rotate}
          min={0}
          max={360}
          format={(v) => `${v}°`}
          onChange={onRotateChange}
        />
        <EditableSelect
          leftElement={<TransformBorderIcon />}
          type="number"
          min={0}
          max={100}
          defaultValue={`${get(config, 'style.borderRadius', 0)}`}
          onChange={onBorderChange}
          format={(v) => `${v}%`}
        />
      </HStack>
    </>
  );
};
