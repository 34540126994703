/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { Add } from 'emotion-icons/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import get from 'lodash/get';
import {
  Button,
  HStack,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  Input,
  useDisclosure,
  FormLabel,
  FormErrorMessage,
  VStack,
  Spacer,
} from '@chakra-ui/react';

import { useCreateUserMutation } from '../../services/auth';
import { useBranch } from '../../hooks/branch';

import { RolesList } from '.';
import { padEnd } from 'lodash';
import { useUpdateBranchMutation } from '../../services/branch';

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required')
      .email('Email must be a valid email address'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .max(100, 'Password cannot be more than 100 characters long'),
    firstName: yup
      .string()
      .required('First name is required')
      .max(100, 'First name cannot be more than 100 characters long'),
    lastName: yup
      .string()
      .required('Last name is required')
      .max(100, 'Last name cannot be more than 100 characters long'),
    role: yup.string().required('Role is required'),
  })
  .required();

type NewUserData = yup.InferType<typeof schema>;

interface CreateUserFormProps {}

export const CreateUser = () => {
  const toast = useToast();
  const branch = useBranch();
  const dispatch = useDispatch();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors, isValid, isDirty },
  } = useForm<NewUserData>({
    resolver: yupResolver<NewUserData>(schema),
    mode: 'onChange',
  });

  const [createUser, { isLoading: isCreatingUser }] = useCreateUserMutation();
  const [updateBranch, { isLoading: isUpdatingRole }] =
    useUpdateBranchMutation();

  const handleOnSubmit = async (data: NewUserData) => {
    const user = {
      ...data,
      username: data?.email,
      confirmed: true,
      role: {
        disconnect: [],
        connect: [{ id: data?.role, position: { end: true } }],
      },
    };
    try {
      const res = await createUser(user).unwrap();
      await updateBranch({
        id: branch?.id,
        users: {
          disconnect: [],
          connect: [{ id: get(res, 'id'), position: { end: true } }],
        },
      }).unwrap();
      onClose();
      resetForm();
      toast({
        title: `User Added ${user?.email}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error creating user`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const isLoading = isCreatingUser || isUpdatingRole;
  return (
    <>
      <Button
        size="md"
        leftIcon={<Add size="24" />}
        colorScheme="blue"
        children="Add"
        onClick={onOpen}
      />
      <Modal
        isCentered
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
          <ModalContent>
            <ModalHeader>Crete New User</ModalHeader>
            <ModalCloseButton isDisabled={isCreatingUser} />
            <ModalBody as={VStack} spacing={2}>
              <Box w="full">
                <HStack>
                  <FormControl isRequired isInvalid={!!errors.firstName}>
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      id="firstName"
                      {...register('firstName', { required: true })}
                    />
                  </FormControl>
                  <FormControl isRequired isInvalid={!!errors.lastName}>
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      id="lastName"
                      {...register('lastName', { required: true })}
                    />
                  </FormControl>
                </HStack>
                <FormControl isInvalid hidden={isValid} as={HStack}>
                  <FormErrorMessage>
                    {errors.firstName?.message}
                  </FormErrorMessage>
                  <Spacer />
                  <FormErrorMessage>
                    {errors.lastName?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <FormControl isRequired isInvalid={!!errors.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" {...register('email', { required: true })} />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.password}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  type="password"
                  id="password"
                  {...register('password', { required: true })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.role}>
                <FormLabel>Role</FormLabel>
                <RolesList w="full" {...register('role', { required: true })} />
                <FormErrorMessage>{errors?.role?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter as={HStack}>
              <Button
                hidden={isLoading}
                variant="ghost"
                size="md"
                onClick={onClose}
                children="Cancel"
              />
              <Button
                isLoading={isLoading}
                isDisabled={!isValid || !isDirty}
                size="md"
                colorScheme="blue"
                mr={3}
                children="Create"
                type="submit"
              />
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default CreateUser;
