/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  assign,
  find,
  findIndex,
  flatMap,
  get,
  isMatch,
  matches,
  merge,
  omit,
  pick,
  set,
  setWith,
  some,
  unset,
  isEqual,
} from 'lodash';

import { RootState } from '../../store';
import { isUISectionBlockType } from './hooks';
import { ItemSelectionState } from '../../components/DataView';
import authApi from '../../services/auth';
import templatesApi from '../../services/display-templates';
import {
  UI_DEFAULT_DISPLAY_TEMPLATE,
  generateUIContentBlock,
  generateUISectionBlock,
} from './template-defaults';
import {
  UIDisplayTemplate,
  UITemplateBlock,
  UITemplateSection,
} from '../../components/DisplayTemplate';
import { setBranch } from '../../app/slice';

export type UISelectedBlock = UITemplateBlock | UITemplateSection;

export type UITemplateBlockId = Partial<
  Pick<UITemplateBlock, 'id' | 'blockUID'>
>;
export type ContentSectionState = {
  assets: ItemSelectionState;
  folders: ItemSelectionState;
  textGroups: ItemSelectionState;
  textItems: ItemSelectionState;
};
export enum EDITOR_MODE {
  LAYOUT = 0,
  EDIT = 1,
}
export interface TemplateEditorState {
  isLoading?: boolean;
  defaultTemplate?: UIDisplayTemplate;
  editableTemplate?: UIDisplayTemplate;
  originalTemplate?: UIDisplayTemplate;
  selectedBlock?: UISelectedBlock;
  contentSelection: ContentSectionState;
  editorMode: EDITOR_MODE;
}

const initialState: TemplateEditorState = {
  editorMode: EDITOR_MODE.LAYOUT,
  contentSelection: { assets: {}, folders: {}, textGroups: {}, textItems: {} },
};
export const slice = createSlice({
  name: 'edit_display_template',
  initialState,
  reducers: {
    editTemplate: (
      state,
      { payload }: PayloadAction<UIDisplayTemplate['id']>
    ) => {
      setWith(state, 'editableTemplate.id', payload);
    },
    setSelectedContent: (
      state,
      { payload }: PayloadAction<Partial<ContentSectionState>>
    ) => {
      state.contentSelection = {
        ...state.contentSelection,
        ...payload,
      };
    },
    updateTemplateSections: (
      state,
      { payload }: PayloadAction<UITemplateSection[]>
    ) => {
      if (!state.editableTemplate) return;
      state.editableTemplate.sections = payload;
    },
    updateTemplateSection: (
      state,
      { payload }: PayloadAction<Partial<UITemplateSection>>
    ) => {
      if (!state.editableTemplate?.sections) return;
      const { sections } = state.editableTemplate;
      const uid = pick(payload, ['id', 'blockUID']);
      const index = findIndex(sections, uid);
      if (~index) {
        set(sections, index, {
          ...sections[index],
          ...payload,
        });
        if (state.selectedBlock && isMatch(state.selectedBlock, uid)) {
          state.selectedBlock = sections[index];
        }
      }
    },
    addTemplateSection: (
      state,
      { payload }: PayloadAction<Partial<UITemplateSection> | void>
    ) => {
      if (!state.editableTemplate) return;
      const sections = state.editableTemplate?.sections || [];
      const blankSection = generateUISectionBlock();
      const newSection = {
        ...blankSection,
        ...payload,
        blocks: [...get(payload, 'blocks', []), ...blankSection.blocks],
      } as UITemplateSection;
      state.editableTemplate.sections = [...sections, newSection];
      state.selectedBlock = newSection;
    },
    deleteTemplateSection: (
      state,
      { payload }: PayloadAction<UITemplateBlockId>
    ) => {
      if (!state.editableTemplate?.sections) return;
      const selectedSection = pick(payload, ['id', 'blockUID']);
      const { sections } = state.editableTemplate;
      state.editableTemplate.sections = sections.filter(
        (section) => !isMatch(section, selectedSection)
      );
      // clear selected block if it was deleted
      if (
        state.selectedBlock &&
        isMatch(state.selectedBlock, selectedSection)
      ) {
        state.selectedBlock = undefined;
      }
    },
    selectTemplateBlock: (
      state,
      {
        payload,
      }: PayloadAction<UITemplateBlock | UITemplateSection | undefined>
    ) => {
      state.selectedBlock = payload;
    },
    addTemplateBlock: (state) => {
      if (!state.selectedBlock) return;
      const selected = pick(state.selectedBlock, ['id', 'blockUID']);
      const section = state.editableTemplate?.sections?.find(
        ({ blocks, ...item }) =>
          isMatch(item, selected) || some(blocks, matches(selected))
      );
      if (section) {
        const newBlock = generateUIContentBlock();
        section.blocks?.push(newBlock);
      }
      state.selectedBlock = section;
    },
    updateTemplateBlocks: (
      state,
      {
        payload,
      }: PayloadAction<{
        sectionUID: UITemplateBlockId | undefined;
        blocks: UITemplateBlock[];
      }>
    ) => {
      const section = state.editableTemplate?.sections?.find(
        (section) => payload.sectionUID && isMatch(section, payload.sectionUID)
      );
      if (!section || !section.blocks?.length) return;
      section.blocks = payload.blocks;
    },
    updateSelectedBlock: (
      state,
      {
        payload,
      }: PayloadAction<{
        block: Partial<UISelectedBlock>;
        removeConfig?: string[];
        partial?: boolean;
      }>
    ) => {
      const { partial = true } = payload;
      const uid = pick(state.selectedBlock, ['id', 'blockUID']);
      if (isUISectionBlockType(state.selectedBlock)) {
        const section = find(
          state.editableTemplate?.sections,
          matches(uid)
        ) as UITemplateSection;
        // update section properties
        if (partial) merge(section.properties, payload.block);
        else assign(section.properties, payload.block);
        // remove config properties
        payload?.removeConfig?.forEach((path) =>
          unset(section.properties?.config, path)
        );
        state.selectedBlock = section;
      } else {
        const block = find(
          flatMap(state.editableTemplate?.sections, 'blocks'),
          matches(uid)
        ) as UITemplateBlock;
        // update section properties
        if (partial) merge(block, payload.block);
        else assign(block, payload.block);
        // remove config properties
        payload?.removeConfig?.forEach((path) => unset(block?.config, path));
        state.selectedBlock = block;
      }
    },
    deleteTemplateBlock: (
      state,
      {
        payload,
      }: PayloadAction<{
        section: UITemplateBlockId;
        block: UITemplateBlockId;
      }>
    ) => {
      const section = state.editableTemplate?.sections?.find((section) =>
        isMatch(section, payload.section)
      );
      if (!section || !section.blocks?.length) return;
      state.selectedBlock = section;
      section.blocks = section.blocks.filter(
        (block) => !isMatch(block, payload.block)
      );
    },
    discardTemplateChanges: (state) => {
      state.editableTemplate = state.originalTemplate;
    },
    setEditorLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setEditorMode: (state, { payload }: PayloadAction<EDITOR_MODE>) => {
      state.editorMode = payload;
    },
  },
  extraReducers: (builder) => {
    // get default template
    builder
      .addMatcher(
        templatesApi.endpoints.getDefaultTemplate.matchRejected,
        (state) => {
          if (!state.editableTemplate) {
            state.editableTemplate = UI_DEFAULT_DISPLAY_TEMPLATE;
            state.originalTemplate = state.editableTemplate;
          }
        }
      )
      .addMatcher(
        templatesApi.endpoints.getDefaultTemplate.matchFulfilled,
        (state, action) => {
          state.defaultTemplate = omit(action.payload, ['id']);
          if (!state.editableTemplate) {
            state.editableTemplate = state.defaultTemplate;
            state.originalTemplate = state.editableTemplate;
          }
        }
      );

    // get default template
    builder.addMatcher(
      templatesApi.endpoints.getDisplayTemplate.matchFulfilled,
      (state, action) => {
        state.editableTemplate = action.payload;
        state.originalTemplate = state.editableTemplate;
      }
    );
    // get default template
    builder.addMatcher(
      templatesApi.endpoints.createDisplayTemplate.matchFulfilled,
      (state, action) => {
        const templateId = get(action, 'payload.data.id');
        if (templateId) {
          state.editableTemplate = { id: templateId };
        }
      }
    );
    builder.addMatcher(setBranch.match, (state, action) => {
      const templateId = get(action, 'payload.display_template.id');
      if (!templateId) {
        state.editableTemplate = state.defaultTemplate;
      } else if (templateId !== state.editableTemplate?.id) {
        state.editableTemplate = { id: templateId };
      }
    });
  },
});

export const {
  addTemplateBlock,
  addTemplateSection,
  deleteTemplateBlock,
  deleteTemplateSection,
  discardTemplateChanges,
  editTemplate,
  selectTemplateBlock,
  setEditorLoading,
  setEditorMode,
  setSelectedContent,
  updateSelectedBlock,
  updateTemplateBlocks,
  updateTemplateSection,
  updateTemplateSections,
} = slice.actions;

export const selectTemplateEditor = (state: RootState) =>
  state.edit_display_template;
export const selectEditableTemplate = createSelector(
  selectTemplateEditor,
  (state) => state.editableTemplate
);
export const templateSelectedBlock = createSelector(
  selectTemplateEditor,
  (state) => state.selectedBlock
);
export const templateSelectedBlockId = createSelector(
  selectTemplateEditor,
  (state) =>
    state.selectedBlock
      ? (pick(state.selectedBlock, ['id', 'blockUID']) as UITemplateBlockId)
      : undefined
);
export const selectEditorMode = createSelector(
  selectTemplateEditor,
  (state) => state.editorMode
);
export const selectorSelectedContent = createSelector(
  selectTemplateEditor,
  (state) => state.contentSelection
);
export const selectorIsTemplateDirty = createSelector(
  selectTemplateEditor,
  (state) => !isEqual(state.editableTemplate, state.originalTemplate)
);
export const selectedIsEditorLoading = createSelector(
  selectTemplateEditor,
  (state) => state.isLoading
);
/** TODO: This could use a redesign, filtering sections all the time is really expensive */
export const selectedBlockSection = createSelector(
  selectTemplateEditor,
  (state) => {
    const selected = pick(state.selectedBlock, ['id', 'blockUID']);
    const section = state.editableTemplate?.sections?.find(
      ({ blocks, ...item }) =>
        isMatch(item, selected) || some(blocks, matches(selected))
    );
    return section;
  }
);

export default slice;
