/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */
import storage from 'store';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { get, uniqueId } from 'lodash';

import authApi from '../services/auth';
import groupsApi from '../services/groups';
import { RootState } from '../store';
import { Branch } from '../services/branch';

export const APP_REDUCER_NAME = 'app';

type NavState = {
  isOpen?: boolean;
  variant: 'permanent' | 'persistent' | 'temporary';
};
export type AppState = {
  isLoading?: boolean;
  branch?: Partial<Branch>;
  nav: NavState;
  auth: {
    isAuthenticated: boolean;
    isLoading: boolean;
    jwt: string | null;
    sessionId?: string;
  };
};

const initialState = {
  isLoading: false,
  nav: {
    isOpen: true,
    variant: 'permanent',
  },
  auth: {
    isAuthenticated: !!storage.get('mwtv_jwt'),
    isLoading: false,
    jwt: storage.get('mwtv_jwt') || null,
    sessionId: uniqueId(),
  },
} as AppState;

export const slice = createSlice({
  name: APP_REDUCER_NAME,
  initialState,
  reducers: {
    setNavVariant: (state, { payload }: PayloadAction<NavState['variant']>) => {
      state.nav.variant = payload;
    },
    setNavIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.nav.isOpen = payload;
    },
    setBranch: (state, { payload }: PayloadAction<AppState['branch']>) => {
      state.branch = payload;
    },
    userLogout: (state) => {
      state.auth.jwt = null;
      state.auth.isAuthenticated = false;
      state.auth.isLoading = false;
      delete state.auth.sessionId;
      delete state.branch;
      storage.clearAll();
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action) => {
        if (!get(action, 'payload.jwt')) return;
        state.auth.jwt = action.payload.jwt;
        state.auth.isAuthenticated = true;
        state.auth.sessionId = uniqueId();
        storage.set('mwtv_jwt', action.payload.jwt);
      }
    );
    builder.addMatcher(
      authApi.endpoints.getUserContext.matchFulfilled,
      (state, action) => {
        // we need to update our branch in case the context has changed
        const branch = get(action, 'payload.context.branch');
        if (branch?.id === state.branch?.id) state.branch = branch;
      }
    );
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, { payload }) => {
        const errorType = get(payload, 'data.error.name');
        if (payload?.status === 401 || errorType === 'UnauthorizedError') {
          state.auth.isAuthenticated = false;
          state.auth.jwt = null;
          storage.remove('mwtv_jwt');
        }
      }
    );
  },
});

export const { setBranch, userLogout, setNavVariant, setNavIsOpen } =
  slice.actions;

export const selectApp = (state: RootState) => state.app;
export const selectBranch = createSelector(selectApp, (app) => app.branch);
export const selectNav = createSelector(selectApp, (app) => app.nav);

export default slice;
