/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { Box, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { Add } from 'emotion-icons/material';

import { AccordionSectionBlocks } from '../Layout';
import { addTemplateBlock } from '../slice';
import { DesignControlGroup, useDesignContext } from '.';
import {
  TransformControls,
  LayoutControls,
  ContentTypeMenu,
  FillControls,
  TextsControls,
  AppointmentsTextControls,
  AppointmentsSettingsControls,
  ContentLayoutControls,
} from './controls';
import { PositioningToggleIcon } from '../../../components/Icons';
import {
  UITemplateBlock,
  UITemplateSection,
} from '../../../components/DisplayTemplate';
import TextsSelectorModal from './controls/texts/selector';

export const SectionControls = () => {
  const dispatch = useDispatch();
  const { block: section, config } = useDesignContext<UITemplateSection>();

  const handleOnAddBlock = React.useCallback(
    debounce(() => {
      dispatch(addTemplateBlock());
    }, 200),
    []
  );

  return (
    <>
      <DesignControlGroup
        spacing={1}
        title="Transform"
        children={<TransformControls />}
        rightElement={
          <Tooltip label="Absolute position" size="xs">
            <IconButton
              // TODO: hidden for now we can easily add support for absolute positioning
              hidden
              size="xs"
              icon={<PositioningToggleIcon boxSize="18px" />}
              aria-label="Absolute position"
              isActive={get(config, 'style.position') === 'absolute'}
              variant="outline"
            />
          </Tooltip>
        }
      />
      <DesignControlGroup
        spacing={1}
        title="Layout"
        children={<LayoutControls />}
      />
      <DesignControlGroup
        spacing={1}
        title="Background"
        children={<FillControls />}
      />
      <DesignControlGroup
        spacing={1}
        title="Blocks"
        overflowY="scroll"
        maxH="100%"
        minH="180px"
        rightElement={
          <Tooltip label="Add New Block" size="xs">
            <IconButton
              size="xs"
              variant="outline"
              icon={<Icon as={Add} boxSize="18px" />}
              aria-label="Add New Block"
              onClick={handleOnAddBlock}
            />
          </Tooltip>
        }
        children={
          <Box data-group w="full" overflowY="scroll">
            <AccordionSectionBlocks section={section} blocksStyle={{ px: 2 }} />
          </Box>
        }
      />
    </>
  );
};

export const ContentControls = () => {
  const { block } = useDesignContext<UITemplateBlock>();
  return (
    <>
      <DesignControlGroup children={<ContentTypeMenu />} />
      <DesignControlGroup
        spacing={1}
        title="Transform"
        children={<TransformControls />}
        rightElement={
          <Tooltip label="Absolute position" size="xs">
            <IconButton
              // TODO: hidden for now we can easily add support for absolute positioning
              hidden
              size="xs"
              icon={<PositioningToggleIcon boxSize="18px" />}
              aria-label="Absolute position"
            />
          </Tooltip>
        }
      />
      <DesignControlGroup
        spacing={1}
        title="Layout"
        children={<ContentLayoutControls />}
      />
      <DesignControlGroup
        spacing={1}
        title="Fill"
        children={<FillControls />}
      />
      {block?.source === 'texts' && (
        <DesignControlGroup
          spacing={1}
          title="Text"
          children={<TextsControls />}
          rightElement={<TextsSelectorModal />}
        />
      )}
      {block?.source === 'appointments' && (
        <>
          <DesignControlGroup
            spacing={1}
            title="Text"
            children={<AppointmentsTextControls />}
          />
          <DesignControlGroup
            spacing={1}
            title="Settings"
            children={<AppointmentsSettingsControls />}
          />
        </>
      )}
    </>
  );
};
