/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { useRef, useState } from 'react';
import { Text, Box, chakra, Center, Input, HStack } from '@chakra-ui/react';

import { FileUpload } from 'emotion-icons/material';
import { rawFileToAsset } from '../../../services/utils';
import { AssetSource, RawAsset } from '../../../services';

const UploadIcon = chakra(FileUpload, {
  baseStyle: {
    color: 'blue.500',
    boxSize: '5rem',
    padding: '1rem',
    bg: 'gray.100',
    borderRadius: 'full',
  },
});
export const MediaBox = chakra(Box, {
  baseStyle: {
    borderStyle: 'dashed',
  },
});
export const OpaqueInput = chakra(Input, {
  baseStyle: {
    opacity: 0,
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    tabIndex: '-1',
    zIndex: 1,
    boxSize: 'full',
  },
});

export interface AddFileStepProps {
  onAddFiles?: (assets: RawAsset[]) => void;
}

const AddFileStep = ({ onAddFiles }: AddFileStepProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragOver, setDragOver] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    if (!inputRef.current) return;
    inputRef.current.click();
    e.preventDefault();
  };

  const handleChange = () => {
    if (!inputRef.current || !inputRef.current.files) return;
    const files = inputRef.current.files;
    const assets: RawAsset[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) continue;
      const asset = rawFileToAsset(file, AssetSource.COMPUTER);
      assets.push(asset);
    }
    if (onAddFiles) onAddFiles(assets);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e?.dataTransfer?.files) {
      const files = e.dataTransfer.files;
      const assets: RawAsset[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) continue;
        const asset = rawFileToAsset(file, AssetSource.COMPUTER);
        assets.push(asset);
      }
      if (onAddFiles) onAddFiles(assets);
    }
    setDragOver(false);
  };

  return (
    <Center
      minH="20rem"
      pos="relative"
      borderRadius="md"
      border="1px solid"
      borderStyle="dashed"
      borderColor={dragOver ? 'gray.50' : 'gray.300'}
      background={dragOver ? 'blue.50' : undefined}
      onDrop={handleDrop}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={() => setDragOver(false)}
      onDragOver={(e) => {
        e.preventDefault();
        setDragOver(true);
      }}
      _hover={{ opacity: 0.5, bg: 'gray.50' }}
    >
      <HStack>
        <OpaqueInput
          multiple
          type="file"
          name="files"
          ref={inputRef}
          onChange={handleChange}
        />
        <UploadIcon />
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            <Box as="u">Click to upload</Box>
            {' or drag and drop'}
          </Text>
          <Text fontSize="sm" color="gray.500">
            Max file size is 3 MB
          </Text>
        </Box>
      </HStack>
    </Center>
  );
};

export default AddFileStep;
