/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// This function creates a set of function that helps us create multipart component styles.
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'asset_card',
  'asset_card_tag',
]);

export const AssetCard = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    MyWelcomeTv: {
      asset_card_tag: {
        color: 'white',
        bg: '#4d8dba',
      },
    },
    branch: {
      asset_card_tag: {
        bg: 'gray.100',
      },
    },
    organization: {
      asset_card_tag: {
        bg: 'primary.500',
      },
    },
  },
});
