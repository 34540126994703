/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { startCase, get, isEmpty } from 'lodash';
import { VideoSettings, Stop, PlayArrow } from 'emotion-icons/material';
import {
  HStack,
  IconButton,
  Tooltip,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';

import { EditableSelect } from '../../../../components/ComboBox';
import { useDesignContext } from '..';
import { UITemplateBlock } from '../../../../components/DisplayTemplate';
import { ANIMATION_INTERVAL } from '../../../../components/DisplayTemplate/TemplateBlock/BlockContent';
import { useDebounceCallback } from '../../hooks';

const MIN_INTERVAL = 1;
const MAX_INTERVAL = 120;

const ANIMATION_TYPES = {
  texts: ['None', 'Fade', 'Scale', 'Slide'] as const,
  media: ['None', 'Fade', 'Scale', 'Slide', 'Shake'] as const,
  appointments: ['None', 'Fade', 'Shake', 'SlideDown', 'SlideRight'] as const,
  none: null,
};

const isOptionHidden = (block?: UITemplateBlock) => {
  switch (block?.source) {
    case 'media':
    case 'texts':
      return isEmpty(block?.content);
    case 'appointments':
      return false;
    default:
      return true;
  }
};

export const AnimationControls = () => {
  const { block, updateConfig, config, isSectionBlock } =
    useDesignContext<UITemplateBlock>();

  const animationType = get(config, 'animation.type', 'Fade') as string;
  const defaultOption = isSectionBlock ? 'media' : block?.source;
  // default animation interval is in milliseconds
  const defaultInterval = get(
    ANIMATION_INTERVAL,
    `${startCase(defaultOption as string)}`,
    0
  ) as number;
  // we covert our interval to seconds for the input
  const intervalValue = Math.floor(
    get(config, 'animation.interval', defaultInterval) / 1000
  ) as number;

  const isAnimationDisabled = get(
    config,
    'animation.isDisabled',
    isSectionBlock
  );

  const animationOptions = React.useMemo(() => {
    const types = get(ANIMATION_TYPES, [`${defaultOption}`], []);
    const options = types?.map((value) => ({ value }));
    return { options, types };
  }, [defaultOption]);

  const handleChangeInterval = useDebounceCallback(
    (value?: string) => {
      const interval = Number(value);
      if (interval < MIN_INTERVAL || interval > MAX_INTERVAL) return;
      if (!interval) return defaultInterval;
      const ms = Math.floor(interval * 1000); // convert to milliseconds
      updateConfig?.({ animation: { interval: ms } });
    },
    [defaultInterval, updateConfig]
  );

  const handleAnimationChange = React.useCallback(
    (type?: string) => {
      updateConfig?.({ animation: { type } });
    },
    [updateConfig]
  );

  const handleToggleAnimation = React.useCallback(() => {
    updateConfig?.({ animation: { isDisabled: !isAnimationDisabled } });
  }, [isAnimationDisabled]);

  if (isOptionHidden(block)) return null;
  return (
    <HStack>
      <HStack spacing={0}>
        <EditableSelect
          tooltip="Animation Type"
          leftElement={<VideoSettings />}
          onOptionChange={handleAnimationChange}
          defaultOption={animationOptions.types.indexOf(animationType)}
          options={animationOptions.options}
        />
        <Tooltip label="Animation Inverval in Seconds">
          <NumberInput
            borderColor="transparent"
            step={0.1}
            key={block?.id || block?.blockUID}
            defaultValue={intervalValue}
            min={MIN_INTERVAL}
            max={MAX_INTERVAL}
            size="sm"
            w="5.5rem"
            onChange={handleChangeInterval}
          >
            <NumberInputField px={1} />
          </NumberInput>
        </Tooltip>
      </HStack>
      <Tooltip label={`${isAnimationDisabled ? 'Play' : 'Stop'} Animation`}>
        <IconButton
          variant="outline"
          size="sm"
          aria-label="Play/Stop Animation"
          icon={isAnimationDisabled ? <PlayArrow /> : <Stop />}
          isActive={isAnimationDisabled}
          onClick={handleToggleAnimation}
        />
      </Tooltip>
    </HStack>
  );
};
