/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import pick from 'lodash/pick';
import { useStyleConfig, InputProps, StyleProps } from '@chakra-ui/react';

import './styles.css';
import { EditableInputBase, EditableInputNumber } from '..';

export type NumberInputProps = Omit<
  EditableInputNumber,
  keyof EditableInputBase
>;

export const useInputStyle = (props: StyleProps) => {
  const input = useStyleConfig('Input') as { field: any };
  return React.useMemo(
    () => ({
      ...input,
      group: pick(props, ['size', 'maxWidth', 'maxW', 'maxH', 'maxHeight']),
    }),
    [props]
  );
};

type DragRangeProps = NumberInputProps & {
  ref: React.RefObject<HTMLElement>;
  onMouseDown?: (e?: MouseEvent) => void;
  onMouseUp?: (e?: MouseEvent) => void;
  value?: string | number;
  onChange?: (value: string) => void;
};
export const useDragRange = ({
  direction = 'x',
  value,
  onChange,
  step = 1,
  min = Number.MIN_VALUE,
  max = Number.MAX_VALUE,
  precision,
  ...props
}: DragRangeProps) => {
  // we need to use a ref to keep track of the value inside the mouse-events
  const innerValue = React.useRef(value);
  const pos = React.useRef({ clientX: 0, clientY: 0 });
  const axisVar = React.useMemo(
    () => (direction === 'x' ? 'clientX' : 'clientY'),
    [direction]
  );
  const cursorClass = React.useMemo(() => {
    if (direction === 'x') return 'cursor-ew-resize';
    if (direction === 'y') return 'cursor-ns-resize';
    return 'default';
  }, [direction]);

  React.useEffect(() => {
    innerValue.current = value;
  }, [value]);

  const handleOnMouseDrag = (e?: MouseEvent) => {
    const currValue = Number(innerValue.current);
    const eventAxis = e?.[axisVar] || 0;
    if (eventAxis > pos.current[axisVar] && currValue < max) {
      onChange?.((currValue + step)?.toFixed(precision));
    } else if (eventAxis < pos.current[axisVar] && currValue > min) {
      onChange?.((currValue - step)?.toFixed(precision));
    }
  };

  const addDragEvent = () => {
    document.body.classList.add(cursorClass);
    window.addEventListener('mousemove', handleOnMouseDrag);
  };
  const removeDragEvent = () => {
    document.body.classList.remove(cursorClass);
    window.removeEventListener('mousemove', handleOnMouseDrag);
  };
  const handleMouseUp = (e?: MouseEvent) => {
    props?.onMouseUp?.(e);
    removeDragEvent();
    window.removeEventListener('mouseup', handleMouseUp);
  };
  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    props?.onMouseDown?.(e);
    pos.current = {
      clientX: e.clientX,
      clientY: e.clientY,
    };
    if (onChange) addDragEvent();
    window.addEventListener('mouseup', handleMouseUp);
  };

  React.useEffect(() => {
    const ref = props?.ref;
    ref?.current?.addEventListener('mousedown', handleMouseDown);
    return () => {
      ref?.current?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [direction, step, onChange]);
};
