/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { DateTime } from 'luxon';
import {
  get,
  flatMap,
  map,
  set,
  reduce,
  isEmpty,
  partition,
  isEqual,
} from 'lodash';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  ScaleFade,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Close,
  CalendarToday,
  PermMedia,
  ChevronRight,
  Verified,
  Remove,
  FitScreen,
} from 'emotion-icons/material';
import {
  AlignBottom,
  AlignCenter,
  AlignEnd,
  AlignMiddle,
  AlignStart,
  AlignTop,
} from 'emotion-icons/bootstrap';

import EmptyMessage from '../../../../ContentManager/EmptyMessage';
import { EditableSelect } from '../../../../../components/ComboBox';
import { MediaContentGroup } from '../../../../../services/content-library';
import { AssetsGrid, FoldersGrid } from '../../../../../components/DataView';
import { FileSelect } from '../../../../../components/ComboBox';
import { useDesignContext } from '../..';
import { useDebounceCallback } from '../../../hooks';
import { AnimationControls } from '../animation';
import {
  MediaContext,
  useMediaContext,
  useMediaData,
  useSelectedMedia,
} from './hooks';

const MediaGroupAssets = () => {
  const { selectedFolder, selectedFolderView, isFetching } = useMediaContext();

  const createdAt = selectedFolder?.createdAt;
  const selectedCount = selectedFolderView?.getSelectedRowModel().rows.length;

  return (
    <ScaleFade initialScale={0.9} in>
      <Box my={2}>
        <HStack my={2} spacing={5}>
          {!!createdAt && (
            <Tag variant="unstyled" size="lg" p={0}>
              <TagLeftIcon as={CalendarToday} />
              <TagLabel>
                Created:
                {' ' + DateTime.fromISO(createdAt)?.toRelative?.()}
              </TagLabel>
            </Tag>
          )}
          <Tag variant="unstyled" size="lg" p={0}>
            <TagLeftIcon as={PermMedia} />
            <TagLabel>Assets: {selectedFolderView.length}</TagLabel>
          </Tag>
          <Tag hidden={!selectedCount} variant="unstyled" size="lg" p={0}>
            <TagLeftIcon as={Verified} />
            <TagLabel>
              Selected: {selectedCount}/{selectedFolderView.length}
            </TagLabel>
          </Tag>
        </HStack>
      </Box>
      <EmptyMessage hidden={!selectedFolderView.getIsViewEmpty()} />
      <AssetsGrid
        isIndeterminateEnabled={false}
        hidden={selectedFolderView.getIsViewEmpty()}
        isEditable
        view={selectedFolderView}
        isLoading={isFetching}
      />
    </ScaleFade>
  );
};

const MyMediaLibrary = () => {
  const {
    setSelectedFolder,
    filterSelectedAssets,
    isFetching,
    myAssetsView,
    myFoldersView,
  } = useMediaContext();

  return (
    <>
      <FoldersGrid
        isEditable
        isIndeterminateEnabled={false}
        hidden={myFoldersView.getIsViewEmpty()}
        view={myFoldersView}
        isLoading={isFetching}
        onClick={setSelectedFolder}
        leftElement={({ media, ...props }) => {
          const maxCount = media?.length || 0;
          const count = filterSelectedAssets?.(media)?.length;
          const label = `${count || maxCount}/${maxCount}`;
          return (
            <Tooltip label={`${count || maxCount} of ${maxCount} selected`}>
              <Tag
                size="sm"
                hidden={!props?.getIsSelected?.()}
                children={label}
              />
            </Tooltip>
          );
        }}
      />
      <AssetsGrid
        isEditable
        isIndeterminateEnabled={false}
        hidden={myAssetsView.getIsViewEmpty()}
        view={myAssetsView}
        isLoading={isFetching}
      />
    </>
  );
};

const MWTVMediaLibrary = () => {
  const {
    setSelectedFolder,
    filterSelectedAssets,
    isFetching,
    mwtvAssetsView,
    mwtvFoldersView,
  } = useMediaContext();

  return (
    <>
      <FoldersGrid
        isEditable
        isIndeterminateEnabled={false}
        hidden={mwtvFoldersView.getIsViewEmpty()}
        view={mwtvFoldersView}
        isLoading={isFetching}
        onClick={setSelectedFolder}
        leftElement={({ media, ...props }) => {
          const maxCount = media?.length || 0;
          const count = filterSelectedAssets?.(media)?.length;
          const label = `${count || maxCount}/${maxCount}`;
          return (
            <Tooltip label={`${count || maxCount} of ${maxCount} selected`}>
              <Tag
                size="sm"
                hidden={!props?.getIsSelected?.()}
                children={label}
              />
            </Tooltip>
          );
        }}
      />
      <AssetsGrid
        isEditable
        isIndeterminateEnabled={false}
        hidden={mwtvAssetsView.getIsViewEmpty()}
        view={mwtvAssetsView}
        isLoading={isFetching}
      />
    </>
  );
};

const MediaLibrary = () => {
  const {
    getSelectedContent,
    mwtvBranchId,
    branchId,
    myAssetsView,
    myFoldersView,
    onClose,
    onSelectionChange,
    selectedContent: selection,
    setTabIndex,
    tabIndex,
  } = useMediaContext();
  const { updateContent, ...ctx } = useDesignContext();
  const originalMedia = React.useMemo(
    () => map(flatMap(ctx.content as MediaContentGroup[], 'media'), 'id'),
    [ctx.content]
  );
  /** save the selected content to the store
   * TODO: maybe we should save the content to the store when the user clicks on the asset
   * instead of saving it when the user clicks on the save button
   */
  const handleSaveContent = React.useCallback(() => {
    onClose?.();
    const blockContent = getSelectedContent();
    const newContent = blockContent?.content as MediaContentGroup[];
    const newMedia = map(
      flatMap(blockContent?.content as MediaContentGroup[], 'media'),
      'id'
    );
    if (!isEqual(originalMedia, newMedia)) {
      updateContent?.({ ...blockContent, content: newContent });
    }
  }, [getSelectedContent, updateContent, originalMedia]);

  const handleClearSelected = React.useCallback(
    () => onSelectionChange({ assets: {}, folders: {} }),
    [onSelectionChange]
  );

  const hasSelection = React.useMemo(
    () => !(isEmpty(selection.assets) && isEmpty(selection.folders)),
    [selection]
  );

  const { mySelectedMedia, mwtvSelectedMedia } = useSelectedMedia();
  return (
    <ScaleFade initialScale={0.9} in>
      <EmptyMessage
        hidden={
          !(myAssetsView.getIsViewEmpty() && myFoldersView.getIsViewEmpty())
        }
      />
      <Tabs index={tabIndex} onChange={setTabIndex}>
        <TabList>
          <Tab>
            <HStack>
              <Text>My Media</Text>
              <Tooltip label="Selected Assets">
                <Tag hidden={!mySelectedMedia?.length}>
                  {mySelectedMedia?.length}
                </Tag>
              </Tooltip>
            </HStack>
          </Tab>
          <Tab hidden={branchId === mwtvBranchId}>
            <HStack>
              <Text>MWTV Media</Text>
              <Tooltip label="Selected Assets">
                <Tag hidden={!mwtvSelectedMedia?.length}>
                  {mwtvSelectedMedia?.length}
                </Tag>
              </Tooltip>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel maxH="md" overflowY="scroll">
            <MyMediaLibrary />
          </TabPanel>
          <TabPanel maxH="md" overflowY="scroll">
            <MWTVMediaLibrary />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Divider mt={5} />
      <HStack mt={2}>
        <Button
          hidden={!hasSelection}
          onClick={handleClearSelected}
          leftIcon={<Close size="1rem" />}
          variant="ghost"
          children="Clear Selected"
          size="md"
        />
        <Spacer />
        <Button
          onClick={handleSaveContent}
          variant="ghost"
          children="Save"
          colorScheme="blue"
          size="md"
        />
      </HStack>
    </ScaleFade>
  );
};

const MediaSelector = () => {
  const ctx = useDesignContext();
  const {
    selectedContent: selection,
    onSelectionChange,
    selectedFolder,
    filterSelectedAssets,
    clearSelectedFolder,
  } = useMediaContext();

  // update selection when content changes
  React.useEffect(() => {
    const content = get(ctx, 'content', []) as MediaContentGroup[];
    // crete a map of selected assets and folders from content
    const [folders] = partition(content, 'group');
    onSelectionChange?.({
      folders: reduce(folders, (res, { id }) => set(res, id, true) && res, {}),
      assets: reduce(
        flatMap(content, 'media'),
        (res, { id }) => set(res, id, true) && res,
        {}
      ),
    });
  }, [ctx?.block?.id, ctx?.block?.blockUID, onSelectionChange]);

  // select folder when assets inside are selected
  React.useEffect(() => {
    if (!selectedFolder?.id) return;
    const selected = filterSelectedAssets(selectedFolder?.media);
    onSelectionChange({
      folders: { ...selection.folders, [selectedFolder.id]: !!selected.length },
    });
  }, [selection?.assets, ctx.content]);

  return (
    <>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRight color="gray.500" size="1.5rem" />}
        hidden={!selectedFolder}
      >
        <BreadcrumbItem>
          <Button
            variant="link"
            colorScheme="black"
            fontWeight="normal"
            onClick={clearSelectedFolder}
            children="All Media"
          />
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text fontWeight="semibold">{selectedFolder?.name || 'Folder'}</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <Tabs isManual index={!!selectedFolder?.id ? 1 : 0} isLazy>
        <TabPanels>
          <TabPanel px={0}>
            <MediaLibrary />
          </TabPanel>
          <TabPanel px={0} maxH="md" overflowY="scroll">
            <MediaGroupAssets />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

const MediaFitOptions = () => {
  const { block, content, config, updateConfig } = useDesignContext();

  const cssUnit = '%';
  const objectFit = get(config, 'style.asset.objectFit', 'contain');
  const mediaWidth = get(config, 'style.asset.width', `100${cssUnit}`);
  const mediaHeight = get(config, 'style.asset.height', `100${cssUnit}`);

  const handleFitChange = React.useCallback(
    (fit?: string) => {
      if (objectFit === fit) return;
      updateConfig?.({ style: { asset: { objectFit: fit } } });
    },
    [updateConfig, objectFit]
  );

  const [mediaWidthValue, setMediaWidthValue] = React.useState(mediaWidth);
  const [mediaHeightValue, setMediaHeightValue] = React.useState(mediaHeight);

  const handleSizeChange = useDebounceCallback(
    (prop: 'width' | 'height', value: string) => {
      const newValue = Number(value.replace(cssUnit, ''));
      if (newValue < 0 || newValue > 100) return;
      updateConfig?.({ style: { asset: { [prop]: `${newValue}${cssUnit}` } } });
    },
    []
  );

  React.useEffect(() => {
    setMediaHeightValue(mediaHeight);
    setMediaWidthValue(mediaWidth);
  }, [block?.id, block?.blockUID]);

  if (!get(content, 'length')) return null;
  return (
    <HStack spacing={0}>
      <EditableSelect
        w="inherit"
        matchWidth
        leftElement={<FitScreen />}
        defaultOption={Object.values(['contain', 'cover']).indexOf(objectFit)}
        onOptionChange={handleFitChange}
        format={(value) => {
          switch (value) {
            case 'contain':
              return 'Fit';
            case 'cover':
              return 'Fill';
            default:
              return 'Fill';
          }
        }}
        options={[
          { label: 'Fit', value: 'contain' },
          { label: 'Fill', value: 'cover' },
        ]}
      />
      <Tooltip label="Image Width">
        <NumberInput
          borderColor="transparent"
          value={mediaWidthValue}
          min={1}
          max={100}
          size="sm"
          w="4.8rem"
          onFocus={(e) =>
            setMediaWidthValue(e.target.value.replace(cssUnit, ''))
          }
          onBlur={(e) => {
            const val = (e.target.value || '100') + cssUnit;
            setMediaWidthValue(val);
            handleSizeChange('width', val);
          }}
          onChange={(v) => {
            setMediaWidthValue(v);
            if (parseInt(v) !== parseInt(mediaWidthValue))
              handleSizeChange('width', v);
          }}
          {...(!get(config, 'style.asset.width') && { color: 'gray.500' })}
        >
          <NumberInputField px={1} />
        </NumberInput>
      </Tooltip>
      <Tooltip label="Image Height">
        <NumberInput
          borderColor="transparent"
          value={mediaHeightValue}
          min={1}
          max={100}
          size="sm"
          w="4.8rem"
          onChange={(v) => {
            setMediaHeightValue(v);
            if (parseInt(v) !== parseInt(mediaHeightValue))
              handleSizeChange('height', v);
          }}
          onBlur={(e) => {
            const val = (e.target.value || '100') + cssUnit;
            setMediaHeightValue(val);
            handleSizeChange('height', val);
          }}
          {...(!get(config, 'style.asset.height') && { color: 'gray.500' })}
        >
          <NumberInputField px={1} />
        </NumberInput>
      </Tooltip>
    </HStack>
  );
};

export const MediaAlignOptions = () => {
  const { updateConfig, config } = useDesignContext();
  const handleHorzAlign = React.useCallback(
    (align?: 'start' | 'center' | 'end') => {
      const isActive = get(config, 'style.asset.alignItems') === align;
      if (isActive) updateConfig?.({}, [`style.asset.alignItems`]);
      else updateConfig?.({ style: { asset: { alignItems: align } } });
    },
    [config?.style]
  );
  const handleVertAlign = React.useCallback(
    (align?: 'start' | 'center' | 'end') => {
      const isActive = get(config, 'style.asset.justifyContent') === align;
      if (isActive) updateConfig?.({}, [`style.asset.justifyContent`]);
      else updateConfig?.({ style: { asset: { justifyContent: align } } });
    },
    [config?.style]
  );
  return (
    <HStack spacing={1}>
      <ButtonGroup size="sm" isAttached variant="outline">
        <IconButton
          w="inherit"
          aria-label="Align left"
          icon={<Icon as={AlignStart} />}
          isActive={get(config, 'style.asset.justifyContent') === 'start'}
          onClick={() => handleVertAlign('start')}
        />
        <IconButton
          w="inherit"
          aria-label="Align center"
          icon={<Icon as={AlignCenter} />}
          isActive={get(config, 'style.asset.justifyContent') === 'center'}
          onClick={() => handleVertAlign('center')}
        />
        <IconButton
          w="inherit"
          aria-label="Align right"
          icon={<Icon as={AlignEnd} />}
          isActive={get(config, 'style.asset.justifyContent') === 'end'}
          onClick={() => handleVertAlign('end')}
        />
      </ButtonGroup>
      <Spacer />
      <ButtonGroup size="sm" isAttached variant="outline">
        <IconButton
          w="inherit"
          aria-label="Align top"
          icon={<Icon as={AlignTop} />}
          isActive={get(config, 'style.asset.alignItems') === 'start'}
          onClick={() => handleHorzAlign('start')}
        />
        <IconButton
          w="inherit"
          aria-label="Align middle"
          icon={<Icon as={AlignMiddle} />}
          isActive={get(config, 'style.asset.alignItems') === 'center'}
          onClick={() => handleHorzAlign('center')}
        />
        <IconButton
          w="inherit"
          aria-label="Align bottom"
          icon={<Icon as={AlignBottom} />}
          isActive={get(config, 'style.asset.alignItems') === 'end'}
          onClick={() => handleHorzAlign('end')}
        />
      </ButtonGroup>
    </HStack>
  );
};

export const MediaFillOption = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { content, config, updateContent, updateConfig } = useDesignContext();
  const [alpha, setAlpha] = React.useState<string | undefined>('100');

  const onRemoveMedia = React.useCallback(() => {
    updateContent?.({ content: [] });
    updateConfig?.({}, ['style.asset.opacity']);
  }, [updateContent]);

  const onMediaAlphaChange = useDebounceCallback(
    (value?: string) => {
      if (!value) updateConfig?.({}, ['style.asset.opacity']);
      else
        updateConfig?.({ style: { asset: { opacity: Number(value) / 100 } } });
    },
    [config?.style]
  );

  React.useEffect(() => {
    const opacity = Number(get(config, 'style.asset.opacity', 1));
    const prevAlpha = Math.floor(opacity * 100);
    if (Number(alpha) !== prevAlpha) setAlpha(prevAlpha?.toString());
  }, [config]);

  const [tabIndex, setTabIndex] = React.useState(0);
  const mediaData = useMediaData(isOpen);

  return (
    <MediaContext.Provider
      value={{ ...mediaData, isOpen, onClose, tabIndex, setTabIndex }}
    >
      <HStack>
        <FileSelect
          size="sm"
          isEmpty={!get(content, 'length')}
          onClick={onOpen}
          alpha={alpha}
          onChangeAlpha={(alpha) => {
            setAlpha(alpha);
            onMediaAlphaChange(alpha);
          }}
        />
        <IconButton
          variant="ghost"
          size="sm"
          aria-label="Remove Color"
          icon={<Icon as={Remove} />}
          onClick={onRemoveMedia}
          isDisabled={!get(content, 'length')}
        />
        <Modal size="4xl" {...{ isOpen, onClose }}>
          <ModalOverlay />
          <ModalContent maxW="container.lg">
            <ModalHeader>Select Media</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={0}>
              <MediaSelector />
            </ModalBody>
          </ModalContent>
        </Modal>
      </HStack>
      <AnimationControls />
      <MediaFitOptions />
    </MediaContext.Provider>
  );
};
