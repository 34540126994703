/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */
import { LoaderFunction, redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, result, find, isEmpty, isEqual } from 'lodash';
import { selectBranch, setBranch } from '../app/slice';
import authApi, { Context } from '../services/auth';
import store from '../store';

export enum ContextErrorType {
  notAuthenticated = 'Your session has expired. Please login again.',
  initFaliure = 'UserContextIsNotInitialized',
  noBranchFound = "This branch does not exist or you don't have access.",
}

const isAuthenticated = () => {
  // if user is not authenticated then redirect to login page
  return !!store.getState().app.auth.isAuthenticated;
};

/** This method is used to fetch the user context for the current user */
export const fetchUserContext = async (slug?: string) => {
  const { dispatch } = store;
  const { getUserContext } = authApi.endpoints;

  const { data: ctx } = await dispatch(getUserContext.initiate(slug));

  // if user is not authenticated then redirect to login page
  if (!isAuthenticated()) throw new Error(ContextErrorType.notAuthenticated);

  // if there are no branches then throw an error
  if (!get(ctx, 'context.branches.length'))
    throw new Error(ContextErrorType.noBranchFound);

  return ctx as Context;
};

/** Load the user branch based on url slug */
export const branchLoader: LoaderFunction = async ({
  params: { branchId, ...params },
}) => {
  const { dispatch } = store;
  try {
    const userCtx = await fetchUserContext(branchId);
    const branchSlug = get(userCtx, 'context.branch.slug');
    const branches = get(userCtx, 'context.branches');
    // if only branchId is provided, redirect to default branch
    if (isEmpty(get(params, '*')) && !isEqual(branchId, branchSlug))
      return redirect(`/${branchSlug}/${branchId}`);

    const branch = find(branches, { slug: branchId });
    if (!branch) throw new Error(ContextErrorType.noBranchFound);
    else dispatch(setBranch(branch));
  } catch (error) {
    switch (error.message) {
      case ContextErrorType.notAuthenticated:
        return redirect('/login');
      default:
        throw new Response(error.message);
    }
  }
};

/** This hook is used to get the current branch from redux store */
export const useBranch = () => {
  return useSelector(selectBranch);
};
