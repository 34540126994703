/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import get from 'lodash/get';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormControl,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import { Folder } from '../../../components/DataView';
import { useBranch } from '../../../hooks/branch';
import { useCreateContentGroupMutation } from '../../../services/content-library';

interface NewFolderFormProps {
  isOpen?: boolean;
  onClose?: () => void;
  onFolderCreated?: (folder: Folder) => void;
}

const schema = yup
  .object({
    name: yup
      .string()
      .label('Folder Name')
      .min(2, '${path} must be at least 2 characters')
      .max(50, '${path} must not exceed 50 characters')
      .required(),
  })
  .required();

type FolderFormData = yup.InferType<typeof schema>;

export const NewFolderForm = ({
  isOpen = false,
  onFolderCreated,
  onClose = () => {},
}: NewFolderFormProps) => {
  const toast = useToast();
  const branch = useBranch();
  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<FolderFormData>({
    resolver: yupResolver(schema),
  });

  const handleOnClose = () => {
    onClose?.();
    resetForm();
  };

  const [createContentGroup, { isLoading }] = useCreateContentGroupMutation();
  const onSubmit = async (formData: FolderFormData) => {
    try {
      const res = await createContentGroup({
        ...formData,
        type: 'media',
        owner: { branch },
      }).unwrap();

      handleOnClose();
      onFolderCreated?.(res);
      toast({
        title: `Folder successfully created`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Error creating folder`,
        description: get(error, 'data.error.message', error?.message),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isCentered
      size="sm"
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>New Folder</ModalHeader>
        <ModalBody>
          <FormControl isRequired isInvalid={!!errors.name}>
            <Input placeholder="Untitled folder" {...register('name')} />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter as={HStack} mt={4}>
          <Button
            isDisabled={isLoading}
            onClick={onClose}
            children="Cancel"
            size="md"
            variant="ghost"
          />
          <Button
            isDisabled={isLoading}
            isLoading={isLoading}
            variant="ghost"
            children="Create"
            colorScheme="blue"
            size="md"
            type="submit"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewFolderForm;
