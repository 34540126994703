/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import { get, keyBy } from 'lodash';
import baseApi, {
  StrapiId,
  getListModel,
  ListResponse,
  PaginationRequest,
} from '.';
import { Branch } from './branch';
import { User } from './auth';

export interface Group {
  id?: StrapiId;
  name: string;
  uid: string;
  updatedAt?: string;
  createdAt?: string;
  branch?: Branch;
  users?: User[];
}

type GetGroupsReq = [Branch['id'], PaginationRequest?, Object?];
type CreateGroupReq = Required<Pick<Group, 'name' | 'uid'>> & {
  branchId: Branch['id'];
};
type UpdateGroupReq = Required<Pick<Group, 'id'>> & Partial<Group>;

const groupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<ListResponse<Group>, GetGroupsReq>({
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.data.map((grp) => ({
                type: 'Groups' as const,
                id: get(grp, 'id'),
              })),
              { type: 'Groups', id: 'LIST' },
            ]
          : [{ type: 'Groups', id: 'LIST' }];
      },
      query: ([branchId, pageMeta, filters = {}]) => {
        const query = {
          filters: {
            ...filters,
            $and: [
              { branch: { id: { $eq: branchId } } },
              ...get(filters, '$and', []),
            ],
          },
          pagination: get(pageMeta, 'page')
            ? pageMeta
            : { page: pageMeta, pageSize: 100 },
        };
        return `/groups?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => {
        const data = getListModel<Group[]>(get(res, 'data', []));
        return {
          data,
          dataByKey: [keyBy(data, 'uid'), 'uid'],
          meta: get(res, 'meta', {}),
          original: get(res, 'data'),
        };
      },
    }),
    createGroup: builder.mutation<Group, CreateGroupReq>({
      query: (data) => ({
        url: '/groups',
        method: 'POST',
        body: {
          data: {
            ...data,
            branch: { id: data.branchId },
          },
        },
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    updateGroup: builder.mutation<Group, UpdateGroupReq>({
      // Invalidates all queries that subscribe to Display id only.
      invalidatesTags: (result, error, { id }) => [{ type: 'Groups', id }],
      query: ({ id, ...data }) => ({
        url: `/groups/${id}`,
        method: 'PUT',
        body: { data },
      }),
    }),
    deleteGroup: builder.mutation<void, Group['id']>({
      invalidatesTags: (result, error, id) => [{ type: 'Groups', id }],
      query: (id) => ({
        url: `/groups/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useDeleteGroupMutation,
  useCreateGroupMutation,
  useUpdateGroupMutation,
} = groupsApi;

export default groupsApi;
